import { Label } from "@app/components/label";
import checkedSrc from "@app/features/onboarding/radio-group-field/checked.svg";

import styles from "./index.module.css";

export const RadioGroup = ({
	name,
	label,
	value,
	disabled,
	options,
	onChange,
}: {
	name: string;
	label?: string;
	value: string;
	options: { label: string; value: string }[];
	onChange?: (event: any) => void;
	disabled?: boolean;
}) => {
	return (
		<div className={styles.inputContainer}>
			{label && <Label htmlFor={name}>{label}</Label>}
			<div className={styles.radioGroup} data-disabled={disabled}>
				{options.map((option) => {
					const isChecked = value === option.value;

					return (
						<label
							key={option.value}
							className={styles.radioLabel}
							data-active={isChecked}
						>
							<input
								name={name}
								className={styles.radio}
								value={option.value}
								disabled={disabled}
								type="radio"
								checked={isChecked}
								onChange={() => onChange?.(option.value)}
							/>
							{isChecked ? (
								<img src={checkedSrc} alt="" width={16} height={16} />
							) : (
								<div className={styles.unchecked} />
							)}
							{option.label}
						</label>
					);
				})}
			</div>
		</div>
	);
};
