import { useAccountManager } from "@app/hooks/use-account-manager";

import { NeedHelp } from "@app/components/need-help";
import { Title } from "@app/components/title";
import { useMediaQuery } from "@app/hooks/use-media-query";
import styles from "./index.module.css";

export const UpNextSection = () => {
	const isMobile = useMediaQuery();
	const { data: accountManager } = useAccountManager();
	return (
		<div className={styles.upNextContainer}>
			<Title>Up next...</Title>
			<p className={styles.upNextText}>
				{isMobile
					? "Complete the following steps to finalise your transaction."
					: "Finalise your transaction by completing the payment details, selecting a BoP code, and uploading supporting documents."}
			</p>
			<ol className={styles.list}>
				<li className={styles.item}>
					<div className={styles.todo} />
					<p>Payment details</p>
				</li>
				<li className={styles.line} />
				<li className={styles.item}>
					<div className={styles.todo} />
					<p>BoP details</p>
				</li>
				<li className={styles.line} />
				<li className={styles.item}>
					<div className={styles.todo} />
					<p>Documents</p>
				</li>
				<li className={styles.line} />
				<li className={styles.item}>
					<div className={styles.todo} />
					<p>Review</p>
				</li>
			</ol>
			<NeedHelp
				email={accountManager?.email}
				phone={accountManager?.contact_number}
			>
				Need help with this?
			</NeedHelp>
		</div>
	);
};
