import { Checkbox, type CheckboxChangeEvent } from "primereact/checkbox";
import type {
	MultiSelectChangeEvent,
	MultiSelectChangeTargetOptions,
} from "primereact/multiselect";
import { MouseEvent, SyntheticEvent, useEffect, useState } from "react";

import { MultiSelectDropDown } from "./components-responsive";
import type { ViewProperties } from "./properties";

import { Button } from "@app/components/button";
import { Dialog } from "@app/components/dialog";
import { Search } from "@app/components/search";
import "./multi-select-responsive.css";

export const MultiSelectViewResponsive = <T,>(props: ViewProperties<T>) => {
	const [filterText, setFilterText] = useState<string>("");
	const [value, setValue] = useState<T[]>(
		props.value && Array.isArray(props.value) ? props.value : [],
	);
	const [showModal, setShowModal] = useState(false);

	useEffect(() => {
		if (props.value && Array.isArray(props.value)) {
			setValue(props.value);
		}
	}, [props.value]);

	const filtered = props.options.filter((option) => {
		return JSON.stringify(option)
			.toLowerCase()
			.includes(filterText.toLowerCase());
	});

	const isChecked = (option: T): boolean => {
		return (
			(value &&
				Array.isArray(value) &&
				(value ?? [])
					.map((value) => JSON.stringify(value))
					.filter((value) => value === JSON.stringify(option)).length > 0) ??
			false
		);
	};

	const handleClick = (
		option: T,
		e: MouseEvent<HTMLDivElement, MouseEvent>,
	) => {
		e.preventDefault();
		e.stopPropagation();
		let updatedPropValue: T[] = [];
		const mappedValues =
			value &&
			Array.isArray(value) &&
			value.map((value) => JSON.stringify(value));
		if (mappedValues && mappedValues.includes(JSON.stringify(option))) {
			updatedPropValue = mappedValues
				.filter((item) => item !== JSON.stringify(option))
				.map((value) => JSON.parse(value));
		} else if (value && Array.isArray(value)) {
			updatedPropValue = [...value, option];
		}

		setValue(updatedPropValue);
	};

	const handleCheck = (option: T, e: CheckboxChangeEvent) => {
		e.preventDefault();
		e.stopPropagation();
		let updatedPropValue: any[] = [];

		if (e.checked) {
			if (value && Array.isArray(value)) {
				updatedPropValue = [...value, option];
			}
		} else {
			if (value && Array.isArray(value)) {
				updatedPropValue = value
					.map((value) => JSON.stringify(value))
					.filter((item) => item !== JSON.stringify(option))
					.map((value) => JSON.parse(value));
			}
		}

		setValue(updatedPropValue);
	};

	const handleSave = (e: MouseEvent<HTMLButtonElement, MouseEvent>) => {
		const event: MultiSelectChangeEvent = {
			value,
			originalEvent: e as unknown as SyntheticEvent,
			target: {
				name: "",
				id: "",
				value,
			} as MultiSelectChangeTargetOptions,
			preventDefault: () => e.preventDefault(),
			stopPropagation: () => e.stopPropagation(),
		};
		props.onChangeSelected?.(event);
		handleClose();
	};

	const handleClose = () => setShowModal(false);

	return (
		<>
			<MultiSelectDropDown
				{...props}
				onClick={() => setShowModal(true)}
				hideOutlineContent={props.hideOutlineContent}
				filterTemplateResponsive={
					value &&
					Array.isArray(value) &&
					value.length > 0 &&
					value.forEach((item, index) => {
						return (
							<>{props.valueTemplate && props.valueTemplate(item, index)}</>
						);
					})
				}
				showDropDownIcon
			/>
			<Dialog
				fullscreen
				isOpen={showModal}
				onClose={() => setShowModal(false)}
				title={props.headingText}
				actions={
					<>
						<Button variant="secondary" onClick={handleClose}>
							Cancel
						</Button>

						<Button onClick={handleSave}>{props.actionLabel ?? "Apply"}</Button>
					</>
				}
			>
				<div>
					<Search
						value={filterText}
						onChange={(event) => setFilterText(event.target.value)}
						onClear={() => setFilterText("")}
						placeholder={props.filterPlaceholder ?? "Search"}
					/>
					{props.onClear && (
						<Button variant="secondary" onClick={props.onClear}>
							Clear
						</Button>
					)}
				</div>
				<div className="mb-24 mt-2">
					{filtered.map((option, index: number) => (
						<div
							key={index}
							className={`flex min-h-11 flex-row items-center px-3 ${
								index % 2 === 0 ? "even" : "odd"
							}`}
							onClick={(e) => {
								handleClick(option, e);
							}}
						>
							<Checkbox
								checked={isChecked(option)}
								onChange={(e) => handleCheck(option, e)}
								className={`
                                        mr-3
                                        border-gray-125
                                        ${
																					isChecked(option)
																						? "checked"
																						: "unchecked"
																				}`}
							/>
							<div>{props.itemTemplate?.(option)}</div>
						</div>
					))}
				</div>
			</Dialog>
		</>
	);
};
