import { arbitrageApiUrl } from "@app/config/env";
import { paths } from "@app/constants/paths";
import { OTPMethod } from "@app/features/otp/use-otp";
import { api } from "@app/services";
import { getFormErrors } from "@app/utils/get-form-errors";
import axios from "axios";

type ResetPasswordResponse = {
	uid: string;
	token: string;
	password: string;
	otp_token: string;
	otp_method: string;
};

export const useAuth = () => {
	const handleForgotPassword = async (email: string) => {
		await api.post(`users/send-password-reset-link/${email}/`);
	};

	const handleResendConfirmationEmail = async (email: string) => {
		try {
			await api.post(`users/resend-confirmation-email/${email}/`);
		} catch (error) {
			return getFormErrors(error);
		}
	};

	const handleResetPassword = async ({
		uid,
		token,
		password,
		otpToken,
		otpMethod,
	}: {
		uid: string;
		token: string;
		password: string;
		otpToken?: string;
		otpMethod?: OTPMethod;
	}) => {
		try {
			await api.post<ResetPasswordResponse>("users/reset-password/", {
				uid,
				token,
				password,
				otp_token: otpToken,
				otp_method: otpMethod,
			});
		} catch (error) {
			return getFormErrors(error);
		}
	};

	return {
		onForgotPassword: handleForgotPassword,
		onArbitrageForgotPassword: async (email: string) => {
			await axios.post(`${arbitrageApiUrl}api/auth/users/reset_password/`, {
				email: email.trim(),
			});
		},
		onArbitrageResendActivate: async (email: string) => {
			try {
				await axios.post(
					`${arbitrageApiUrl}api/auth/users/resend_activation/`,
					{
						email: email.trim(),
					},
				);
			} catch (error) {
				return getFormErrors(error);
			}
		},
		onLogout: async (params = "") => {
			await api.post("auth/logout/");
			window.sessionStorage.clear();
			window.location.href = paths().login + params;
		},
		onResetPassword: handleResetPassword,
		onResendConfirmationEmail: handleResendConfirmationEmail,
	};
};
