import useSWR from "swr";
import { BalanceResponse } from "./use-balance";

export const useRefreshedBalance = (backAccountId?: number) => {
	return useSWR<BalanceResponse>(
		backAccountId
			? `/bank-accounts/${backAccountId}/balance/?refresh_balance=True`
			: null,
		{ revalidateOnMount: false, revalidateOnFocus: false },
	);
};
