import isEmpty from "lodash/isEmpty";
import type {
	DataTableStateEvent,
	DataTableValue,
	DataTableValueArray,
	SortOrder,
} from "primereact/datatable";
import { ReactNode } from "react";
import { type CSSProperties, useEffect, useMemo } from "react";

import type { GroupedArray } from "@app/entities";
import { useStateWithCallback } from "@app/hooks/use-state-with-callback";
import { arrayGroupBy } from "@app/utils";

import { type ColumnProps, TableView } from "./table-view";

interface TableState<T> {
	cardSortField: keyof T;
	sortField: string;
	sortOrder: SortOrder;
}

export const Table = <T,>(props: {
	cardViewContainerClassName?: string;
	cardViewGroupClassName?: string;
	cardViewItemClassName?: string;
	columns: ColumnProps<T>[];
	defaultSortField?: keyof T;
	emptyMessage?: string;
	groupByField?: keyof T;
	id?: string;
	lazy?: boolean;
	loading?: boolean;
	values?: DataTableValueArray;
	scrollable?: boolean;
	scrollHeight?: string;
	sortOrder?: SortOrder;
	stripedRows?: boolean;
	tableStyle?: CSSProperties;
	totalRecords: number;

	cardViewGroupHeaderTemplate?: (header: string) => ReactNode;
	cardViewTemplate?: (data: DataTableValue) => ReactNode;
	rowClassName?: (data: DataTableValue) => string;
	sortFunction?: (
		event: DataTableStateEvent,
		state: TableState<T>,
	) => DataTableStateEvent;
	onClickCard?: (data: DataTableValue) => void;
}) => {
	const defaultTableState = useMemo<TableState<T>>(() => {
		const foundColumn = props.columns.find((x) => {
			return (x.cardField ?? x.field) === props.groupByField?.toString();
		});

		return {
			cardSortField: props.groupByField as keyof T,
			sortField: foundColumn
				? foundColumn.field
				: ((props.defaultSortField as string) ?? "date"),
			sortOrder: 0,
		};
	}, []);
	const [state, setState] =
		useStateWithCallback<TableState<T>>(defaultTableState);

	const groupedValues = useMemo<
		GroupedArray<DataTableValue> | DataTableValueArray | undefined
	>(() => {
		return state.cardSortField && !isEmpty(state.cardSortField.toString())
			? arrayGroupBy<DataTableValue, T>(
					props.values,
					state.cardSortField as keyof T,
				)
			: props.values;
	}, [props.values, state.cardSortField, state.sortOrder]);

	const sortFunction = (event: DataTableStateEvent) => {
		const foundColumn = props.columns.find((x) => x.field === event.sortField);

		setState(
			{
				cardSortField: (foundColumn
					? (foundColumn.cardField ?? event.sortField)
					: event.sortField) as keyof T,
				sortField: event.sortField,
				sortOrder: event.sortOrder || 0,
			},
			(values) => {
				if (props.sortFunction) props.sortFunction(event, values);
			},
		);

		return event;
	};

	const onClickCard = (data: DataTableValue) => {
		if (props.onClickCard) props.onClickCard(data);
	};

	useEffect(() => {
		if (props.groupByField) {
			const foundColumn = props.columns.find(
				(x) => x.cardField === props.groupByField,
			);

			if (foundColumn) {
				setState({
					...state,
					cardSortField: props.groupByField,
					sortField: foundColumn.field,
				});
			} else {
				setState({ ...state, cardSortField: props.groupByField });
			}
		}
	}, [props.groupByField]);

	useEffect(() => {
		setState({ ...state, sortOrder: props.sortOrder });
	}, [props.sortOrder]);

	const viewProps = {
		...props,
		columns: props.columns as ColumnProps<unknown>[],
		groupedValues,
		groupByField: undefined,
		defaultSortField: undefined,
		sortField: state.sortField as keyof unknown,
		sortOrder: state.sortOrder as SortOrder,
		sortFunction,
		onClickCard,
	};

	return <TableView {...viewProps} />;
};
