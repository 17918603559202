import { DropdownProps, Dropdown as PrimeDropdown } from "primereact/dropdown";

import clsx from "clsx";
import { forwardRef } from "react";
import styles from "./index.module.css";

export const Dropdown = forwardRef<
	any,
	DropdownProps & {
		getLabel?: (value: any) => string;
	}
>(
	(
		{
			filter = true,
			className,
			panelClassName,
			filterPlaceholder = "Search",
			getLabel = (value) => value,
			optionLabel,
			...rest
		},
		ref,
	) => {
		return (
			<PrimeDropdown
				scrollHeight="224px"
				ref={ref}
				checkmark
				className={clsx(styles.dropdown, className)}
				panelClassName={clsx(styles.dropdownPanel, panelClassName)}
				filter={filter}
				filterPlaceholder={filterPlaceholder}
				optionLabel={optionLabel}
				itemTemplate={(option) => {
					const label =
						typeof option === "object" ? option[optionLabel as any] : option;
					return (
						<>
							{option === "Other" && <div className={styles.divider} />}

							<div className={styles.item}>
								<span>{label}</span>

								<svg
									role="presentation"
									className={styles.checkmark}
									xmlns="http://www.w3.org/2000/svg"
									width="20"
									height="20"
									viewBox="0 0 20 20"
									fill="none"
								>
									<path
										d="M16.6668 5L7.50016 14.1667L3.3335 10"
										stroke="#888"
										strokeWidth="1.66667"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</div>
						</>
					);
				}}
				{...rest}
			/>
		);
	},
);
