import { useAuth } from "@app/hooks/use-auth";
import { formatStatusMessage } from "@app/utils/format-status-message";
import { useMemo } from "react";
import { Button } from "../button";
import { Dialog, type DialogProps } from "../dialog";
import styles from "./index.module.css";

export const StatusCheckModal = ({
	error,
	data,
	isOpen,
	...rest
}: {
	isOpen?: boolean;
	error?: any;
	data?: {
		message_body?: null | string;
		message_title?: null | string;
	};
} & Omit<DialogProps, "isOpen">) => {
	const { onLogout } = useAuth();
	const errorContent = useMemo(() => {
		if (!error) return undefined;
		try {
			const errorDetail = JSON.parse(error.data.detail);
			return errorDetail as {
				message_body: string;
				message_title: string;
			};
		} catch {}
	}, [error]);

	const hasMessage =
		!!(data?.message_body && data?.message_title) ||
		!!(errorContent?.message_title && errorContent?.message_body);

	if (!hasMessage && error)
		return (
			<Dialog
				isOpen
				title="Oops! Something went wrong"
				description="We are unable to retrieve your account status at the moment. Please try again later."
			>
				<Button centered onClick={() => onLogout()}>
					Log out
				</Button>
			</Dialog>
		);

	if (!hasMessage) return null;

	const content = data ?? errorContent!;

	return (
		<Dialog
			size="xl"
			isOpen={isOpen !== undefined ? isOpen : hasMessage}
			title={content.message_title!}
			description={
				<div
					className={styles.content}
					dangerouslySetInnerHTML={{
						__html: formatStatusMessage(content.message_body!),
					}}
				/>
			}
			{...rest}
		/>
	);
};
