import { useState } from "react";
import { ChangeOtpPasswordModal } from "../modals/change-otp-password-modal";
import { OTPInputModal } from "../modals/otp-input-modal";
import { ResendOTPModal } from "../modals/resend-otp-modal";
import { ConfirmedOTPNumberChangedModal } from "../modals/confirmed-otp-number-changed-modal";
import { ChangeOTPNumberModal } from "../modals/change-otp-number-modal";
import { useOTP } from "../use-otp";
import { getIsOTPError } from "../get-is-otp-error";

export const ChangeOTPNumberFlow = ({
	onComplete,
	onClose,
}: {
	onComplete: () => void;
	onClose: () => void;
}) => {
	const { validateCurrentNumber, changeOTPNumber, verifyOTP, otpDetails } =
		useOTP();
	const [password, setPassword] = useState("");
	const [contactNumber, setContactNumber] = useState("");
	const [status, setStatus] = useState<
		| "password"
		| "otp"
		| "resend"
		| "number"
		| "verify"
		| "verify-resend"
		| "confirmed"
	>("password");

	return (
		<>
			{status === "password" && (
				<ChangeOtpPasswordModal
					title="Change OTP number"
					description="This will change the mobile number used for OTP authentication. Please enter your password to proceed."
					onConfirm={async (password) => {
						const errors = await validateCurrentNumber(password);
						if (errors && !getIsOTPError(errors.apiErrors)) return errors;
						setPassword(password);
						setStatus("otp");
					}}
					onClose={onClose}
				/>
			)}

			{status === "otp" && (
				<OTPInputModal
					onResend={() => {
						setStatus("resend");
					}}
					onClose={() => {
						setStatus("password");
					}}
					onConfirm={async () => {
						const errors = await validateCurrentNumber(password);
						if (errors) return errors;
						setStatus("number");
					}}
				/>
			)}

			{status === "resend" && (
				<ResendOTPModal
					onConfirm={() => {
						setStatus("otp");
					}}
					onClose={() => {
						setStatus("otp");
					}}
				/>
			)}

			{status === "number" && (
				<ChangeOTPNumberModal
					onClose={onClose}
					onConfirm={async (contactNumber) => {
						const errors = await changeOTPNumber({
							contact_number: contactNumber,
						});
						if (errors && !getIsOTPError(errors.apiErrors)) return errors;
						setContactNumber(contactNumber);
						setStatus("verify");
					}}
				/>
			)}

			{status === "verify" && (
				<OTPInputModal
					method={otpDetails?.message_channel}
					title="Verify your new number"
					variant="unauthed"
					contactNumber={contactNumber}
					onResend={() => {
						setStatus("verify-resend");
					}}
					onClose={() => setStatus("number")}
					onConfirm={async (token) => {
						const verifyErrors = await verifyOTP(token);
						if (verifyErrors) return verifyErrors;
						const errors = await changeOTPNumber({
							contact_number: contactNumber,
						});
						if (errors) return errors;
						setStatus("confirmed");
					}}
				/>
			)}

			{status === "verify-resend" && (
				<ResendOTPModal
					onConfirm={() => {
						setStatus("verify");
					}}
					onClose={() => {
						setStatus("verify");
					}}
				/>
			)}

			{status === "confirmed" && (
				<ConfirmedOTPNumberChangedModal onClose={onComplete} />
			)}
		</>
	);
};
