import { type ReactNode, useEffect, useState } from "react";

import {
	type ForexQuote,
	type GetExchangeDetails,
	type ListTransactionInProgress,
	type TransactionDirection,
} from "@app/entities";

import { twMerge } from "tailwind-merge";

import CalendarIcon from "@app/assets/images/calendar.svg";
import CoinsIcon from "@app/assets/images/coins.svg";
import HexIcon from "@app/assets/images/hexagon.svg";
import PenIcon from "@app/assets/images/pen-tool.svg";
import RefreshIcon from "@app/assets/images/refresh.svg";
import { Button } from "@app/components/button";
import { Typography } from "@app/components/typography";
import confirmCheckSrc from "./confirm-icon.svg";

import { useMediaQuery } from "@app/hooks/use-media-query";

import { MoreInfoTooltip } from "../more-info-tooltip";

import { dateFormats } from "@app/constants/date-formats";
import { tempExchangeDetailsMapping } from "@app/hooks/use-exchange-details";
import { ForexQuoteOptions } from "@app/hooks/use-quote-options";
import { useTransaction } from "@app/hooks/use-transaction";
import { useTransactionId } from "@app/hooks/use-transaction-id";
import { toDayjs } from "@app/lib/date";
import {
	FiArrowDown,
	FiArrowLeft,
	FiArrowRight,
	FiArrowUp,
} from "react-icons/fi";
import { CountryIcon } from "../country-icon";
import styles from "./index.module.css";

interface ExchangeRow {
	icon: string;
	labelContent: ReactNode;
	valueContent: ReactNode;
}

type Variant = "confirmation" | "booking" | "default" | "view";

const getHeading = (variant?: Variant) => {
	if (variant === "booking") {
		return "Confirm exchange";
	}

	if (variant === "confirmation") {
		return "Exchange confirmed";
	}

	if (variant === "view") {
		return "Exchange";
	}

	return "Confirmed exchange";
};

const CurrencySection = ({
	currency,
	mainNumber,
	centsNumber,
}: {
	currency: string;
	mainNumber: string;
	centsNumber: string;
}) => {
	const isMobile = useMediaQuery();
	const iconSize = isMobile ? 24 : 32;

	const noNumber = !mainNumber || Number.isNaN(Number(mainNumber));
	return (
		<div className={styles.currencyRow}>
			<CountryIcon currencyCode={currency} width={iconSize} height={iconSize} />
			<div className={styles.currency}>
				<Typography theme="displayXs" className="my-auto font-medium">
					{currency.toUpperCase()}{" "}
					{noNumber ? "-" : Number.parseFloat(mainNumber).toLocaleString("en")}
				</Typography>
				{!noNumber && (
					<Typography
						theme="textMd"
						className="mb-auto mt-2 font-medium text-gray-650"
					>
						.{centsNumber}
					</Typography>
				)}
			</div>
		</div>
	);
};

export const ExchangeDetailsBlock = ({
	isLoading = false,
	variant = "default",
	...props
}: {
	className?: string;
	displayHeadingAsMain?: boolean;
	exchangeDetails?: GetExchangeDetails;
	forexQuoteValues?: ForexQuote;
	overrideHeading?: ReactNode;
	overrideShowPaymentDetails?: boolean;
	quoteOptions?: ForexQuoteOptions;
	showAsOpen?: boolean;
	transaction?: ListTransactionInProgress;
	isLoading?: boolean;
	variant?: Variant;
}) => {
	const transactionId = useTransactionId();
	const { exchangeDetails, isExchangeDetailsLoading } =
		useTransaction(transactionId);
	const [showPaymentDetails, setShowPaymentDetails] = useState(
		props.showAsOpen || false,
	);

	useEffect(() => {
		if (!props.exchangeDetails) {
			if (transactionId) {
				setShowPaymentDetails(false);
			}
		}
	}, [transactionId, props.exchangeDetails]);

	const isMobile = useMediaQuery();

	const details =
		props.exchangeDetails ?? tempExchangeDetailsMapping(exchangeDetails);

	const [mainCash, cents] = (
		details?.zarAmount?.replace(/^\D+/g, "").replace(/,/g, "") ||
		Number.parseFloat(
			props.quoteOptions?.fxCurrency === props.quoteOptions?.quoteCurrency
				? props.forexQuoteValues?.amount
						.replace(/^\D+/g, "")
						.replace(/,/g, "") || ""
				: props.quoteOptions?.quoteAmount?.replace(/,/g, "") || "",
		).toFixed(2) ||
		"0"
	).split(".");
	const [fxMainCash, fxCents] = (
		details?.fxAmount?.replace(/^\D+/g, "").replace(/,/g, "") ||
		Number.parseFloat(
			props.quoteOptions?.fxCurrency === props.quoteOptions?.quoteCurrency
				? props.quoteOptions?.fxAmount?.replace(/,/g, "") || ""
				: props.forexQuoteValues?.amount
						.replace(/^\D+/g, "")
						.replace(/,/g, "") || "",
		).toFixed(2) ||
		"0"
	).split(".");

	const paymentType = details
		? (details?.direction.toLowerCase() as TransactionDirection)
		: props.quoteOptions?.transactionType;
	const isSend = paymentType === "send";

	const exchangeRows: ExchangeRow[] = [
		{
			icon: HexIcon,
			labelContent: (
				<>
					Spread{" "}
					<MoreInfoTooltip
						hasIcon
						name="Spread"
						maxWidth={300}
						size={isMobile ? 18 : 20}
					>
						The spread is the difference between the exchange rate provided to
						you and the bench exchange rate. This difference is due to the cost
						of exchanging one currency for another.
					</MoreInfoTooltip>
				</>
			),
			valueContent: details?.spread || props.forexQuoteValues?.spread || "-",
		},
		...(details?.fec.isFec
			? [
					{
						icon: PenIcon,
						labelContent: "FEC Type",
						valueContent: details?.fec.fecType || "",
					},
				]
			: []),

		{
			icon: CalendarIcon,
			labelContent:
				details?.fec.isFec === true ? "Maturity Date" : "Value Date",
			valueContent:
				details?.fec.isFec === true
					? details?.fec.maturityDate || ""
					: !details
						? toDayjs(
								props.quoteOptions?.valueDate || "",
								dateFormats.iso8601,
							).format(dateFormats.paddedDayShortMonthYear)
						: toDayjs(
								details?.transactionDate || "",
								dateFormats.reverseIso8601,
							).format(dateFormats.paddedDayShortMonthYear),
		},

		{
			icon: CoinsIcon,
			labelContent: "Settlement Account",
			valueContent:
				(
					<>
						{details ? (
							details?.settlementAccount
						) : (
							<>
								{props.quoteOptions?.settlementAccounts?.accountType} (
								{props.quoteOptions?.settlementAccounts?.accountNumber})
							</>
						)}{" "}
						<MoreInfoTooltip
							name="Current Account"
							hasIcon
							maxWidth={300}
							size={isMobile ? 18 : 20}
						>
							Your account type is actually a "Trading account", but for
							purposes of depositing funds from other banks you should select
							"Current” as the account option.
						</MoreInfoTooltip>
					</>
				) || "",
		},
	];

	const sendReceiveIconSize = isMobile ? 16 : 24;
	const iconSize = isMobile ? 18 : 24;

	const showCheck = variant === "confirmation";

	return (
		<div
			data-variant={variant}
			className={twMerge(styles.container, props.className)}
		>
			{showCheck && (
				<img
					className={styles.tick}
					src={confirmCheckSrc}
					width={44}
					height={44}
					alt=""
				/>
			)}

			<div className={styles.heading}>
				<div className={styles.headingInner}>
					{!props.overrideHeading ? (
						<>
							<h3 className={styles.title}>{getHeading(variant)}</h3>
						</>
					) : (
						<>{props.overrideHeading}</>
					)}
				</div>
				{!props.showAsOpen && !props.overrideShowPaymentDetails && !isMobile ? (
					<Button
						variant="tertiary"
						size="sm"
						noPadding
						disabled={isExchangeDetailsLoading || isLoading}
						inline
						onClick={() => setShowPaymentDetails(!showPaymentDetails)}
					>
						{showPaymentDetails ? "Hide details" : "Show details"}{" "}
					</Button>
				) : (
					<div />
				)}
			</div>

			<div className={styles.moneyRow}>
				<div className={styles.moneyRowStart}>
					{!isMobile && (
						<Typography
							theme="textSm"
							className={twMerge("text-gray-650", "my-auto")}
						>
							{paymentType === "send" ? "You send" : "You receive"}
						</Typography>
					)}

					<CurrencySection
						currency="ZAR"
						mainNumber={mainCash}
						centsNumber={cents}
					/>
				</div>
				<div className={styles.typeRow}>
					<div data-type={paymentType} className={styles.sendReceive}>
						{isSend && !isMobile && (
							<FiArrowRight size={sendReceiveIconSize} color="#383838" />
						)}
						{!isSend && !isMobile && (
							<FiArrowLeft size={sendReceiveIconSize} color="#383838" />
						)}
						{isSend && isMobile && (
							<FiArrowDown size={sendReceiveIconSize} color="#383838" />
						)}
						{!isSend && isMobile && (
							<FiArrowUp size={sendReceiveIconSize} color="#383838" />
						)}
					</div>
					{isMobile && (
						<p>
							{paymentType === "send" ? (
								<>
									{props.transaction?.recipient ? "Send to" : "Send"}{" "}
									<strong>{props.transaction?.recipient}</strong>
								</>
							) : (
								"Receive"
							)}
						</p>
					)}
				</div>

				<div className={styles.moneyRowEnd}>
					{!isMobile && (
						<Typography
							theme="textSm"
							className={`${twMerge(
								"text-gray-650",
								"my-auto",
							)} text-right mobile:text-left`}
						>
							{paymentType === "send" ? "They receive" : "They send"}
						</Typography>
					)}

					<CurrencySection
						currency={
							details?.currencyCode || props.quoteOptions?.fxCurrency || ""
						}
						mainNumber={fxMainCash}
						centsNumber={fxCents}
					/>
				</div>
			</div>
			<div className={styles.divider} />
			<div className={styles.exchangeRateRow}>
				{isLoading ? null : (
					<>
						<div>
							<div className={styles.leftSection}>
								<img
									alt=""
									src={RefreshIcon}
									width={iconSize}
									height={iconSize}
								/>
								<p className={styles.rowLabel}>Exchange Rate</p>
								{isMobile && (
									<MoreInfoTooltip name="Exchange Rate" size={18}>
										Exchange Rate is inclusive of the Spread
									</MoreInfoTooltip>
								)}
							</div>
							{!isMobile && (
								<p className={styles.inclusiveSpread}>
									Exchange Rate is inclusive of the Spread
								</p>
							)}
						</div>
						<div className={styles.rowValue}>
							<p>
								{details ? (
									details?.exchangeRateComponents?.zarComponent || "-"
								) : (
									<>
										{" "}
										ZAR {props.forexQuoteValues?.confirmPageDisplay.zarAmount}
									</>
								)}
							</p>
							{isSend ? (
								<FiArrowRight size={16} color="#383838" />
							) : (
								<FiArrowLeft size={16} color="#383838" />
							)}
							<p>
								{details ? (
									details?.exchangeRateComponents?.fxComponent
								) : (
									<>
										{" "}
										{props.quoteOptions?.fxCurrency || "-"}{" "}
										{props.forexQuoteValues?.confirmPageDisplay.fxAmount}
									</>
								)}
							</p>
						</div>
					</>
				)}
			</div>
			{showPaymentDetails && (
				<div className={styles.detailsContainer}>
					{exchangeRows.map((x: ExchangeRow) => (
						<div className={styles.exchangeRowItem} key={x.icon}>
							<div className={styles.leftSection}>
								<img src={x.icon} width={iconSize} height={iconSize} alt="" />
								<p className={styles.rowLabel}>{x.labelContent}</p>
							</div>
							<p className={styles.rowValue}>{x.valueContent}</p>
						</div>
					))}
				</div>
			)}

			{isMobile && !props.overrideShowPaymentDetails && (
				<Button
					variant="tertiary"
					size="sm"
					noPadding
					inline
					className={styles.showDetailsButton}
					disabled={isExchangeDetailsLoading || isLoading}
					onClick={() => setShowPaymentDetails(!showPaymentDetails)}
				>
					{showPaymentDetails ? "Hide details" : "Show details"}
				</Button>
			)}
		</div>
	);
};
