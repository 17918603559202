import { arbitrageApiUrl } from "@app/config/env";
import { links } from "@app/constants/links";
import { STORAGE_KEYS } from "@app/constants/storage-keys";
import { OTPMethod } from "@app/features/otp/use-otp";
import { type LoginFailureResponse, api } from "@app/services";
import axios from "axios";

const getLoginErrors = (error: any) => {
	try {
		const data = (error?.data || error?.response?.data) as LoginFailureResponse;
		if (!data) return ["An unexpected error occurred. Please try again."];
		if (error.response?.status === 403) {
			return ["Too many failed login attempts. please contact us."];
		}

		const errors: Array<string> = [];
		if (data.detail && typeof data.detail === "string") {
			errors.push(data.detail);
		} else if (data.message && typeof data.message === "string") {
			errors.push(data.message);
		}
		if (data.non_field_errors) errors.push(...data.non_field_errors);
		if (data.username)
			errors.push(...data.username.map((x) => `username: ${x}`));
		if (data.password)
			errors.push(...data.password.map((x) => `password: ${x}`));
		return errors;
	} catch {
		return ["An unexpected error occurred. Please try again."];
	}
};

type LoginForm = {
	username: string;
	password: string;
};

type LoginResponse = {
	username: string;
	password: string;
	otp_token: string;
	otp_method: string;
};

export const useLogin = () => {
	const handleForexLogin = async ({
		username,
		password,
		otp_token,
		otp_method,
	}: LoginForm & { otp_token?: string; otp_method?: OTPMethod }) => {
		try {
			const { data } = await api.post<LoginResponse>("auth/login/", {
				username: `${username}`.trim(),
				password: `${password}`.trim(),
				otp_token,
				otp_method,
			});
			return [data, null] as const;
		} catch (error) {
			return [null, getLoginErrors(error)] as const;
		}
	};

	const handleValidateForexLogin = async ({
		username,
		password,
	}: LoginForm) => {
		try {
			const result = await api.post<{ is_valid: boolean }>(
				"auth/validate-user/",
				{
					username: `${username}`.trim(),
					password: `${password}`.trim(),
				},
			);
			return result.data.is_valid;
		} catch (error) {
			console.error(error);
			return false;
		}
	};

	const handleArbLogin = async ({ username, password }: LoginForm) => {
		try {
			const result = await axios.post<{ auth_token: string }>(
				`${arbitrageApiUrl}api/auth/token/login/`,
				{
					username: `${username}`.trim(),
					password: `${password}`.trim(),
				},
			);
			window.localStorage.setItem(
				STORAGE_KEYS.arbToken,
				result.data.auth_token,
			);
			return [];
		} catch (error) {
			return getLoginErrors(error);
		}
	};

	const handleGoToArb = (isInactiveUser?: boolean) => {
		let redirectUrl = links.arbitrage.base;
		const isAddClient =
			window.sessionStorage.getItem(STORAGE_KEYS.arbAddClient) === "true";
		if (isAddClient) {
			redirectUrl += "clients?&new=true&login-source=OFP";
		} else {
			redirectUrl = `${redirectUrl}?login-source=OFP`;
		}

		const token = window.localStorage.getItem(STORAGE_KEYS.arbToken);
		if (token) {
			redirectUrl += `&auth-token=${token}`;
		}
		if (isInactiveUser) {
			redirectUrl += "&inactive=true";
		}

		window.location.href = redirectUrl;
	};

	return {
		onForexLogin: handleForexLogin,
		onValidateForexLogin: handleValidateForexLogin,
		onArbLogin: handleArbLogin,
		onGoToArb: handleGoToArb,
	};
};
