import { Button } from "@app/components/button";
import { Dialog } from "@app/components/dialog";
import { useMediaQuery } from "@app/hooks/use-media-query";
import { AccountManagerCard } from "./account-manager-card";

import { useClientOnboardingStatus } from "@app/components/signed-in-layout/use-client-onboarding-status";
import styles from "./index.module.css";

export const NeedHelpModal = ({
	onBack,
	variant = "default",
}: {
	onBack: () => void;
	variant?: "default" | "condensed";
}) => {
	const isMobile = useMediaQuery();
	const { data } = useClientOnboardingStatus();
	return (
		<Dialog
			size={variant === "condensed" ? "sm" : "xl"}
			isOpen
			bottomsheet={isMobile}
			title="Need help?"
			description={data?.need_help.map((current) => (
				<p key={current} className={styles.description}>
					{current}
				</p>
			))}
			actions={
				<Button centered onClick={onBack}>
					Back
				</Button>
			}
		>
			<AccountManagerCard variant={variant} />
		</Dialog>
	);
};
