import { type ReactNode } from "react";

import { Dialog as BaseDialog } from "@headlessui/react";

import useMountTransition from "@app/hooks/use-mount-transition";
import { joinClasses } from "@app/utils";
import clsx from "clsx";
import { MobileHeader } from "../mobile-header";
import { Title } from "../title";
import styles from "./index.module.css";

export interface DialogProps {
	size?: "sm" | "md" | "lg" | "xl" | "fit";
	title?: ReactNode;
	classNameDescription?: string;
	isOpen: boolean;
	showTopbar?: boolean;
	onClose?: () => void;
	onBack?: () => void;
	description?: ReactNode;
	children?: ReactNode;
	actions?: ReactNode;
	// TODO(tiaan): Refactor to use variant
	fullscreen?: boolean;
	bottomsheet?: boolean;
	actionsClassName?: string;
	className?: string;
	wrapperClassName?: string;
	backdropClassName?: string;
	isSmallHeader?: boolean;
	zIndex?: number;
}

export const Dialog = ({
	isOpen,
	onClose,
	size = "md",
	title,
	description,
	children,
	actions,
	fullscreen = false,
	showTopbar = false,
	isSmallHeader = false,
	className,
	classNameDescription,
	bottomsheet = false,
	wrapperClassName,
	actionsClassName,
	backdropClassName,
	onBack,
	zIndex = 101,
}: DialogProps) => {
	const isNonCloseable = !onClose;

	const hasTransitionedIn = useMountTransition({
		isMounted: isOpen,
		delay: 0,
	});

	const titleVariant =
		fullscreen || bottomsheet ? "inline" : isSmallHeader ? "small" : "default";

	return (
		<BaseDialog
			open={isOpen}
			onClose={() => {
				if (onClose) onClose();
			}}
		>
			<div
				data-visible={hasTransitionedIn}
				className={clsx(styles.backdrop, backdropClassName)}
				style={{ zIndex }}
				aria-hidden="true"
			/>
			<div
				data-visible={hasTransitionedIn}
				className={clsx(styles.wrapper, wrapperClassName)}
				data-fullscreen={fullscreen}
				data-bottomsheet={bottomsheet}
				style={{ zIndex }}
				data-has-actions={!!actions}
				data-topbar={showTopbar}
			>
				<BaseDialog.Panel
					className={joinClasses(styles.content, className)}
					data-size={size}
				>
					{onBack && !showTopbar && (
						<button
							className={styles.backButton}
							type="button"
							onClick={onBack}
							aria-label="Back"
						>
							<svg
								role="presentation"
								xmlns="http://www.w3.org/2000/svg"
								width="20"
								height="20"
								viewBox="0 0 20 20"
								fill="none"
							>
								<path
									d="M12.5024 15.0018L7.50411 10L12.5059 5.00175"
									stroke="currentColor"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</button>
					)}

					{!isNonCloseable && !showTopbar && (
						<button
							className={styles.closeButton}
							type="button"
							aria-label="Close"
							onClick={onClose}
						>
							<svg
								role="presentation"
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
							>
								<path
									d="M18 6L6 18M6 6L18 18"
									stroke="currentColor"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</button>
					)}
					{showTopbar ? (
						<MobileHeader variant="fixed" onBack={onBack ?? onClose}>
							{title}
						</MobileHeader>
					) : title ? (
						typeof title === "string" ? (
							<Title
								tabIndex
								variant={titleVariant}
								className={styles.title}
								titleClassName={clsx({
									[styles.titleDefault]: titleVariant === "default",
								})}
							>
								{title}
							</Title>
						) : (
							title
						)
					) : null}
					{description && (
						<BaseDialog.Description
							className={joinClasses(styles.description, classNameDescription)}
						>
							{description}
						</BaseDialog.Description>
					)}

					{children}
					{actions && (
						<div className={clsx(styles.actions, actionsClassName)}>
							{actions}
						</div>
					)}
				</BaseDialog.Panel>
			</div>
		</BaseDialog>
	);
};
