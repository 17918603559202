import { isLocalOrTest } from "@app/config/env";

export const isPrimaryShareholdersEnabled = false;
export const isMobileBottomsheetMenusEnabled = false;
export const isChangeEmailAddressEnabled = false;

export const isPaymentDetailsAddRecipientEnabled = true;
export const isMultipleFileUploadEnabled = true;
export const isClientAddRedirectEnabled = true;
export const isExchangeDetailsAsTransactionEnabled = true;
export const isGeneralErrorModalEnabled = true;
export const isBopCodeAllEnabled = true;
export const isArbInactiveClientEnabled = true;

export const isOTPAuthEnabled = true;
export const isAvailableBalanceEnabled = isLocalOrTest;
