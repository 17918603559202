import { usePayment } from "@app/features/transaction-details/payment-details/use-payment";
import { useRecipient } from "@app/hooks/use-recipient";
import { useTransaction } from "@app/hooks/use-transaction";

import { twMerge } from "tailwind-merge";

import editPencilSrc from "@app/assets/images/edit-pencil.svg";
import fileTextSrc from "@app/assets/images/file-text.svg";
import { Button } from "@app/components/button";
import { IconButton } from "@app/components/icon-button";
import { Typography } from "@app/components/typography";

import { MoreInfoTooltip } from "@app/components/more-info-tooltip";
import { InProgressTransaction } from "@app/hooks/use-in-progress-transactions";
import { useMediaQuery } from "@app/hooks/use-media-query";
import { EntityType } from "@app/types";
import { useState } from "react";
import { CurrencyTags } from "./currency-tags";
import styles from "./index.module.css";

const LabelValuePair = ({ label, value }: { label: string; value: string }) => {
	return (
		<div className={styles.rowItem}>
			<Typography
				theme="textSm"
				className={twMerge("text-gray-650", "my-auto")}
			>
				{label}
			</Typography>
			<Typography theme="textLg">{value || "-"}</Typography>
		</div>
	);
};

export const PaymentDetailsBlock = ({
	transactionId,
	onEdit,
}: {
	transactionId?: number;
	onEdit?: () => void;
}) => {
	const [showRecipient, setShowRecipient] = useState(false);
	const { activePaymentId, transaction: transactionData } =
		useTransaction(transactionId);
	const { data: payment } = usePayment(activePaymentId);
	const { data: recipient } = useRecipient(payment?.recipient_id);

	const isCompletedTransaction =
		!(transactionData as InProgressTransaction)?.progress_status ||
		(transactionData as InProgressTransaction)?.progress_status?.stage > 3;

	const isMobile = useMediaQuery();
	const entityType =
		recipient?.entity_type === "individual" ? "Individual" : "Business";

	const currencies = recipient?.currencies?.split(",");

	const recipientDetails =
		(!isCompletedTransaction && recipient) || showRecipient ? (
			<>
				<div className={`${styles.rowPayment} grid-cols-3`}>
					<LabelValuePair
						label="Bank:"
						value={recipient?.bank_details?.bank_name || ""}
					/>

					<LabelValuePair
						label="SWIFT:"
						value={recipient?.bank_details?.swift_code || ""}
					/>

					<LabelValuePair
						label={recipient?.bank_details?.iban ? "IBAN:" : "Account number:"}
						value={
							recipient?.bank_details?.iban
								? recipient.bank_details.iban || ""
								: recipient?.bank_details?.account_number || ""
						}
					/>
				</div>
				<div className={`${styles.rowPayment} grid-cols-1`}>
					<LabelValuePair
						label="Address:"
						value={recipient?.address?.combined_str || ""}
					/>
				</div>
			</>
		) : null;

	return (
		<div className={styles.container}>
			<div className={styles.headingRow}>
				<div className="left-section flex flex-row gap-3">
					<img src={fileTextSrc} width={24} height={24} alt="" />
					<Typography theme="displayXs" className="font-medium">
						Payment Details
					</Typography>
				</div>
				{onEdit && (
					<IconButton
						className={styles.editButton}
						onClick={() => {
							if (onEdit) onEdit();
						}}
					>
						<img src={editPencilSrc} width={24} height={24} alt="" />
					</IconButton>
				)}
			</div>
			<div
				className={`${styles.rowPayment} ${
					transactionData?.direction === "send" ? "grid-cols-2" : "grid-cols-1"
				}`}
			>
				<div className={styles.rowItem}>
					<Typography theme="textSm" className={"text-gray-650"}>
						SWIFT Fee:
					</Typography>

					<Typography theme="textLg">
						{payment?.swift_fee}

						{payment?.swift_fee_tooltip && (
							<MoreInfoTooltip
								style={{ marginLeft: "4px" }}
								size={18}
								hasIcon
								name="SWIFT Fee"
								maxWidth={260}
							>
								{payment?.swift_fee_tooltip}
							</MoreInfoTooltip>
						)}
					</Typography>
				</div>

				{payment?.amount_due && (
					<LabelValuePair
						label={isCompletedTransaction ? "Amount settled:" : "Amount Due:"}
						value={payment.amount_due}
					/>
				)}
			</div>

			<div className={`${styles.rowPayment} grid-cols-2`}>
				<LabelValuePair
					label="Reference:"
					value={payment?.payment_reference || "-"}
				/>
				<LabelValuePair
					label="Purpose of Payment:"
					value={payment?.purpose_of_payment || "-"}
				/>
			</div>

			{recipient?.address && (
				<>
					<div className={styles.divider} />
					<div className={styles.subheaderRow}>
						<div className={styles.subheaderInner}>
							<h3 className={styles.subheader}>Recipient</h3>
							<div className={styles.tagContainer}>
								{recipient?.entity_type && (
									<div className={styles.tag}>{entityType}</div>
								)}
								{recipient?.country_label && (
									<div className={styles.tag}>{recipient.country_label}</div>
								)}

								<CurrencyTags currencies={currencies} />
							</div>
						</div>
						{!isMobile && isCompletedTransaction && recipient && (
							<div className="right-col flex flex-col justify-center">
								<Button
									size="sm"
									noPadding
									inline
									variant="tertiary"
									onClick={() => setShowRecipient(!showRecipient)}
								>
									{showRecipient ? "Hide details" : "Show details"}
								</Button>
							</div>
						)}
					</div>
					{!isMobile && (
						<div className={`${styles.rowPayment} grid-cols-2`}>
							<LabelValuePair
								label="Name:"
								value={
									(recipient?.entity_type as EntityType) === "legal_entity"
										? recipient.company_name || ""
										: `${recipient?.first_name} ${recipient?.last_name}` || ""
								}
							/>
						</div>
					)}
				</>
			)}

			{isMobile && recipient && (
				<div className={styles.details}>
					{recipient && (
						<div className={`${styles.rowPayment} grid-cols-1`}>
							<LabelValuePair
								label="Name:"
								value={
									(recipient?.entity_type as EntityType) === "legal_entity"
										? recipient?.company_name || ""
										: `${recipient?.first_name} ${recipient?.last_name}` || ""
								}
							/>
						</div>
					)}
					{recipientDetails}
				</div>
			)}

			{!isMobile && recipientDetails}

			{isCompletedTransaction && isMobile && recipient && (
				<Button
					size="sm"
					noPadding
					inline
					className={styles.showDetailsButton}
					variant="tertiary"
					onClick={() => setShowRecipient(!showRecipient)}
				>
					{showRecipient ? "Hide details" : "Show details"}
				</Button>
			)}
		</div>
	);
};
