import { ReactNode } from "react";
import { Button } from "../button";
import { Dialog } from "../dialog";
import { Title } from "../title";

import styles from "./index.module.css";

export const TooltipDialog = ({
	title,
	children,
	onBack,
}: {
	title: ReactNode;
	children: ReactNode;
	onBack: () => void;
}) => {
	return (
		<Dialog
			bottomsheet
			className={styles.dialogContent}
			actionsClassName={styles.dialogActions}
			isOpen
			actions={<Button onClick={onBack}>Back</Button>}
			title={
				<div className={styles.header}>
					<div>
						<Title variant="inline">{title}</Title>
					</div>
				</div>
			}
		>
			{children}
		</Dialog>
	);
};
