import { useNavigate } from "react-router-dom";
import styles from "./index.module.css";

import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "@app/components/more-info-tooltip/tooltip";
import { useMediaQuery } from "@app/hooks/use-media-query";
import { useOverflowDetect } from "@app/hooks/use-overflow-detect";
import clsx from "clsx";
import { ReactNode, useRef, useState } from "react";
import { Fragment } from "react/jsx-runtime";
import { TooltipDialog } from "../tooltip-dialog";
import infoSrc from "./info.svg";
import stepCompleteSrc from "./step-complete.svg";
import stepSrc from "./step.svg";

export type NavigationStep = {
	title: string;
	active: boolean;
	completed: boolean;
	path: string;
	description?: string;
	disabled?: boolean;
	key?: string;
	subSteps?: Array<{
		name: string;
		id: string;
		linkId?: string;
		iconSrc: string;
	}>;
};

export const NavigationSteps = ({
	steps,
	onStepNavigate,
	children,
	className,
	action,
}: {
	steps: Array<NavigationStep>;
	onStepNavigate?: (path: string, targetId?: string | undefined) => void;
	children?: ReactNode;
	action?: ReactNode;
	className?: string;
}) => {
	const [activeTooltip, setActiveTooltip] = useState<string | null>(null);
	const listRef = useRef<HTMLUListElement>(null);
	const isOverflowing = useOverflowDetect(listRef, steps);
	const navigate = useNavigate();
	const isMobile = useMediaQuery();

	return (
		<>
			<div className={clsx(styles.container, className)} id="onboarding-steps">
				{children && <div className={styles.heading}>{children}</div>}
				<div style={{ padding: isOverflowing ? "4px 6px 4px 0" : 0 }}>
					<ul ref={listRef} className={styles.list}>
						{steps.map((step, index) => {
							const content = (
								<Fragment key={step.title}>
									{index !== 0 ? <div className={styles.line} /> : null}
									{step.active ? <span className={styles.indicator} /> : null}
									<img
										data-testid={`step-${index}-${
											step.completed ? "completed" : "incomplete"
										}`}
										src={step.completed ? stepCompleteSrc : stepSrc}
										alt=""
										width={16}
										height={16}
									/>
									{step.title}
									{step.subSteps && (
										<div className={styles.count}>{step.subSteps.length}</div>
									)}
								</Fragment>
							);

							const handleNavigate = (id?: string) => {
								if (step.active && !id) return;

								if (onStepNavigate) {
									onStepNavigate(step.path, id);
								} else {
									const isCurrentStep = step.completed || step.active;
									if (step.completed || isCurrentStep) {
										navigate(step.path);
									}
								}
							};

							return (
								<>
									<li id={`step-${index}`} key={step.title}>
										{step.disabled && !isMobile ? (
											<Tooltip placement="bottom">
												<TooltipTrigger
													type="button"
													disabled
													className={styles.step}
													data-testid={`step-${index}-${
														step.active ? "active" : "inactive"
													}`}
												>
													{content}
												</TooltipTrigger>
												<TooltipContent className={styles.tooltip}>
													<img
														className={styles.icon}
														src={infoSrc}
														alt=""
														width={18}
														height={18}
													/>
													<div>
														You first need to complete prior steps before you
														can navigate to this one.
													</div>
												</TooltipContent>
											</Tooltip>
										) : (
											<button
												type="button"
												aria-disabled={step.disabled}
												className={styles.step}
												data-testid={`step-${index}-${
													step.active ? "active" : "inactive"
												}`}
												data-active={step.active}
												onClick={() => {
													if (step.disabled) {
														setActiveTooltip(step.title);
														return;
													}
													handleNavigate();
												}}
											>
												{content}
											</button>
										)}
									</li>
									{step.active &&
										step.subSteps?.map((substep) => {
											return (
												<li key={substep.name}>
													<button
														onClick={() => handleNavigate(substep.id)}
														type="button"
														className={styles.substep}
													>
														<div
															className={clsx(styles.line, styles.substepLine)}
														/>
														<div className={styles.substepContent}>
															{substep.iconSrc && (
																<img
																	src={substep.iconSrc}
																	alt=""
																	height={20}
																	width={20}
																/>
															)}
															<span className={styles.substepName}>
																{substep.name}
															</span>
														</div>
													</button>
												</li>
											);
										})}
								</>
							);
						})}
					</ul>
				</div>
				{action}
			</div>

			{activeTooltip && (
				<TooltipDialog
					title={activeTooltip}
					onBack={() => setActiveTooltip(null)}
				>
					You first need to complete prior steps before you can navigate to this
					one.
				</TooltipDialog>
			)}
		</>
	);
};
