import { useState } from "react";
import { OTPInputModal } from "../modals/otp-input-modal";
import { ResendOTPModal } from "../modals/resend-otp-modal";
import { FormErrors } from "@app/utils/get-form-errors";
import { OTPMethod } from "../use-otp";

export const StandardOTPFlow = ({
	onClose,
	onComplete,
	onResend,
	variant = "standard",
}: {
	onClose: () => void;
	variant?: "unauthed" | "standard";
	// Optional callback to resend the OTP. Overrides the default behaviour of the ResendOTPModal
	onResend?: (type: OTPMethod) => Promise<FormErrors | undefined>;
	// biome-ignore lint: Need to support async and sync callbacks
	onComplete: (token?: string) => void | Promise<FormErrors | void>;
}) => {
	const [showResendOTPModal, setShowResendOTPModal] = useState(false);
	const [showOTPInputModal, setShowOTPInputModal] = useState(true);

	const handleResendComplete = () => {
		setShowResendOTPModal(false);
		setShowOTPInputModal(true);
	};

	return (
		<>
			{showOTPInputModal && (
				<OTPInputModal
					variant={variant}
					onResend={() => {
						setShowResendOTPModal(true);
						setShowOTPInputModal(false);
					}}
					onClose={onClose}
					onConfirm={onComplete}
				/>
			)}
			{showResendOTPModal && (
				<ResendOTPModal
					variant={variant}
					onResend={onResend}
					onConfirm={handleResendComplete}
					onClose={handleResendComplete}
				/>
			)}
		</>
	);
};
