import { Column } from "primereact/column";
import {
	DataTable,
	type DataTablePassThroughOptions,
	type DataTableStateEvent,
	type DataTableValue,
	type DataTableValueArray,
	type SortOrder,
} from "primereact/datatable";
import { type CSSProperties, type ReactNode, useMemo } from "react";
import { twMerge } from "tailwind-merge";

import styles from "./index.module.css";

import Unsorted from "@app/assets/images/default-sort.svg";
import SortDown from "@app/assets/images/sort-down.svg";
import SortUp from "@app/assets/images/sort-up.svg";
import { Typography } from "@app/components/typography";
import type { GroupedArray } from "@app/entities";

import { useMediaQuery } from "@app/hooks/use-media-query";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "./table.css";

export interface ColumnProps<T> {
	alignHeader?: "left" | "right" | "center";
	cardField?: keyof T;
	className?: string;
	field: string;
	header: string | ReactNode;
	sortable?: boolean;
	width?: number | string;
}

export const TableView = <T,>(props: {
	cardViewContainerClassName?: string;
	cardViewGroupClassName?: string;
	cardViewItemClassName?: string;
	emptyMessage?: string;
	id?: string;
	lazy?: boolean;
	loading?: boolean;
	values?: DataTableValueArray;
	scrollable?: boolean;
	scrollHeight?: string;
	sortOrder?: SortOrder;
	stripedRows?: boolean;
	tableStyle?: CSSProperties;
	totalRecords: number;
	cardViewGroupHeaderTemplate?: (header: string) => ReactNode;
	cardViewTemplate?: (data: DataTableValue) => ReactNode;
	rowClassName?: (data: DataTableValue) => string;
	onClickCard?: (data: DataTableValue) => void;
	groupByField?: keyof unknown;
	groupedValues?: GroupedArray<DataTableValue> | DataTableValueArray;
	sortField?: keyof unknown;
	columns: ColumnProps<unknown>[];
	defaultSortField?: keyof unknown;
	sortFunction: (event: DataTableStateEvent) => DataTableStateEvent;
}) => {
	const isMobile = useMediaQuery();

	const generalWidth: string = useMemo(() => {
		let calc = "calc((100%";

		let numberOfUnsetColumns = props.columns.length;

		props.columns.forEach((column) => {
			if (!column.header) {
				--numberOfUnsetColumns;
				return;
			}

			if (column.width) {
				if (typeof column.width !== "string") {
					calc += ` - ${column.width}%`;

					--numberOfUnsetColumns;
				} else if (column.width) {
					calc += ` - ${column.width}`;

					--numberOfUnsetColumns;
				}
			}
		});

		calc += `)/${numberOfUnsetColumns})`;

		return calc;
	}, [props.columns]);

	const getColumnWidth = (col: ColumnProps<T>): CSSProperties => {
		if (!col.header) return {};

		if (col.width) {
			if (typeof col.width === "string")
				return {
					width: `${col.width}`,
					maxWidth: `${col.width}`,
				};

			return {
				width: `${col.width}%`,
				maxWidth: `${col.width}%`,
			};
		}

		return {
			width: `${generalWidth}`,
			maxWidth: `${generalWidth}`,
		};
	};

	const getTableDesktopContent = () => {
		return (
			<DataTable
				id={props.id}
				lazy={props.lazy}
				disabled={props.loading}
				pt={
					{
						headerRow: "bg-none",
						header: "bg-none",
						bodyRow: "text-gray-890",
						loadingOverlay: "bg-transparent rounded-2",
					} as DataTablePassThroughOptions
				}
				value={props.values}
				scrollable={props.scrollable}
				scrollHeight={props.scrollHeight}
				sortIcon={(options) => {
					switch (options.sortOrder) {
						case 0:
							return <img src={Unsorted} alt="" />;
						case -1:
							return <img src={SortDown} alt="" />;
						case 1:
							return <img src={SortUp} alt="" />;
					}
				}}
				sortField={props.sortField}
				sortOrder={props.sortOrder}
				stripedRows={props.stripedRows}
				emptyMessage={props.emptyMessage}
				tableStyle={props.tableStyle}
				rowClassName={props.rowClassName}
				onSort={props.sortFunction}
				removableSort
			>
				{props.columns.map((col, i) => (
					<Column
						key={col.field}
						field={col.field}
						header={col.header}
						className={twMerge(col.className, !col.header && "options-column")}
						bodyStyle={getColumnWidth(col)}
						sortable={col.sortable}
						alignHeader={col.alignHeader}
						style={getColumnWidth(col)}
					/>
				))}
			</DataTable>
		);
	};

	const getMobileItemContent = (data: DataTableValue, index: number) => {
		return (
			<button
				type="button"
				key={index}
				className={styles.button}
				onClick={() => {
					if (props.onClickCard) {
						props.onClickCard(data);
					}
				}}
			>
				{props.cardViewTemplate?.(data)}
			</button>
		);
	};

	const getTableMobileContent = () => {
		const areValuesArray = Array.isArray(props.groupedValues);

		const gk = Object.keys(props.groupedValues ?? {});

		return (
			<div
				className={twMerge(
					"flex flex-col",
					areValuesArray ? mediumVerticalGapStyle : "gap-y-4",
					props.cardViewContainerClassName,
				)}
			>
				{areValuesArray
					? (props.groupedValues as DataTableValueArray).map((x, i) => {
							return getMobileItemContent(x, i);
						})
					: gk.map((x, i) => {
							return (
								<div
									key={i}
									className={twMerge(
										"flex flex-col",
										"gap-y-2",
										props.cardViewGroupClassName,
									)}
								>
									{props.cardViewGroupHeaderTemplate ? (
										props.cardViewGroupHeaderTemplate(x)
									) : (
										<Typography
											className={"font-semibold text-gray-1100"}
											theme="textXs"
										>
											{x}
										</Typography>
									)}
									<div
										className={twMerge(
											"flex flex-col",
											mediumVerticalGapStyle,
											props.cardViewItemClassName,
										)}
									>
										{(props.groupedValues as GroupedArray<DataTableValue>)[
											x
										].map((y, i) => {
											return getMobileItemContent(y, i);
										})}
									</div>
								</div>
							);
						})}
			</div>
		);
	};

	const mediumVerticalGapStyle = "gap-y-3";

	return <>{isMobile ? getTableMobileContent() : getTableDesktopContent()}</>;
};
