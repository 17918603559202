import { InputSwitch as PrimeInputSwitch } from "primereact/inputswitch";
import { twMerge } from "tailwind-merge";

import "./input-switch.css";

export const InputSwitch = ({
	id,
	checked,
	onChange,
	className,
}: {
	id: string;
	className?: string;
	checked: boolean;
	onChange?: (value: boolean) => void;
}) => {
	return (
		<PrimeInputSwitch
			inputId={id}
			className={twMerge("inputSwitch", className)}
			checked={checked}
			onChange={({ value }) => onChange?.(value)}
		/>
	);
};
