import { useEffect, useState } from "react";

export const useIsActiveTab = () => {
	const [isActive, setIsActive] = useState(true);

	useEffect(() => {
		const handleVisibilityChange = () => setIsActive(!document.hidden);
		document.addEventListener("visibilitychange", handleVisibilityChange);
		return () =>
			document.removeEventListener("visibilitychange", handleVisibilityChange);
	}, []);

	return isActive;
};
