import { Controller } from "react-hook-form";

import { Typography } from "@app/components/typography";

import { FieldError } from "@app/components/field-error";
import styles from "../index.module.css";
import { showMappedReasons } from "../show-mapped-reasons";
import type { MultiSelectCurrencyPartialProps } from "../types";
import { MultiSelectNew } from "@app/features/onboarding/business-company-details/multi-select-new";
import { useCurrencies } from "@app/hooks/use-currencies";

export const MultiSelectCurrency = (props: MultiSelectCurrencyPartialProps) => {
	const { orderedMappedCurrencies } = useCurrencies();
	return (
		<Controller
			name={props.name}
			control={props.formControl}
			rules={{
				required: props.required ? "This field is required" : false,
				validate: (x) =>
					props.onCustomValidationRule?.(x) || props.customErrorMessage,
			}}
			render={({ field, fieldState: { error } }) => {
				return (
					<div
						className={`${props.fieldRowClassName || ""} ${styles.inputRow}`}
					>
						{props.showLabel && (
							<div className={styles.multiSelectCurrencyLabel}>
								<Typography
									theme="textSm"
									className={`${styles.formLabel} text-gray-1100`}
								>
									{props.title + (props.required ? "*" : "")}
								</Typography>
								{props.popover && props.popover}
							</div>
						)}

						<MultiSelectNew
							tagsClassName={styles.tags}
							mobileHeading="Select currencies"
							placeholder={props.placeholder}
							error={
								!!error ||
								showMappedReasons(
									props.mappedName ?? field.name,
									props.mappedReasons,
								) !== false
							}
							options={
								orderedMappedCurrencies.map((current) => ({
									id: current.currency_code,
									countryCode: current.country_code,
									name: current.verbose_name,
								})) ?? []
							}
							onChange={(value) => {
								const values = value.map((value) => ({
									currencyCode: value.id.toString(),
									countryCode: value.countryCode!,
									verboseName: value.name,
								}));
								field.onChange(values);
							}}
							value={field.value?.map(
								(value: {
									currencyCode: string;
									countryCode: string;
									verboseName: string;
								}) => ({
									id: value.currencyCode,
									countryCode: value.countryCode,
									name: value.verboseName,
								}),
							)}
						/>

						{error && <FieldError>{error.message}</FieldError>}
						{!error &&
							showMappedReasons(
								props.mappedName ?? field.name,
								props.mappedReasons,
							)}
					</div>
				);
			}}
		/>
	);
};
