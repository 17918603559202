import { parsePhoneNumber } from "libphonenumber-js";

export const formatPhoneNumber = (phoneNumber: string) => {
	try {
		const parsedNumber = parsePhoneNumber(phoneNumber);
		if (!parsedNumber?.isValid()) {
			return null;
		}
		return parsedNumber.formatInternational();
	} catch (error) {
		return null;
	}
};
