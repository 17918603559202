import type { GenericFailureResponse, MappedReasons } from "@app/services";

import { snakeCaseToCamelCase } from "./snake-case-to-camel-case";

const getMsg = (msg: any) => {
	try {
		const result = JSON.parse(msg);
		if (Array.isArray(result)) {
			return result.join(", ");
		}
		return result;
	} catch {
		return msg;
	}
};

export const getMappedReasons = (error: GenericFailureResponse) => {
	if (!error?.detail || !Array.isArray(error.detail)) {
		return undefined;
	}

	const fieldReasons = error.detail;
	const mappedReasons = fieldReasons.reduce((result, item) => {
		if (item.loc.length > 0) {
			let itemField = item.loc[item.loc.length - 1];
			if (itemField.split(".").length > 1) {
				itemField = itemField.split(".")[itemField.split(".").length - 1];
			}
			const itemFieldCamelCase = snakeCaseToCamelCase(itemField);
			result[itemFieldCamelCase] = [
				...(result[itemFieldCamelCase] || []),
				getMsg(item.msg),
			];
		}
		return result;
	}, {} as MappedReasons);
	return mappedReasons;
};
