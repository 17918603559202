import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { paths } from "@app/constants/paths";

import { Button } from "@app/components/button";
import styles from "./index.module.css";
import { ResumeLaterModal } from "./resume-later-modal";

import { ChangeSaved } from "@app/components/changes-saved";
import { useMediaQuery } from "@app/hooks/use-media-query";
import { FiHome } from "react-icons/fi";
import { STORAGE_KEYS } from "@app/constants/storage-keys";

export const TransactionDetailsFooter = ({
	isFailed = false,
	showResumeLater,
	onResumeLaterChange,
	hasAutoSave = false,
	isResumeLaterSecondaryAction = false,
	resumeLaterPath,
	isSaving,
	...props
}: {
	onBack: () => void;
	isFailed?: boolean;
	onNext?: () => void;
	onResumeLater?: () => Promise<void>;
	customNextLabel?: string;
	isLoading?: boolean;
	showResumeLater?: boolean;
	resumeLaterPath?: string;
	hasAutoSave?: boolean;
	onResumeLaterChange?: (show: boolean) => void;
	isResumeLaterSecondaryAction?: boolean;
	isSaving?: boolean;
}) => {
	const [hasSaved, setHasSaved] = useState(false);
	const [isLoading, setLoading] = useState(false);
	const navigate = useNavigate();
	const isMobile = useMediaQuery();

	const [showResumeLaterModal, setShowResumeLaterModal] = useState(false);

	const onResumeLater = () => setShowResumeLaterModal(true);

	const onResumeLaterClosed = () => {
		setShowResumeLaterModal(false);
		if (onResumeLaterChange) {
			onResumeLaterChange(false);
		}
	};

	const onResumeLaterConfirm = async () => {
		const path =
			resumeLaterPath ||
			window.sessionStorage.getItem(STORAGE_KEYS.resumeLaterPath) ||
			paths().dashboard;
		if (props.onResumeLater) {
			setLoading(true);
			await props.onResumeLater();
			if (onResumeLaterChange) {
				onResumeLaterChange(false);
			}
			navigate(path);
			setLoading(false);
		} else {
			navigate(path);
		}
	};

	useEffect(() => {
		if (typeof showResumeLater === "boolean") {
			setShowResumeLaterModal(showResumeLater);
		}
	}, [showResumeLater]);

	useEffect(() => {
		if (isSaving) {
			setHasSaved(true);
		}
	}, [isSaving]);

	return (
		<>
			<div
				className={styles.footer}
				style={{
					justifyContent: isFailed ? "center" : "space-between",
				}}
			>
				{isMobile && hasAutoSave && (
					<ChangeSaved
						status={isSaving ? "saving" : hasSaved ? "saved" : "unsaved"}
					/>
				)}
				{isFailed ? (
					<Button onClick={() => navigate(paths().dashboard)}>
						<FiHome size={20} />
						Home
					</Button>
				) : (
					<div className={styles.footerContent}>
						<div className={styles.leftAction}>
							{!isResumeLaterSecondaryAction ? (
								<Button
									className={styles.button}
									disabled={props.isLoading || isLoading}
									variant="secondary"
									onClick={props.onBack}
								>
									Back
								</Button>
							) : (
								<Button
									className={styles.button}
									variant="secondary"
									disabled={props.isLoading || isLoading}
									onClick={onResumeLater}
								>
									Resume later
								</Button>
							)}
						</div>

						{!isMobile && hasAutoSave && (
							<ChangeSaved
								status={isSaving ? "saving" : hasSaved ? "saved" : "unsaved"}
							/>
						)}

						<div className={styles.rightAction}>
							<Button
								className={styles.button}
								type={props.onNext ? "button" : "submit"}
								disabled={props.isLoading || isLoading}
								onClick={() => props.onNext?.()}
							>
								{props.customNextLabel ? props.customNextLabel : "Next"}
							</Button>
						</div>
					</div>
				)}
			</div>
			{showResumeLaterModal && (
				<ResumeLaterModal
					onBack={onResumeLaterClosed}
					onClose={onResumeLaterClosed}
					onConfirm={onResumeLaterConfirm}
				/>
			)}
		</>
	);
};
