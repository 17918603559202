import { useState } from "react";
import AnimateHeight from "react-animate-height";
import { useNotifications } from "./use-notifications";

import styles from "./notifcation-list.module.css";

export const NotificationList = ({
	showHeader = false,
}: {
	showHeader?: boolean;
}) => {
	const [expandedNotificationId, setExpandedNotificationId] = useState<
		number | null
	>(null);
	const { markAsRead, notifications, readNotifications, unreadCount } =
		useNotifications();
	return (
		<>
			{showHeader && (
				<>
					<h2 className={styles.header}>
						Notifications {unreadCount > 0 ? `(${unreadCount})` : ""}
					</h2>
					<hr className={styles.hr} />
				</>
			)}
			<ul
				className={styles.list}
				onClick={(event) => {
					event.preventDefault();
					event.stopPropagation();
				}}
			>
				{notifications.map((notification, index) => {
					const isExpanded =
						expandedNotificationId &&
						notification.id === expandedNotificationId;
					const isNextActive =
						notifications[index + 1] &&
						notifications[index + 1].id === expandedNotificationId;

					const isRead = readNotifications.includes(notification.id);
					return (
						<li key={notification.id}>
							<AnimateHeight
								duration={200}
								className={styles.item}
								data-next-active={isNextActive}
								data-active={isExpanded}
								height={isExpanded ? "auto" : 54}
							>
								<button
									type="button"
									className={styles.itemButton}
									onClick={() => {
										markAsRead(notification.id);
										setExpandedNotificationId(
											isExpanded ? null : notification.id,
										);
									}}
								>
									<div
										data-variant={
											isExpanded ? "expanded" : isRead ? "read" : "unread"
										}
										className={styles.notificationIndicator}
									/>
									<span className={styles.title} data-read={isRead}>
										{notification.title}
									</span>
									<svg
										role="presentation"
										className={styles.caret}
										xmlns="http://www.w3.org/2000/svg"
										width="20"
										height="20"
										viewBox="0 0 20 20"
										fill="none"
									>
										<path
											d="M5 7.5L10 12.5L15 7.5"
											stroke="#888"
											strokeWidth="2"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</button>
								<p className={styles.description}>{notification.description}</p>
							</AnimateHeight>
						</li>
					);
				})}
			</ul>
		</>
	);
};
