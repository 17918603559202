import { formatPhoneNumber } from "./format-phone-number";

/**
 * Formats and obfuscates a phone number, showing only the country code and last 4 digits
 * @param phoneNumber - The phone number to format and obfuscate
 * @returns Formatted and obfuscated phone number or null if invalid
 *
 * @example
 * console.log(formatAndObfuscatePhoneNumber('+1234567890')); // +1 ***** 7890
 * console.log(formatAndObfuscatePhoneNumber('+442071838750')); // +44 ** **** 8750
 */
export const formatAndObfuscatePhoneNumber = (phoneNumber: string) => {
	const formattedNumber = formatPhoneNumber(phoneNumber);
	if (!formattedNumber) return null;
	const parts = formattedNumber.split(" ");

	const diallingCode = parts[0];

	const lastFourDigits = parts[parts.length - 1].slice(-4);

	const middleParts = parts.slice(1, -1);
	const lastPart = parts[parts.length - 1].slice(0, -4);

	const obfuscatedMiddle = [...middleParts, lastPart]
		.map((part) => "*".repeat(part.length))
		.join(" ");

	return `${diallingCode} ${obfuscatedMiddle} ${lastFourDigits}`.trim();
};
