import clsx from "clsx";
import styles from "./index.module.css";

export const StatusTag = ({ status }: { status?: string }) => {
	if (!status) return null;

	if (status === "complete") {
		return (
			<div data-status={status} className={clsx(styles.tag, styles.complete)}>
				<svg
					width="20"
					height="21"
					role="presentation"
					viewBox="0 0 20 21"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<rect y="0.0625" width="20" height="20" rx="10" fill="#56A7A2" />
					<path
						d="M5.07812 10.3784L8.15313 13.542L14.9181 6.58203"
						stroke="white"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
				Complete
			</div>
		);
	}
	return (
		<div data-status={status} className={styles.tag}>
			{status === "complete" && "Complete"}
			{status === "awaiting_submission" && "Awaiting submission"}
			{status === "in_progress" && "In progress"}
		</div>
	);
};
