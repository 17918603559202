import { Button } from "@app/components/button";
import { useRef, useState } from "react";

import { IconButton } from "@app/components/icon-button";
import { MoreInfoTooltip } from "@app/components/more-info-tooltip";
import RefreshIcon from "@app/icons/refresh.svg?react";
import { toDayjs } from "@app/lib/date";
import { sleep } from "@app/utils/sleep";
import { useBalance } from "../use-balance";
import { useRefreshedBalance } from "../use-refreshed-balance";
import { AddFundsModal } from "./add-funds-modal";
import { DownloadStatementModal } from "./download-statement-modal";
import styles from "./index.module.css";
import { isAvailableBalanceEnabled } from "@app/constants/feature-flags";

const REFRESH_INTERVAL = 5 * 60 * 1000;

const getBalanceParts = (balance?: string) => {
	const currencyAndRands = balance?.split(".")[0];
	let rands = currencyAndRands?.split(" ")[1];
	let currency = "ZAR";
	const currencyAndRandsParts = currencyAndRands?.split(" ");
	const cents = balance?.includes(".") ? balance?.split(".")[1] : "00";

	if (currencyAndRandsParts && currencyAndRandsParts?.length > 1) {
		[currency, rands] = currencyAndRands?.split(" ") ?? [];
	}

	if (currency.startsWith("-")) {
		currency = currency.replace("-", "");
		rands = `-${rands}`;
	}

	return {
		currency,
		rands,
		cents,
	};
};

export const BalanceCard = ({
	onWithdraw,
	backAccountId,
	isLoading = false,
}: {
	backAccountId?: number;
	onWithdraw?: () => void;
	isLoading?: boolean;
}) => {
	const [isRefreshing, setIsRefreshing] = useState(false);
	const defaultBalanceResult = useBalance(backAccountId);
	const refreshedBalanceResult = useRefreshedBalance(backAccountId);

	const balanceResult = refreshedBalanceResult.data
		? refreshedBalanceResult
		: defaultBalanceResult;

	const [showAddFundsModal, setShowAddFundsModal] = useState(false);
	const [showStatementModal, setShowStatementModal] = useState(false);
	const [nextRefreshTime, setNextRefreshTime] = useState<string | null>(null);
	const refreshTimeout = useRef<NodeJS.Timeout | null>(null);
	const [refreshEnabled, setRefreshEnabled] = useState(true);
	const balance = balanceResult.data?.balance;
	const { rands, cents, currency } = getBalanceParts(balance);

	const isCardLoading = isLoading || balanceResult.isLoading;
	const lastUpdatedMessage = balanceResult.data?.last_updated
		? `Last updated ${toDayjs(balanceResult.data.last_updated).format("HH:mm on D MMM YYYY")}`
		: "Your balance will reflect soon";

	const holdAmountValue = balanceResult.data?.hold_amount?.split(" ")[1];
	const hasHoldAmount = Number.parseFloat(holdAmountValue ?? "0") > 0;

	const handleRefresh = async () => {
		await refreshedBalanceResult.mutate();
		setNextRefreshTime(toDayjs().add(5, "minutes").format("HH:mm"));
		setRefreshEnabled(false);
		await sleep(500);
		setIsRefreshing(false);
		refreshTimeout.current = setTimeout(() => {
			setNextRefreshTime(null);
			setRefreshEnabled(true);
		}, REFRESH_INTERVAL);
	};

	return (
		<>
			<div className={styles.container}>
				{refreshEnabled ? (
					<IconButton
						data-testid="refresh-button"
						onClick={handleRefresh}
						className={styles.refreshButton}
						data-refreshing={isRefreshing}
					>
						<RefreshIcon width={24} height={24} />
					</IconButton>
				) : (
					<MoreInfoTooltip
						className={styles.refreshButton}
						indicator={
							<IconButton disabled data-testid="disabled-refresh-button">
								<RefreshIcon width={24} height={24} />
							</IconButton>
						}
						position="top"
						name="Refresh Balance"
						maxWidth={180}
					>
						Your balance was updated within the last 5 minutes. You can try
						again at {nextRefreshTime}.
					</MoreInfoTooltip>
				)}
				<div>
					<h2 className={styles.title}>
						{isAvailableBalanceEnabled ? "Available Balance" : "Balance"}{" "}
					</h2>
					<p className={styles.rands}>
						{isCardLoading ? (
							"-"
						) : (
							<>
								{currency} {rands ?? "0"}
								{cents && <span className={styles.cents}>.{cents}</span>}
							</>
						)}
					</p>
					{hasHoldAmount && (
						<div className={styles.holdAmount}>
							{hasHoldAmount ? (
								<>
									{balanceResult.data?.hold_amount} on hold -{" "}
									<MoreInfoTooltip
										name="Why are funds on hold?"
										maxWidth={256}
										className={styles.why}
										indicator="Why?"
									>
										<div className={styles.whyTooltipContent}>
											Funds are put on hold for:
											<ul className={styles.whyTooltipList}>
												<li>Send transactions "in progress"</li>
												<li>Pending withdrawals</li>
												<li>FEC deposits</li>
											</ul>
											<p className={styles.whyTooltipDescription}>
												And are deducted from your available balance.
											</p>
											If your balance is negative, please deposit the required
											funds before 11:00am on the transaction's value date.
										</div>
									</MoreInfoTooltip>
								</>
							) : (
								`Excludes ${balanceResult.data?.hold_amount} on hold`
							)}
						</div>
					)}
					{isCardLoading ? (
						<p className={styles.lastUpdated}>-</p>
					) : lastUpdatedMessage ? (
						<p className={styles.lastUpdated}>
							{lastUpdatedMessage}
							{balanceResult.data?.warning_message && (
								<MoreInfoTooltip
									variant="alert"
									name={
										balanceResult.data?.warning_message.includes("last updated")
											? "Last updated"
											: "Balance"
									}
								>
									<p className={styles.warningMessage}>
										{balanceResult.data?.warning_message}
									</p>
								</MoreInfoTooltip>
							)}
						</p>
					) : null}
				</div>
				<div>
					<div className={styles.buttons}>
						<Button
							className={styles.button}
							block
							disabled={isCardLoading}
							onClick={() => setShowAddFundsModal(true)}
						>
							Add funds
						</Button>
						<Button
							className={styles.button}
							block
							disabled={isCardLoading}
							variant="secondary"
							onClick={onWithdraw}
						>
							Withdraw ZAR
						</Button>
					</div>
					<Button
						block
						variant="secondary"
						disabled={isCardLoading}
						onClick={() => setShowStatementModal(true)}
					>
						<svg
							role="presentation"
							xmlns="http://www.w3.org/2000/svg"
							width="21"
							height="21"
							viewBox="0 0 21 21"
							fill="none"
						>
							<path
								d="M17.9984 12.5131L17.9981 13.5131C17.9976 14.9132 17.9974 15.6133 17.7247 16.148C17.4848 16.6183 17.1023 17.0006 16.6318 17.2401C16.0969 17.5124 15.3968 17.5122 13.9967 17.5117L6.99669 17.5092C5.59656 17.5087 4.8965 17.5085 4.36181 17.2358C3.89149 16.996 3.50918 16.6134 3.26966 16.1429C2.99736 15.608 2.99761 14.9079 2.9981 13.5078L2.99845 12.5078M14.6666 8.34524L10.4984 12.5104M10.4984 12.5104L6.33324 8.34231M10.4984 12.5104L10.502 2.51044"
								stroke="currentColor"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
						Download statement
					</Button>
				</div>
			</div>
			{showAddFundsModal && (
				<AddFundsModal
					bankAccountId={backAccountId}
					onClose={() => setShowAddFundsModal(false)}
				/>
			)}
			{showStatementModal && (
				<DownloadStatementModal onClose={() => setShowStatementModal(false)} />
			)}
		</>
	);
};
