const emailSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
<path d="M1.83398 4.66602L7.27726 8.47631C7.71804 8.78486 7.93844 8.93913 8.17816 8.99889C8.38991 9.05167 8.61139 9.05167 8.82314 8.99889C9.06287 8.93913 9.28326 8.78486 9.72404 8.47631L15.1673 4.66602M5.03398 13.3327H11.9673C13.0874 13.3327 13.6475 13.3327 14.0753 13.1147C14.4516 12.9229 14.7576 12.617 14.9493 12.2407C15.1673 11.8128 15.1673 11.2528 15.1673 10.1327V5.86602C15.1673 4.74591 15.1673 4.18586 14.9493 3.75803C14.7576 3.38171 14.4516 3.07575 14.0753 2.884C13.6475 2.66602 13.0874 2.66602 11.9673 2.66602H5.03398C3.91388 2.66602 3.35383 2.66602 2.926 2.884C2.54968 3.07575 2.24372 3.38171 2.05197 3.75803C1.83398 4.18586 1.83398 4.74591 1.83398 5.86602V10.1327C1.83398 11.2528 1.83398 11.8128 2.05197 12.2407C2.24372 12.617 2.54968 12.9229 2.926 13.1147C3.35383 13.3327 3.91388 13.3327 5.03398 13.3327Z" stroke="#888" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;

const phoneSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
<path d="M15.1669 11.2807V13.2807C15.1677 13.4664 15.1297 13.6502 15.0553 13.8203C14.9809 13.9904 14.8718 14.1431 14.735 14.2686C14.5982 14.3941 14.4367 14.4897 14.2608 14.5492C14.0849 14.6087 13.8985 14.6308 13.7136 14.614C11.6622 14.3911 9.69161 13.6901 7.96028 12.5674C6.3495 11.5438 4.98384 10.1782 3.96028 8.56738C2.8336 6.82819 2.13244 4.84805 1.91361 2.78738C1.89695 2.60303 1.91886 2.41722 1.97795 2.2418C2.03703 2.06637 2.13199 1.90518 2.25679 1.76846C2.38159 1.63175 2.53348 1.52252 2.70281 1.44773C2.87213 1.37294 3.05517 1.33422 3.24028 1.33405H5.24028C5.56382 1.33086 5.87748 1.44543 6.12279 1.6564C6.3681 1.86737 6.52833 2.16035 6.57361 2.48072C6.65803 3.12076 6.81458 3.7492 7.04028 4.35405C7.12998 4.59266 7.14939 4.85199 7.09622 5.1013C7.04305 5.35061 6.91952 5.57946 6.74028 5.76072L5.89361 6.60738C6.84265 8.27641 8.22458 9.65835 9.89361 10.6074L10.7403 9.76072C10.9215 9.58147 11.1504 9.45795 11.3997 9.40478C11.649 9.35161 11.9083 9.37102 12.1469 9.46072C12.7518 9.68642 13.3802 9.84297 14.0203 9.92738C14.3441 9.97307 14.6399 10.1362 14.8513 10.3857C15.0627 10.6352 15.1751 10.9538 15.1669 11.2807Z" stroke="#888" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;

const whatsappSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
<path d="M6.03275 4.36353C5.8508 3.96557 5.57168 4.0008 5.28971 4.0008C4.78577 4.0008 4 4.65811 4 5.88143C4 6.88401 4.4057 7.98149 5.77277 9.62321C7.0921 11.2076 8.82562 12.0272 10.2648 11.9993C11.7039 11.9714 12 10.6228 12 10.1674C12 9.96552 11.885 9.86481 11.8057 9.83744C11.3153 9.58114 10.4108 9.10358 10.205 9.01386C9.99917 8.92413 9.89171 9.04549 9.82491 9.11152C9.63827 9.3052 9.26831 9.87599 9.14163 10.0044C9.01496 10.1328 8.82611 10.0678 8.74752 10.0193C8.45832 9.89288 7.67419 9.51307 7.04916 8.85327C6.27616 8.03729 6.23079 7.75655 6.08516 7.50665C5.96865 7.30673 6.05415 7.18407 6.09681 7.13047C6.26335 6.92121 6.49332 6.59812 6.59645 6.43757C6.69958 6.27702 6.61771 6.03326 6.56858 5.88144C6.3573 5.22849 6.17831 4.6819 6.03275 4.36353Z" fill="#888"/>
<path d="M1.55327 12.1292L1.60683 11.929L1.50306 11.7496C0.865217 10.647 0.5 9.36691 0.5 8C0.5 3.85786 3.85786 0.5 8 0.5C12.1421 0.5 15.5 3.85786 15.5 8C15.5 12.1421 12.1421 15.5 8 15.5C6.68078 15.5 5.44252 15.1598 4.36659 14.5628L4.19589 14.4681L4.00617 14.5141L0.700709 15.3156L1.55327 12.1292Z" stroke="#888"/>
</svg>`;

export const formatStatusMessage = (message: string) => {
	if (!message) return message;
	return message
		.replaceAll(`<span class="icon">Email:</span>`, emailSvg)
		.replaceAll(`<span class="icon">Whatsapp:</span>`, whatsappSvg)
		.replaceAll(`<span class="icon">WhatsApp:</span>`, whatsappSvg)
		.replaceAll(`<span class="icon">Call:</span>`, phoneSvg)
		.replaceAll(`<span class="icon">Phone:</span>`, phoneSvg)
		.replaceAll("Email: ", emailSvg)
		.replaceAll("Call: ", phoneSvg)
		.replaceAll("Phone: ", phoneSvg)
		.replaceAll("Whatsapp: ", whatsappSvg)
		.replaceAll("WhatsApp: ", whatsappSvg);
};
