import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Button } from "@app/components/button";
import { paths } from "@app/constants/paths";
import {
	type FilterRecipientsByForm,
	type ListTransaction,
	type TransactionDirection,
} from "@app/entities";
import { useSetRecipientId } from "@app/helpers";
import type { RootState } from "@app/redux";
import type { TransactionsQueryParams } from "@app/services";

import { CountryIcon } from "@app/components/country-icon";
import { PaymentTypeCell } from "@app/components/payment-type-cell";
import { dateFormats } from "@app/constants/date-formats";
import { useClients } from "@app/hooks/use-clients";
import {
	tempCompletedTransactionMapping,
	useCompletedTransactions,
} from "@app/hooks/use-completed-transactions";
import {
	MappedCurrency,
	tempCurrenciesMapping,
	useCurrencies,
} from "@app/hooks/use-currencies";
import {
	tempInProgressTransactionMapping,
	useInProgressTransactions,
} from "@app/hooks/use-in-progress-transactions";
import {
	RecipientNames,
	useRecipientNames,
} from "@app/hooks/use-recipient-names";
import { toDayjs } from "@app/lib/date";
import type {
	TransactionTableModel,
	TransactionsFilterState,
} from "./models/models";
import { TransactionsView } from "./transactions-view";

const FILTER_GROUP_SIZE = 3;

const tempCompletedTransactionQueryMap = (data: TransactionsQueryParams) => {
	return {
		ordering:
			data.sortOrder === 1 || data.sortOrder === -1
				? `${data.sortOrder === -1 ? "-" : ""}${data.sortField}`
				: undefined,
		country: data.country,
		start_date: data.startDate
			? toDayjs(data.startDate).format(dateFormats.iso8601)
			: undefined,
		end_date: data.endDate
			? toDayjs(data.endDate).format(dateFormats.iso8601)
			: undefined,
		direction: data.direction,
		currencies:
			data.currencies && data.currencies.length > 0
				? data.currencies.join(",")
				: undefined,
		recipients:
			data.recipients && data.recipients.length > 0
				? data.recipients.join(",")
				: undefined,
		search: data.search,
		limit: data.limit ? Number.parseInt(data.limit) : undefined,
		offset: data.offset ? Number.parseInt(data.offset) : undefined,
	};
};

const tempInProgressTransactioQueryMap = (data: TransactionsQueryParams) => {
	return {
		country: data.country,
		start_date: data.startDate
			? toDayjs(data.startDate).format(dateFormats.iso8601)
			: undefined,
		end_date: data.endDate
			? toDayjs(data.endDate).format(dateFormats.iso8601)
			: undefined,
		direction: data.direction,
		currencies:
			data.currencies && data.currencies.length > 0
				? data.currencies.join(",")
				: undefined,
		recipients:
			data.recipients && data.recipients.length > 0
				? data.recipients.join(",")
				: undefined,
		search: data.search,
	};
};

interface TransactionsLogicState {
	currentPage?: number;
}

const DEFAULT_STATE: TransactionsQueryParams = {
	limit: "10",
	offset: "0",
	recipients: [],
	sortOrder: -1,
	sortField: "transaction_date",
	direction: undefined,
	currencies: [],
	search: "",
};

const Transactions = () => {
	const navigate = useNavigate();
	const [setRecipientId] = useSetRecipientId();

	const { control } = useForm<FilterRecipientsByForm>({});

	const { recipientId } = useSelector(
		(rootState: RootState) => rootState.recipients,
	);
	const { data: recipientNames } = useRecipientNames();

	const currenciesResult = useCurrencies();
	const { activeClientId, isLoading: isClientsLoading } = useClients();

	const [state, setState] = useState<TransactionsQueryParams>(DEFAULT_STATE);
	const inProgressTransactionsResult = useInProgressTransactions(
		activeClientId,
		tempInProgressTransactioQueryMap(state),
	);
	const completedTransactionsResult = useCompletedTransactions(
		activeClientId,
		tempCompletedTransactionQueryMap(state),
	);

	const defaultFilterStateOptions: TransactionsFilterState = {
		currencyOptions: [],
		directionOptions: ["both", "receive", "send"],
		recipientNameOptions: [],
	};
	const defaultFilterStateSelected: TransactionsFilterState = {
		appliedCurrenciesSelected: undefined,
		appliedDirectionsSelected: undefined,
		appliedRecipientNamesSelected: undefined,
		appliedEndDate: undefined,
		appliedStartDate: undefined,
		currentCurrenciesSelected: undefined,
		currentDirectionsSelected: defaultFilterStateOptions.directionOptions
			? [defaultFilterStateOptions.directionOptions[0]]
			: undefined,
		currentEndDate: undefined,
		currentRecipientNamesSelected: recipientId ? [] : undefined,
		currentStartDate: undefined,
	};

	const getDefaultRecipientsFilter = ({
		currencyOptions = [],
		recipientNameOptions = [],
	}: {
		currencyOptions?: MappedCurrency[];
		recipientNameOptions?: RecipientNames[];
	}): TransactionsFilterState => {
		if (recipientNames) {
			if (recipientNames.length > 0) {
				recipientNameOptions = recipientNames;
			}
		}
		if (currenciesResult.data?.currency_mapping) {
			currencyOptions = currenciesResult.data?.currency_mapping.map(
				tempCurrenciesMapping,
			);
		}

		const currentRecipient = recipientNameOptions?.find(
			(option) => option.id === recipientId,
		);

		return {
			currentRecipientNamesSelected: currentRecipient
				? [currentRecipient]
				: undefined,
			appliedRecipientNamesSelected: currentRecipient
				? [currentRecipient]
				: undefined,
			currencyOptions,
			recipientNameOptions,
		};
	};

	const defaultFilterState: TransactionsFilterState = {
		...defaultFilterStateSelected,
		...defaultFilterStateOptions,
		...getDefaultRecipientsFilter({}),
	};
	const [filterState, setFilterState] =
		useState<TransactionsFilterState>(defaultFilterState);

	const defaultLogicState: TransactionsLogicState = {
		currentPage: undefined,
	};
	const [showFilters, setShowFilters] = useState(false);
	const [logicState, setLogicState] =
		useState<TransactionsLogicState>(defaultLogicState);

	const [currenciesOverflowing, setCurrenciesOverflowing] = useState(false);
	const [recipientsOverflowing, setRecipientsOverflowing] = useState(false);
	const [listCompletedTransactions, setListCompletedTransactions] = useState<
		TransactionTableModel[] | undefined
	>(undefined);

	const getTotalFilters = () => {
		const totalFilters =
			(filterState.appliedCurrenciesSelected?.length || 0) +
			(filterState.appliedRecipientNamesSelected?.length || 0) +
			(filterState.appliedStartDate || filterState.appliedEndDate ? 1 : 0) +
			(filterState.appliedDirectionsSelected
				? filterState.appliedDirectionsSelected?.filter(
						(x) => x !== ("both" as TransactionDirection),
					)?.length
				: 0);

		return totalFilters;
	};

	const onApplyFilters = () => {
		setFilterState({
			...filterState,
			appliedCurrenciesSelected: [
				...(filterState.currentCurrenciesSelected ?? []),
			],
			appliedDirectionsSelected: [
				...(filterState.currentDirectionsSelected?.filter(
					(x) => x !== ("both" as TransactionDirection),
				) ?? []),
			],
			appliedEndDate: filterState.currentEndDate
				? new Date(filterState.currentEndDate)
				: undefined,
			appliedRecipientNamesSelected: [
				...(filterState.currentRecipientNamesSelected ?? []),
			],
			appliedStartDate: filterState.currentStartDate
				? new Date(filterState.currentStartDate)
				: undefined,
		});
	};

	const onChangeCurrencyFilters = (value: MappedCurrency[]) => {
		setFilterState({ ...filterState, currentCurrenciesSelected: value });
	};

	const onChangeDirectionFilters = (value?: TransactionDirection[]) => {
		setFilterState({ ...filterState, currentDirectionsSelected: value });
	};

	const onChangeEndDateFilter = (value: Date) => {
		setFilterState({ ...filterState, currentEndDate: value });
	};

	const onChangeRecipientFilters = (value: RecipientNames[]) => {
		setFilterState({
			...filterState,
			currentRecipientNamesSelected: value,
		});
	};

	const onChangeStartDateFilter = (value: Date) => {
		setFilterState({ ...filterState, currentStartDate: value });
	};

	const onClearFilters = () => {
		setFilterState({ ...filterState, ...defaultFilterStateSelected });
		setState(DEFAULT_STATE);
	};

	const onCurrenciesOverflow = (overflow: boolean) => {
		setCurrenciesOverflowing(overflow);
	};

	const onPageChange = (page: number, rowsPerPage: number) => {
		setState({
			...state,
			limit: rowsPerPage.toString(),
			offset: Math.max(0, (page - 1) * rowsPerPage).toString(),
		});

		setLogicState((logicState) => ({ ...logicState, currentPage: page }));
	};

	const onRecipientsOverflow = (overflow: boolean) => {
		setRecipientsOverflowing(overflow);
	};

	const onRemoveCurrencyFilter = (value: MappedCurrency) => {
		const newCurrencies = filterState.currentCurrenciesSelected;

		const foundCurrencyIndex = newCurrencies?.findIndex(
			(x) => x.currencyCode === value.currencyCode,
		);

		if (foundCurrencyIndex !== undefined && foundCurrencyIndex > -1) {
			newCurrencies?.splice(foundCurrencyIndex, 1);

			setFilterState({
				...filterState,
				currentCurrenciesSelected: newCurrencies,
			});
		}
	};

	const onRemoveCurrencyFilterTag = (value: MappedCurrency) => {
		const newCurrencies = [...(filterState.currentCurrenciesSelected ?? [])];

		const foundCurrencyIndex = newCurrencies?.findIndex(
			(x) => x.currencyCode === value.currencyCode,
		);

		if (foundCurrencyIndex !== undefined && foundCurrencyIndex > -1) {
			newCurrencies?.splice(foundCurrencyIndex, 1);

			setFilterState({
				...filterState,
				currentCurrenciesSelected: newCurrencies,
				appliedCurrenciesSelected: [...newCurrencies],
			});
		}
	};

	const onRemoveCurrencyFiltersTag = () => {
		setFilterState({
			...filterState,
			currentCurrenciesSelected: [],
			appliedCurrenciesSelected: [],
		});
	};

	const onRemoveDateFiltersTag = () => {
		setFilterState({
			...filterState,
			currentStartDate: undefined,
			currentEndDate: undefined,
			appliedEndDate: undefined,
			appliedStartDate: undefined,
		});
	};

	const onRemoveDirectionFilterTag = (value: TransactionDirection) => {
		const newDirections = [...(filterState.currentDirectionsSelected ?? [])];

		const foundDirectionIndex = newDirections?.findIndex((x) => x === value);

		if (foundDirectionIndex !== undefined && foundDirectionIndex > -1) {
			newDirections?.splice(foundDirectionIndex, 1);

			setFilterState({
				...filterState,
				currentDirectionsSelected:
					newDirections.length === 0 &&
					defaultFilterState.currentDirectionsSelected
						? [...(defaultFilterState.currentDirectionsSelected ?? [])]
						: newDirections,
				appliedDirectionsSelected: [...newDirections],
			});
		}
	};

	const onRemoveDirectionFiltersTag = () => {
		setFilterState({
			...filterState,
			currentDirectionsSelected: defaultFilterState.currentDirectionsSelected,
			appliedDirectionsSelected: [],
		});
	};

	const onRemoveRecipientFilter = (value: RecipientNames) => {
		const newRecipients = filterState.currentRecipientNamesSelected;

		const foundRecipientIndex = newRecipients?.findIndex((x) => x === value);

		if (foundRecipientIndex !== undefined && foundRecipientIndex > -1) {
			newRecipients?.splice(foundRecipientIndex, 1);

			setFilterState({
				...filterState,
				currentRecipientNamesSelected: newRecipients,
			});
		}
	};

	const onRemoveRecipientFilterTag = (value: RecipientNames) => {
		const newRecipients = [
			...(filterState.currentRecipientNamesSelected ?? []),
		];

		const foundRecipientIndex = newRecipients?.findIndex(
			(x) => JSON.stringify(x) === JSON.stringify(value),
		);

		if (foundRecipientIndex !== undefined && foundRecipientIndex > -1) {
			newRecipients?.splice(foundRecipientIndex, 1);

			setFilterState({
				...filterState,
				currentRecipientNamesSelected: newRecipients,
				appliedRecipientNamesSelected: [...newRecipients],
			});
		}
	};

	const onRemoveRecipientFiltersTag = () => {
		setFilterState({
			...filterState,
			currentRecipientNamesSelected: [],
			appliedRecipientNamesSelected: [],
		});
	};

	const onFilterByName = (value: string) => {
		setLogicState((logicState) => ({ ...logicState, currentPage: 1 }));
		setState((state) => ({
			...state,
			search: value,
		}));
	};

	const onSortByName = (sortOrder: number, columnKey: string) => {
		setState((state) => ({
			...state,
			sortOrder,
			sortField: columnKey,
		}));
	};

	const onToggleShowFilters = () => {
		setShowFilters(!showFilters);
	};

	const mapToTableRowModel = useCallback(
		(x: ListTransaction): TransactionTableModel => {
			return {
				id: x.id,
				date: toDayjs(x.date, dateFormats.reverseIso8601).format(
					dateFormats.paddedDayShortMonthYear,
				),
				recipient: x.recipient,
				recipientDisplay: (
					<>
						{x.recipient === "N/A" ? (
							<div className="transactions-empty-table-cell">{x.recipient}</div>
						) : (
							<>{x.recipient}</>
						)}
					</>
				),
				zarAmount: x.zarAmount,
				zarAmountDisplay: (
					<>
						{x.zarAmount !== "" ? (
							<>
								<CountryIcon currencyCode="ZAR" width={16} height={16} />
								{x.zarAmount}
							</>
						) : (
							<div className="transactions-empty-table-cell">-</div>
						)}
					</>
				),
				fxAmount: x.fxAmount,
				fxAmountDisplay: (
					<>
						{x.fxAmount !== "" ? (
							<>
								<CountryIcon
									currencyCode={x.fxCurrency}
									width={16}
									height={16}
								/>
								{x.fxAmount}
							</>
						) : (
							<div className="transactions-empty-table-cell">-</div>
						)}
					</>
				),
				fxCurrency: x.fxCurrency,
				paymentType: x.paymentType,
				tablePaymentType: <PaymentTypeCell type={x.paymentType} />,
				view: (
					<Button
						variant="secondary"
						size="sm"
						onClick={() => {
							navigate(paths().viewTransaction(x.id));
						}}
					>
						View
					</Button>
				),
			};
		},
		[navigate],
	);

	useEffect(() => {
		setLogicState((logicState) => ({ ...logicState, currentPage: 1 }));
		setState((state) => ({
			...state,
			currencies: filterState.appliedCurrenciesSelected?.map(
				(x) => x.currencyCode,
			),
			direction: filterState.appliedDirectionsSelected
				? filterState.appliedDirectionsSelected[0]
				: undefined,
			endDate: filterState.appliedEndDate,
			startDate: filterState.appliedStartDate,
			recipients: filterState.appliedRecipientNamesSelected
				? filterState.appliedRecipientNamesSelected?.map((x) => `${x.id}`)
				: state.recipients,
		}));
	}, [
		filterState.appliedCurrenciesSelected,
		filterState.appliedDirectionsSelected,
		filterState.appliedEndDate,
		filterState.appliedRecipientNamesSelected,
		filterState.appliedStartDate,
	]);

	useEffect(() => {
		if (completedTransactionsResult.data) {
			setListCompletedTransactions(
				completedTransactionsResult.data?.items
					.map(tempCompletedTransactionMapping)
					.map((x) => mapToTableRowModel(x)),
			);
		}
	}, [completedTransactionsResult.data, mapToTableRowModel]);

	useEffect(() => {
		return () => {
			setRecipientId(undefined);
		};
	}, []);

	useEffect(() => {
		setFilterState({
			...filterState,
			...getDefaultRecipientsFilter(filterState),
		});
	}, [recipientNames]);

	return (
		<TransactionsView
			filterGroupSize={FILTER_GROUP_SIZE}
			control={control}
			currencies={currenciesResult.data?.currency_mapping.map(
				tempCurrenciesMapping,
			)}
			currenciesOverflowing={currenciesOverflowing}
			currentPage={logicState.currentPage}
			filterState={filterState}
			listCompletedTransactions={listCompletedTransactions}
			listInProgressTransactions={inProgressTransactionsResult.data?.map(
				tempInProgressTransactionMapping,
			)}
			loading={currenciesResult.isLoading || isClientsLoading}
			loadingTableData={
				inProgressTransactionsResult.isLoading ||
				completedTransactionsResult.isLoading
			}
			total={completedTransactionsResult.data?.count}
			totalFilters={getTotalFilters()}
			recipientsOverflowing={recipientsOverflowing}
			showFilters={showFilters}
			state={state}
			onApplyFilters={onApplyFilters}
			onChangeCurrencyFilters={onChangeCurrencyFilters}
			onChangeDirectionFilter={onChangeDirectionFilters}
			onChangeEndDateFilter={onChangeEndDateFilter}
			onChangeRecipientFilters={onChangeRecipientFilters}
			onChangeStartDateFilter={onChangeStartDateFilter}
			onClearFilters={onClearFilters}
			onFilterByName={onFilterByName}
			onPageChange={onPageChange}
			onCurrenciesOverflow={onCurrenciesOverflow}
			onRecipientsOverflow={onRecipientsOverflow}
			onRemoveCurrencyFilter={onRemoveCurrencyFilter}
			onRemoveCurrencyFilterTag={onRemoveCurrencyFilterTag}
			onRemoveCurrencyFiltersTag={onRemoveCurrencyFiltersTag}
			onRemoveDateFiltersTag={onRemoveDateFiltersTag}
			onRemoveDirectionFilterTag={onRemoveDirectionFilterTag}
			onRemoveDirectionFiltersTag={onRemoveDirectionFiltersTag}
			onRemoveRecipientFilter={onRemoveRecipientFilter}
			onRemoveRecipientFilterTag={onRemoveRecipientFilterTag}
			onRemoveRecipientFiltersTag={onRemoveRecipientFiltersTag}
			onSortByName={onSortByName}
			onToggleShowFilters={onToggleShowFilters}
		/>
	);
};

export default Transactions;
