import { type ComponentPropsWithRef, type ReactNode } from "react";
import type {
	FieldError as FieldErrorType,
	UseFormRegister,
} from "react-hook-form";

import { FieldError } from "@app/components/field-error";
import styles from "./index.module.css";

export interface TextFieldProps extends ComponentPropsWithRef<"input"> {
	leftSection?: ReactNode;
	rightSection?: ReactNode;
	name: string;
	label: string;
	hideLabel?: boolean;
	validate?: (value: string) => boolean | string;
	placeholder?: string;
	error?: FieldErrorType;
	register: UseFormRegister<any>;
	required?: boolean;
}

const getErrorMessage = (error?: FieldErrorType) => {
	if (!error) return null;
	return error?.type === "required" ? "This field is required" : error.message;
};

export const TextField = ({
	name,
	label,
	leftSection,
	rightSection,
	hideLabel = true,
	register,
	error,
	placeholder,
	required = false,
	validate,
	...rest
}: TextFieldProps) => {
	const errorMessage = getErrorMessage(error);

	return (
		<div className={styles.container}>
			<div className={styles.wrapper} data-error={!!error}>
				{leftSection}
				<input
					className={styles.input}
					type="text"
					data-testid={name}
					placeholder={`${placeholder ?? label}${required ? "*" : ""}`}
					id={name}
					aria-label={hideLabel ? label : undefined}
					{...rest}
					{...register(name, { required, validate })}
				/>
				{rightSection}
			</div>
			{errorMessage && <FieldError>{errorMessage}</FieldError>}
		</div>
	);
};
