import { ComponentPropsWithoutRef } from "react";

import { Transition } from "@headlessui/react";

export const FadeIn = ({
	show = false,
	children,
	...rest
}: {
	show?: boolean;
} & ComponentPropsWithoutRef<"div">) => {
	return (
		<Transition
			{...rest}
			show={show}
			appear
			enter="transition ease-out duration-200"
			leave="transition east-out duration-200"
			enterFrom="opacity-0 translate-y-5"
			enterTo="opacity-100 translate-y-0"
			leaveTo="opacity-0 -translate-y-5"
		>
			{children}
		</Transition>
	);
};
