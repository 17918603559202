import { Button } from "@app/components/button";

import styles from "./footer.module.css";

export const Footer = ({
	step,
	onBack,
	onNext,
	isSubmitting,
	isEdit,
	variant = "default",
}: {
	isEdit: boolean;
	step: number;
	onBack: () => void;
	onNext: () => void;
	isSubmitting?: boolean;
	variant?: "default" | "payment-details";
}) => {
	const showCancel = variant === "default" && (step === 0 || isEdit);
	return (
		<div className={styles.footer}>
			<Button
				variant="secondary"
				onClick={(e) => {
					e.preventDefault();
					onBack();
				}}
			>
				{showCancel ? "Cancel" : "Back"}
			</Button>
			<Button
				form="add-edit-recipient-form"
				type={step === 1 ? "submit" : "button"}
				disabled={isSubmitting}
				onClick={(e) => {
					if (step !== 1) {
						e.preventDefault();
					}
					onNext();
				}}
			>
				{step === 1 ? (isSubmitting ? "Saving" : "Save") : "Next"}
			</Button>
		</div>
	);
};
