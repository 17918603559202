import { type ElementRef, type ReactNode, useEffect, useRef } from "react";

import styles from "./index.module.css";

import alertSrc from "./alert.svg";

export const ApiErrors = ({
	errors,
	autoFocus = false,
	className,
}: {
	errors?: ReactNode[];
	className?: string;
	autoFocus?: boolean;
}) => {
	const ref = useRef<ElementRef<"ul">>(null);
	const hasNoErrors = !errors || errors.length === 0;
	useEffect(() => {
		if (autoFocus && ref.current && !hasNoErrors) {
			const timeout = setTimeout(() => {
				ref.current?.scrollIntoView({
					block: "center",
					inline: "end",
					behavior: "smooth",
				});
			}, 200);
			return () => clearTimeout(timeout);
		}
	}, [autoFocus, hasNoErrors]);

	if (hasNoErrors) return null;

	return (
		<ul ref={ref} className={className}>
			{errors.map((error, index) => {
				if (!error) return null;
				return (
					<li key={index} className={styles.item}>
						<img
							src={alertSrc}
							alt=""
							width={20}
							height={20}
							className={styles.icon}
						/>
						{error}
					</li>
				);
			})}
		</ul>
	);
};
