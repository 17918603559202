import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { paths } from "@app/constants/paths";
import {
	GetPaymentBopDocs,
	type CreateSupportingDocument,
	type DocumentUploadCandidate,
} from "@app/entities";
import {
	useCreateSupportingDocument,
	useDeleteSupportingDocument,
	useDownloadPaymentSupportingDocs,
	useGetPaymentBopDocs,
} from "@app/helpers";
import { useSetCurrentPage } from "@app/hooks/use-set-payment-current-page";
import type { RootState } from "@app/redux";
import { type MappedReasons, abortRequests } from "@app/services";
import { downloadFile } from "@app/utils";

import { CustomLoader } from "@app/components/custom-loader";
import { NeedHelp } from "@app/components/need-help";
import { TransactionDetailsFooter } from "../transaction-details-footer";

import { DocumentUploadModal } from "@app/components/document-upload-modal";
import { useAccountManager } from "@app/hooks/use-account-manager";
import {
	SupportingDocument,
	useSupportingDocuments,
} from "@app/hooks/use-supporting-documents";
import { useTransaction } from "@app/hooks/use-transaction";
import { useTransactionId } from "@app/hooks/use-transaction-id";
import { AxiosProgressEvent } from "axios";
import { TransactionLayout, TransactionStep } from "../transaction-layout";
import { useSubmittedTransactionRedirect } from "../use-submitted-transaction-redirect";
import { DocumentTypeContent } from "./document-type-content";
import styles from "./index.module.css";
import { handleGeneralError } from "@app/utils/handle-general-error";

interface DocumentsState {
	candidates?: DocumentUploadCandidate[];
	documentTypeId?: number;
}

const MAX_FILES_UPLOADED = 25;

const Documents = () => {
	const [isSaving, setIsSaving] = useState(false);
	const [showResumeLater, setShowResumeLater] = useState(false);
	const { data: accountManager } = useAccountManager();
	const transactionId = useTransactionId();
	const {
		exchangeDetails,
		isExchangeDetailsLoading,
		isPaymentStatusLoading,
		activePaymentId,
	} = useTransaction(transactionId);
	const {
		data: supportingDocuments,
		isLoading: isSupportingDocumentsLoading,
		mutate: mutateSupportingDocuments,
	} = useSupportingDocuments(activePaymentId);

	const {
		deleteSupportingDocumentLoading,
		downloadSupportingDocLoading,
		paymentBopDocs: paymentRequiredDocs,
		paymentBopDocsLoading: paymentRequiredDocsLoading,
	} = useSelector((rootState: RootState) => rootState.payments);

	const [createSupportingDocHook] = useCreateSupportingDocument();
	const [deleteSupportingDocHook] = useDeleteSupportingDocument();
	const [downloadSupportingDocHook] = useDownloadPaymentSupportingDocs();
	const [getRequiredDocsHook] = useGetPaymentBopDocs();

	const navigate = useNavigate();

	const [state, setState] = useState<DocumentsState>({
		candidates: [],
		documentTypeId: undefined,
	});

	const [activeDocument, setActiveDocument] =
		useState<GetPaymentBopDocs | null>(null);

	const [showValidation, setShowValidation] = useState(false);

	const checkRequiredDocumentsUploaded = () => {
		const requiredDocumentsUploaded = paymentRequiredDocs?.every(
			(x) =>
				!x.required ||
				supportingDocuments?.some(
					(y) => y?.document_type_id === x?.documentTypeId,
				),
		);

		setShowValidation(true);
		return requiredDocumentsUploaded;
	};

	const handleDeleteDocument = (documentId?: number | string) => {
		if (documentId !== undefined) {
			const safeDocumentId =
				typeof documentId === "string" ? +documentId : documentId;

			if (!Number.isNaN(safeDocumentId)) {
				setIsSaving(true);
				deleteSupportingDocHook(
					safeDocumentId,
					(errors?: string[], mappedReasons?: MappedReasons) => {
						if (
							(!errors || errors.length === 0) &&
							!mappedReasons &&
							exchangeDetails
						) {
							setIsSaving(false);
							mutateSupportingDocuments();
						}
					},
				);
			}
		}
	};

	const onDownloadDocument = (
		document: SupportingDocument["documents"][number],
	) => {
		downloadSupportingDocHook(document.id, (response) => {
			if (
				response &&
				!Array.isArray(response) &&
				response.contentType &&
				response.data
			) {
				downloadFile(
					response.contentType,
					response.data,
					document.document_name || new Date().toLocaleString(),
				);
			}
		});
	};

	const onShowUploadModal = (
		document: GetPaymentBopDocs,
		documents?: SupportingDocument["documents"],
	) => {
		if (document !== undefined) {
			const candidates = documents?.map<DocumentUploadCandidate>((x) => {
				return {
					existing: true,
					file: undefined,
					fileExtension: x.document_extension,
					fileName: x.document_name,
					fileSize: x.document_size ? Number.parseFloat(x.document_size) : 0,
					id: x.id,
					load: 0,
				};
			});

			setState({
				...state,
				candidates,
				documentTypeId: document.documentTypeId,
			});
			setActiveDocument(document);
		}
	};

	const onCancelUpload = () => {
		abortRequests();
	};

	const onCloseUploadModal = () => {
		setActiveDocument(null);
		setState({ ...state, candidates: [] });
		mutateSupportingDocuments();
	};

	const onConfirmUpload = () => {
		setActiveDocument(null);
		setState({ ...state, candidates: [] });
		mutateSupportingDocuments();
	};

	const handleNext = (navigationPath?: string) => {
		if (exchangeDetails) {
			if (checkRequiredDocumentsUploaded()) {
				navigate(
					navigationPath ??
						paths().reviewTransaction(exchangeDetails.transaction_id),
				);
			}
		} else {
			handleGeneralError();
		}
	};

	const onNavigateTransaction = (navigationPath: string) => {
		if (!transactionId) return;
		if (navigationPath.includes(paths().reviewTransaction(transactionId))) {
			handleNext(navigationPath);
		} else {
			navigate(navigationPath);
		}
	};

	const onUpload = (
		index: number,
		file: File | undefined,
		onSetUploadComplete: (index: number, id?: number | string) => void,
		onSetUploadProgress: (progressEvent: AxiosProgressEvent) => void,
		onUploadError: (error: string, index: number) => void,
	) => {
		if (exchangeDetails) {
			if (file && state.documentTypeId) {
				setIsSaving(true);
				createSupportingDocHook(
					{
						paymentId: exchangeDetails.payment_ids[0],
						supportingDocument: {
							documentTypeId: state.documentTypeId,
							file: file,
						},
						onUploadProgress: onSetUploadProgress,
					},
					(
						response?: CreateSupportingDocument,
						errors?: any[],
						mappedReasons?: MappedReasons,
					) => {
						if (!errors || (errors.length === 0 && !mappedReasons)) {
							onSetUploadComplete(index, response?.documentId);
							setIsSaving(false);
							return;
						}
						const errorMessage = errors[0]?.[0]?.msg ?? "Upload failed";
						onUploadError(errorMessage, index);
					},
				);
			}
		} else {
			handleGeneralError();
		}
	};

	useSubmittedTransactionRedirect();
	useSetCurrentPage(exchangeDetails?.payment_ids[0], "supporting_documents");

	useEffect(() => {
		if (!isExchangeDetailsLoading && !exchangeDetails) {
			handleGeneralError({
				message: "No exchange details found.",
			});
			return;
		}
		if (exchangeDetails) {
			const paymentIdToUse = exchangeDetails.payment_ids[0];
			getRequiredDocsHook(paymentIdToUse, () => {
				mutateSupportingDocuments();
			});
		}
	}, [exchangeDetails, isExchangeDetailsLoading]);

	const isLoading =
		deleteSupportingDocumentLoading ||
		downloadSupportingDocLoading ||
		isExchangeDetailsLoading ||
		paymentRequiredDocsLoading ||
		isPaymentStatusLoading ||
		isSupportingDocumentsLoading;

	return (
		<TransactionLayout
			step={TransactionStep.Documents}
			onStepNavigate={onNavigateTransaction}
			onResumeLater={() => setShowResumeLater(true)}
			footer={
				<TransactionDetailsFooter
					hasAutoSave
					isSaving={isSaving}
					showResumeLater={showResumeLater}
					onResumeLaterChange={setShowResumeLater}
					onBack={() => {
						if (exchangeDetails?.transaction_id)
							navigate(
								paths().balanceOfPayment(exchangeDetails.transaction_id),
							);
					}}
					onNext={handleNext}
				/>
			}
		>
			<div className={styles.container}>
				<h2 className={styles.title}>Documents</h2>
				<div className={styles.list}>
					{paymentRequiredDocs?.map((document) => (
						<DocumentTypeContent
							key={document.documentName}
							allDocumentTypeIds={paymentRequiredDocs.map(
								(current) => current.documentTypeId,
							)}
							document={document}
							showValidation={showValidation}
							paymentSupportingDocs={supportingDocuments}
							onShowUploadModal={onShowUploadModal}
							onDownloadDocument={onDownloadDocument}
							onDeleteDocument={handleDeleteDocument}
						/>
					))}
				</div>
				<div className={styles.divider} />
				<NeedHelp
					email={accountManager?.email}
					phone={accountManager?.contact_number}
				>
					Need help with supporting documents?
				</NeedHelp>
			</div>
			{isLoading && <CustomLoader />}
			{!!activeDocument && (
				<DocumentUploadModal
					title={activeDocument.documentName}
					candidates={state.candidates}
					maxFiles={MAX_FILES_UPLOADED}
					multiple
					showSingleButton
					onCancelUpload={onCancelUpload}
					onClose={onCloseUploadModal}
					onConfirmUpload={onConfirmUpload}
					onDeleteItem={(_, item) => handleDeleteDocument(item.id)}
					onUpload={onUpload}
				/>
			)}
		</TransactionLayout>
	);
};

export default Documents;
