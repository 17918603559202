import useSWR from "swr";

export interface BalanceResponse {
	balance: string;
	hold_amount: string;
	last_updated: string;
	warning_message: string;
}

export const useBalance = (backAccountId?: number) => {
	return useSWR<BalanceResponse>(
		backAccountId ? `/bank-accounts/${backAccountId}/balance/` : null,
	);
};
