import parsePhoneNumber from "libphonenumber-js/max";

export const isValidNumber = (phoneNumber: string) => {
	try {
		const parsedNumber = parsePhoneNumber(phoneNumber);
		if (!parsedNumber) return false;

		if (
			parsedNumber.country === "ZA" &&
			parsedNumber.nationalNumber.length < 9
		) {
			return false;
		}
		return parsedNumber.isValid();
	} catch (error) {
		return false;
	}
};
