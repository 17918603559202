import CryptoJS from "crypto-js";
import {
	FLUSH,
	PAUSE,
	PERSIST,
	PURGE,
	type PersistConfig,
	REGISTER,
	REHYDRATE,
	createTransform,
	persistReducer,
	persistStore,
} from "redux-persist";
import { encryptTransform } from "redux-persist-transform-encrypt";
import type { TransformConfig } from "redux-persist/lib/createTransform";
import storage from "redux-persist/lib/storage";
import type { ThunkAction } from "redux-thunk";

import { type Action, configureStore } from "@reduxjs/toolkit";

import { isDevServer } from "../config/env";
import rootReducer, { type RootState } from "./reducer";

const reloadWindow = false;
const storeName = isDevServer ? "persist-state" : "encrypted-state";
const ENCRYPTION_KEY = "F689567B4EDB376C7B9FCCC1A8838";

const getRegexTransformer = (
	blacklist?: RegExp[],
	whitelist?: RegExp[],
	config?: TransformConfig,
) => {
	const filter = (
		state: any,
		key: string | number | symbol,
		blacklist?: RegExp[],
		whitelist?: RegExp[],
	) => {
		if (typeof state !== "object") return state;

		let keys = Object.keys(state);

		if (whitelist) keys = keys.filter((x) => whitelist.some((y) => y.test(x)));
		if (blacklist) keys = keys.filter((x) => !blacklist.some((y) => y.test(x)));

		return keys.reduce(
			(prev, current) => ({ ...prev, [current]: state[current] }),
			{},
		);
	};

	return createTransform(
		(inboundState, key) => filter(inboundState, key, blacklist, whitelist),
		(outboundState, key) => filter(outboundState, key, blacklist, whitelist),
		config,
	);
};

const regexTransformer = getRegexTransformer([
	/loading/i,
	/error/i,
	/toast/i,
	/profilePictureLastLoaded/i,
	/profilePicture/i,
]);

const encryptTransformer = encryptTransform({
	secretKey: CryptoJS.SHA256(ENCRYPTION_KEY).toString(),
});

const persistConfig: PersistConfig<any> = {
	key: storeName,
	storage: storage,
	transforms: isDevServer
		? [regexTransformer]
		: [regexTransformer, encryptTransformer],
};

export const store = configureStore({
	reducer: persistReducer(persistConfig, rootReducer),
	devTools: isDevServer,
	enhancers: [],
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}),
});

export const storePersist = persistStore(store, undefined, () => {
	if (reloadWindow) location.reload();
});
export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
