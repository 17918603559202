import { atom, createStore, Provider, useAtom } from "jotai";
import { Dialog } from "../dialog";
import { Button } from "../button";

import styles from "./index.module.css";
import { useVerifySession } from "@app/wrappers/auth/use-verify-session";
import { links } from "@app/constants/links";

export const genericErrorAtom = atom(false);
export const genericErrorStore = createStore();

export const GenericErrorProvider = ({ children }: { children: any }) => {
	return (
		<Provider store={genericErrorStore}>
			{children}
			<GenericErrorModal />
		</Provider>
	);
};

export const GenericErrorModal = () => {
	const [showModal, setShowModal] = useAtom(genericErrorAtom);
	const { data, isLoading } = useVerifySession(showModal);

	const isSignedIn = !isLoading && !!data?.ok;

	const handleClose = () => {
		if (isSignedIn) {
			window.location.href = "/";
		} else {
			window.location.reload();
		}
		setShowModal(false);
	};

	return (
		<Dialog isOpen={showModal} title="Error" onClose={handleClose}>
			<p className={styles.description}>
				An unexpected error has occurred.
				<br />
				Our team has been notified and will resolve this as soon as possible.
			</p>
			<p className={styles.description}>
				Please contact us if you need assistance.
			</p>

			<ul className={styles.links}>
				<li>
					<a
						className={styles.link}
						href="mailto:info@futureforex.co.za"
						target="_blank"
						rel="noreferrer"
					>
						<svg
							role="presentation"
							xmlns="http://www.w3.org/2000/svg"
							width="17"
							height="16"
							viewBox="0 0 17 16"
							fill="none"
						>
							<path
								d="M1.83301 4.66602L7.27629 8.47631C7.71707 8.78486 7.93746 8.93913 8.17718 8.99889C8.38894 9.05167 8.61041 9.05167 8.82217 8.99889C9.06189 8.93913 9.28228 8.78486 9.72306 8.47631L15.1663 4.66602M5.03301 13.3327H11.9663C13.0864 13.3327 13.6465 13.3327 14.0743 13.1147C14.4506 12.9229 14.7566 12.617 14.9484 12.2407C15.1663 11.8128 15.1663 11.2528 15.1663 10.1327V5.86602C15.1663 4.74591 15.1663 4.18586 14.9484 3.75803C14.7566 3.38171 14.4506 3.07575 14.0743 2.884C13.6465 2.66602 13.0864 2.66602 11.9663 2.66602H5.03301C3.9129 2.66602 3.35285 2.66602 2.92503 2.884C2.5487 3.07575 2.24274 3.38171 2.05099 3.75803C1.83301 4.18586 1.83301 4.74591 1.83301 5.86602V10.1327C1.83301 11.2528 1.83301 11.8128 2.05099 12.2407C2.24274 12.617 2.5487 12.9229 2.92503 13.1147C3.35285 13.3327 3.9129 13.3327 5.03301 13.3327Z"
								stroke="#888"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
						info@futureforex.co.za
					</a>
				</li>
				<li>
					<a
						className={styles.link}
						href="tel:0215180558"
						target="_blank"
						rel="noreferrer"
					>
						<svg
							role="presentation"
							xmlns="http://www.w3.org/2000/svg"
							width="17"
							height="16"
							viewBox="0 0 17 16"
							fill="none"
						>
							<path
								d="M15.1669 11.2807V13.2807C15.1677 13.4664 15.1297 13.6502 15.0553 13.8203C14.9809 13.9904 14.8718 14.1431 14.735 14.2686C14.5982 14.3941 14.4367 14.4897 14.2608 14.5492C14.0849 14.6087 13.8985 14.6308 13.7136 14.614C11.6622 14.3911 9.69161 13.6901 7.96028 12.5674C6.3495 11.5438 4.98384 10.1782 3.96028 8.56738C2.8336 6.82819 2.13244 4.84805 1.91361 2.78738C1.89695 2.60303 1.91886 2.41722 1.97795 2.2418C2.03703 2.06637 2.13199 1.90518 2.25679 1.76846C2.38159 1.63175 2.53348 1.52252 2.70281 1.44773C2.87213 1.37294 3.05517 1.33422 3.24028 1.33405H5.24028C5.56382 1.33086 5.87748 1.44543 6.12279 1.6564C6.3681 1.86737 6.52833 2.16035 6.57361 2.48072C6.65803 3.12076 6.81458 3.7492 7.04028 4.35405C7.12998 4.59266 7.14939 4.85199 7.09622 5.1013C7.04305 5.35061 6.91952 5.57946 6.74028 5.76072L5.89361 6.60738C6.84265 8.27641 8.22458 9.65835 9.89361 10.6074L10.7403 9.76072C10.9215 9.58147 11.1504 9.45795 11.3997 9.40478C11.649 9.35161 11.9083 9.37102 12.1469 9.46072C12.7518 9.68642 13.3802 9.84297 14.0203 9.92738C14.3441 9.97307 14.6399 10.1362 14.8513 10.3857C15.0627 10.6352 15.1751 10.9538 15.1669 11.2807Z"
								stroke="#888"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
						021 518 0558
					</a>
				</li>
			</ul>

			<div className={styles.modalActions}>
				<Button
					className={styles.action}
					variant="secondary"
					href={links.contact}
				>
					Contact us
				</Button>
				<Button className={styles.action} onClick={handleClose}>
					{isSignedIn && (
						<svg
							width="21"
							role="presentation"
							height="20"
							viewBox="0 0 21 20"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M8 17.4996V11.333C8 10.8662 8 10.6329 8.09083 10.4546C8.17072 10.2978 8.29821 10.1703 8.45501 10.0904C8.63327 9.99962 8.86662 9.99962 9.33333 9.99962H11.6667C12.1334 9.99962 12.3667 9.99962 12.545 10.0904C12.7018 10.1703 12.8293 10.2978 12.9092 10.4546C13 10.6329 13 10.8662 13 11.333V17.4996M9.68141 2.30297L4.02949 6.6989C3.65168 6.99275 3.46278 7.13968 3.32669 7.32368C3.20614 7.48667 3.11633 7.67029 3.06169 7.86551C3 8.0859 3 8.32521 3 8.80384V14.833C3 15.7664 3 16.2331 3.18166 16.5896C3.34144 16.9032 3.59641 17.1582 3.91002 17.318C4.26654 17.4996 4.73325 17.4996 5.66667 17.4996H15.3333C16.2668 17.4996 16.7335 17.4996 17.09 17.318C17.4036 17.1582 17.6586 16.9032 17.8183 16.5896C18 16.2331 18 15.7664 18 14.833V8.80384C18 8.32521 18 8.0859 17.9383 7.86551C17.8837 7.67029 17.7939 7.48667 17.6733 7.32368C17.5372 7.13968 17.3483 6.99275 16.9705 6.69891L11.3186 2.30297C11.0258 2.07526 10.8794 1.9614 10.7178 1.91763C10.5752 1.87902 10.4248 1.87902 10.2822 1.91763C10.1206 1.9614 9.97418 2.07526 9.68141 2.30297Z"
								stroke="currentColor"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					)}
					{isSignedIn ? "Home" : "Back"}
				</Button>
			</div>
		</Dialog>
	);
};
