import { Note } from "@app/components/note";
import { forwardRef, useCallback, useEffect, useImperativeHandle } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Card } from "../card";
import { DocumentField } from "../document-field";
import { RadioGroupField } from "../radio-group-field";
import { Row } from "../row";
import {
	getShareholderStructure,
	useShareholderStructure,
} from "./use-shareholder-structure";

import { useMediaQuery } from "@app/hooks/use-media-query";
import { useOnboardingDocument } from "../document-field/use-onboarding-document";
import styles from "./shareholder-legal-entity-form.module.css";

type Inputs = {
	is_complex_structure: string;
	organogram_document?: string;
};

export const ShareholderLegalEntityForm = forwardRef<
	any,
	{
		activeClientId: number;
		onShowShareholderStructureChange: (isComplexStructure: boolean) => void;
	}
>(({ activeClientId, onShowShareholderStructureChange }, ref) => {
	const isMobile = useMediaQuery();
	const { submit } = useShareholderStructure();
	const { data: organogramDocument } = useOnboardingDocument(
		"organogram_document",
	);
	const methods = useForm<Inputs>({
		defaultValues: async () => {
			const { data } = await getShareholderStructure(activeClientId);
			if (data.shareholder_structure) {
				onShowShareholderStructureChange(
					data.shareholder_structure === "complex",
				);
			}
			return {
				is_complex_structure: data.shareholder_structure
					? data.shareholder_structure === "complex"
						? "yes"
						: "no"
					: "",
			};
		},
	});

	const {
		watch,
		getValues,
		handleSubmit,
		setError,
		clearErrors,
		setValue,
		formState: { errors },
	} = methods;

	useEffect(() => {
		if (organogramDocument) {
			setValue("organogram_document", organogramDocument.name);
			clearErrors("organogram_document");
		}
	}, [organogramDocument, setValue, clearErrors]);

	const onSubmit = useCallback(async () => {
		const data = getValues();
		const isRequired = data.is_complex_structure === "yes";

		if (isRequired && !organogramDocument) {
			setError("organogram_document", {
				type: "required",
				message: "This field is required",
			});
			return false;
		}

		const errors = await submit({
			shareholder_structure: isRequired ? "complex" : "simple",
		});
		if (errors) {
			return false;
		}
		return true;
	}, [submit, getValues, setError, organogramDocument]);

	useImperativeHandle(
		ref,
		() => {
			return {
				submit: onSubmit,
			};
		},
		[onSubmit],
	);

	return (
		<FormProvider {...methods}>
			<form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
				<Card>
					<Row
						style={{
							marginBottom:
								watch("is_complex_structure") === "" ? 0 : undefined,
						}}
					>
						<RadioGroupField
							name="is_complex_structure"
							label="Are any of the Shareholders legal entities (e.g. Trust, Pty Ltd)?*"
							onChange={async () => {
								const isComplexStructure =
									watch("is_complex_structure") === "yes";
								await submit({
									shareholder_structure: isComplexStructure
										? "complex"
										: "simple",
								});
								onShowShareholderStructureChange(isComplexStructure);
							}}
						/>
					</Row>
					{watch("is_complex_structure") === "yes" && (
						<>
							<Note className={styles.note} variant="full">
								To simplify the process, we will review your
								Organogram/Ownership structure and revert with which
								shareholders we require information on, due to them being
								primary shareholders/UBOs (persons with +5% effective
								ownership).
							</Note>
							<DocumentField
								style={{ marginBottom: 0 }}
								type="organogram_document"
								value={watch("organogram_document")}
								tooltipWidth={330}
								tooltip="Upload a graphic showing the ownership structure of the company, including all Ultimate Beneficial Owners (“warm bodies”)"
								label={
									isMobile
										? "Ownership structure"
										: "Organogram/Ownership structure*"
								}
								error={errors.organogram_document}
							/>
						</>
					)}
					{watch("is_complex_structure") === "no" && (
						<p className={styles.minShareholder}>
							Please add details for all shareholders with{" "}
							<strong>more than a 5%</strong> share of the company below.
						</p>
					)}
				</Card>
			</form>
		</FormProvider>
	);
});
