import { GenericFailureResponse } from "@app/services";

export const getReasons = (exception: any) => {
	if (exception.detail && typeof exception.detail === "string") {
		return [exception.detail];
	}

	const error = exception.data as GenericFailureResponse;
	if (!error) return ["An unexpected error occurred."];

	if (typeof error === "string") {
		return [error];
	}

	if (error.genericErrors) {
		return [...error.genericErrors];
	}

	if (error.detail) {
		return [exception.detail];
	}

	if (error.message) {
		return [error.message];
	}

	return ["An unexpected error occurred."];
};
