import { isPWA } from "@app/config/env";
import clsx from "clsx";
import { ReactNode, useEffect, useRef, useState } from "react";

import styles from "./index.module.css";

const RESISTANCE = 0.3;
const MAX_BOUNCE_RATIO = 0.25;

interface OverScrollProps {
	children: ReactNode;
	className?: string;
	overscrollClassName?: string;
	disabled?: boolean;
}

export const OverScroll = ({
	children,
	className = "",
	overscrollClassName = "",
	disabled = false,
}: OverScrollProps) => {
	const containerRef = useRef<HTMLDivElement | null>(null);
	const [transform, setTransform] = useState(0);
	const [isOverscrolling, setIsOverscrolling] = useState(false);

	useEffect(() => {
		const container = containerRef.current;
		if (!container) return;
		if (!isPWA || disabled) return;

		let startY = 0;
		let currentY = 0;
		let isDragging = false;

		const handleTouchStart = (e: TouchEvent) => {
			isDragging = true;
			startY = e.touches[0].clientY;
			currentY = startY;
		};

		const handleTouchMove = (e: TouchEvent) => {
			if (!isDragging) return;

			currentY = e.touches[0].clientY;
			const deltaY = currentY - startY;

			const isAtTop = container.scrollTop <= 0;
			const isAtBottom =
				Math.abs(
					container.scrollHeight - container.clientHeight - container.scrollTop,
				) < 1;

			if ((isAtTop && deltaY > 0) || (isAtBottom && deltaY < 0)) {
				const bounce = deltaY / RESISTANCE;
				const maxBounce = window.innerHeight * MAX_BOUNCE_RATIO;
				const newBounce = Math.max(Math.min(bounce, maxBounce), -maxBounce);
				setTransform(newBounce);
				setIsOverscrolling(true);
				e.preventDefault();
			} else {
				setTransform(0);
				setIsOverscrolling(false);
			}
		};

		const handleTouchEnd = () => {
			if (isOverscrolling) {
				setTransform(0);
				setIsOverscrolling(false);
			}
			isDragging = false;
		};

		container.addEventListener("touchstart", handleTouchStart);
		container.addEventListener("touchmove", handleTouchMove, {
			passive: false,
		});
		container.addEventListener("touchend", handleTouchEnd);

		return () => {
			container.removeEventListener("touchstart", handleTouchStart);
			container.removeEventListener("touchmove", handleTouchMove);
			container.removeEventListener("touchend", handleTouchEnd);
		};
	}, [isOverscrolling, disabled]);

	if (!isPWA || disabled) {
		return <div className={className}>{children}</div>;
	}

	return (
		<div
			ref={containerRef}
			className={clsx(styles.overscroll, className, overscrollClassName)}
			style={{
				transform: `translateY(${transform}px)`,
				transitionTimingFunction:
					Math.abs(transform) < 70
						? "ease-in-out"
						: "cubic-bezier(0.175, 0.885, 0.32, 1.275)",
			}}
		>
			{children}
		</div>
	);
};
