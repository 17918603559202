import { useState } from "react";
import { useForm } from "react-hook-form";

import { Button } from "@app/components/button";
import { DatePicker } from "@app/components/controls";
import { FormBuilder } from "@app/components/form-builder";
import { useMediaQuery } from "@app/hooks/use-media-query";
import { useStateWithCallback } from "@app/hooks/use-state-with-callback";

import loaderSrc from "./loader.svg";

import styles from "./index.module.css";

import { ApiErrors } from "@app/components/api-errors";
import { Dialog } from "@app/components/dialog";
import { FieldError } from "@app/components/field-error";
import { Note } from "@app/components/note";
import { dateFormats } from "@app/constants/date-formats";
import { useShowToast } from "@app/helpers";
import { useClients } from "@app/hooks/use-clients";
import { toDayjs } from "@app/lib/date";
import { getLastSixMonths } from "./get-last-six-months";
import { useDownloadStatement } from "./use-download-statement";

interface DownloadStatementModalLogicState {
	selectedDateRange?: number;
	selectedEndDate?: Date;
	selectedStartDate?: Date;
	minDate?: Date;
	maxDate?: Date;
}

interface DownloadStatementForm {
	customRangeOne?: string;
	customRangeTwo?: string;
	dateRange?: string;
	month?: string;
}

const dateRangeOptions = [
	{ label: "Last 30 Days", value: 1 },
	{ label: "Last 90 Days", value: 2 },
	{ label: "Specific Month", value: 3 },
	{ label: "Custom Range", value: 4 },
];

export const DownloadStatementModal = ({
	onClose,
}: {
	onClose: () => void;
}) => {
	const [isLoading, isIsLoading] = useState(false);
	const isMobile = useMediaQuery("only screen and (max-width: 50em)");
	const [showToast] = useShowToast();
	const { downloadStatement } = useDownloadStatement();

	const { activeClient } = useClients();

	const [selectedStartDateError, setSelectedStartDateError] = useState(false);
	const [selectedEndDateError, setSelectedEndDateError] = useState(false);
	const [apiErrors, setApiErrors] = useState<Array<string | null>>([]);

	const {
		control,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm<DownloadStatementForm>({
		defaultValues: {},
		mode: "onChange",
	});

	const defaultLogicState: DownloadStatementModalLogicState = {
		minDate: toDayjs().subtract(6, "months").toDate(),
		maxDate: new Date(),
	};

	const [logicState, setLogicState] =
		useStateWithCallback<DownloadStatementModalLogicState>(defaultLogicState);

	const onHandleSubmitValid = async (data: DownloadStatementForm) => {
		let fromDate: string | undefined;
		let toDate: string | undefined;

		const selectedOption = dateRangeOptions.find(
			(option) => option.label === data.dateRange,
		);

		switch (selectedOption?.value) {
			case 1:
				fromDate = toDayjs().subtract(30, "days").format(dateFormats.iso8601);
				toDate = toDayjs().format(dateFormats.iso8601);
				break;
			case 2:
				fromDate = toDayjs().subtract(90, "days").format(dateFormats.iso8601);
				toDate = toDayjs().format(dateFormats.iso8601);
				break;
			case 3:
				if (data.month) {
					const monthDate = toDayjs(data.month, "MMMM YYYY");
					fromDate = monthDate.startOf("month").format(dateFormats.iso8601);

					if (monthDate.isSame(toDayjs(), "month")) {
						toDate = toDayjs().format(dateFormats.iso8601);
					} else {
						toDate = monthDate.endOf("month").format(dateFormats.iso8601);
					}
				}
				break;
			case 4:
				if (logicState.selectedStartDate && logicState.selectedEndDate) {
					fromDate = toDayjs(logicState.selectedStartDate).format(
						dateFormats.iso8601,
					);
					toDate = toDayjs(logicState.selectedEndDate).format(
						dateFormats.iso8601,
					);
				} else {
					setSelectedStartDateError(logicState.selectedStartDate === undefined);
					setSelectedEndDateError(logicState.selectedEndDate === undefined);
					return;
				}
				break;
		}

		const bankAccountId = activeClient?.bank_account_id;

		if (!bankAccountId) {
			setApiErrors(["No bank account found"]);
			return;
		}

		isIsLoading(true);
		showToast("Download started", "success");
		const errors = await downloadStatement({
			bankAccountId,
			fromDate: fromDate,
			toDate: toDate,
		});
		if (errors) {
			if (Array.isArray(errors)) {
				setApiErrors(errors);
			} else {
				setApiErrors([
					"An error occurred while downloading the statement. Please try again.",
				]);
			}
		}
		isIsLoading(false);
	};

	const onSelectDateRange = (selectedDateRange: string) => {
		const selectedValue = dateRangeOptions.find(
			(option) => option.label === selectedDateRange,
		)?.value;

		setLogicState({ ...logicState, selectedDateRange: selectedValue });
		setApiErrors([]);

		reset({
			dateRange: selectedDateRange,
			customRangeOne: undefined,
			customRangeTwo: undefined,
			month: undefined,
		});
	};

	const onChangeStartDate = (value: Date) => {
		setLogicState({ ...logicState, selectedStartDate: value }, () => {
			setSelectedStartDateError(false);
		});
	};

	const onChangeEndDate = (value: Date) => {
		setLogicState({ ...logicState, selectedEndDate: value }, () => {
			setSelectedEndDateError(false);
		});
	};

	const dropdownOptions = dateRangeOptions.map((option) => option.label);

	const minEndDate = logicState.selectedStartDate
		? toDayjs(logicState.selectedStartDate).add(1, "days").toDate()
		: logicState.minDate;
	const maxStartDate = logicState.selectedEndDate
		? toDayjs(logicState.selectedEndDate).subtract(1, "days").toDate()
		: logicState.maxDate;

	const showNote =
		logicState.selectedDateRange === 3 || logicState.selectedDateRange === 4;

	return (
		<Dialog
			fullscreen={isMobile}
			showTopbar={isMobile}
			title="Download statement"
			size="lg"
			description="Please select a date range below, or specify one you’d prefer."
			onClose={onClose}
			isOpen
			actions={
				<>
					<Button variant="secondary" disabled={isLoading} onClick={onClose}>
						Cancel
					</Button>
					<Button
						disabled={isLoading}
						type="submit"
						form="download-statement-form"
					>
						{isLoading ? "Downloading" : "Download"}
					</Button>
				</>
			}
		>
			<form
				id="download-statement-form"
				onSubmit={handleSubmit(onHandleSubmitValid)}
				noValidate
			>
				<div>
					<div className={styles.content}>
						<div>
							<p className={styles.label}>Date Range</p>
							<FormBuilder
								formControl={control}
								errors={errors}
								formInputs={[
									[
										{
											iconColour: "#888",
											iconSize: 20,
											name: "dateRange",
											onChange: onSelectDateRange,
											options: dropdownOptions,
											panelClassName: "download-statement-dropdown-panel",
											placeholder: "Select a date range",
											required: true,
											showLabel: false,
											title: "",
											type: "dropdown",
										},
									],
								]}
							/>
						</div>
						{logicState.selectedDateRange === 3 && (
							<div>
								<p className={styles.label}>Month</p>
								<FormBuilder
									formControl={control}
									errors={errors}
									formInputs={[
										[
											{
												iconColour: "#888",
												iconSize: 20,
												name: "month",
												options: getLastSixMonths(),
												panelClassName: "download-statement-dropdown-panel",
												placeholder: "Select a month",
												required: true,
												showLabel: false,
												title: "",
												type: "dropdown",
											},
										],
									]}
								/>
							</div>
						)}
						{logicState.selectedDateRange === 4 && (
							<div className={styles.row}>
								<div
									className={styles.picker}
									data-error={selectedStartDateError}
								>
									<p className={styles.label}>Start Date</p>
									<>
										<DatePicker
											placeholder="Select a date"
											maxDate={maxStartDate}
											minDate={logicState.minDate}
											onChange={(value) => onChangeStartDate(value[0])}
											showChevron
										/>
										{selectedStartDateError && (
											<FieldError>This field is required</FieldError>
										)}
									</>
								</div>
								<div className={styles.divider}>-</div>
								<div
									className={styles.picker}
									data-error={selectedEndDateError}
								>
									<p className={styles.label}>End Date</p>
									<>
										<DatePicker
											placeholder="Select a date"
											maxDate={logicState.maxDate}
											minDate={minEndDate}
											onChange={(value) => onChangeEndDate(value[0])}
											showChevron
										/>
										{selectedEndDateError && (
											<FieldError>This field is required</FieldError>
										)}
									</>
								</div>
							</div>
						)}

						{showNote ? (
							<Note>
								Only statements of up to 6 months old may be requested.
							</Note>
						) : null}

						{isLoading && (
							<div className={styles.loadingDescription} role="alert">
								<div className={styles.loadingSpinner}>
									<img className={styles.spinner} src={loaderSrc} alt="" />
									<img className={styles.spinner} src={loaderSrc} alt="" />
								</div>
								Your download will begin shortly. This may take up to 30
								seconds.
							</div>
						)}
						{apiErrors.length > 0 && <ApiErrors errors={apiErrors} />}
					</div>
				</div>
			</form>
		</Dialog>
	);
};
