import { useState, useEffect, useRef, useMemo, useCallback } from "react";

interface TextTruncateProps {
	text: string;
	maxWidth?: number;
	debounceMs?: number;
}

export const TextTruncate = ({
	text,
	maxWidth = 400,
	debounceMs = 150,
}: TextTruncateProps) => {
	const [truncatedText, setTruncatedText] = useState(text);
	const containerRef = useRef<HTMLParagraphElement>(null);
	const timeoutRef = useRef<NodeJS.Timeout>();

	const truncateText = useCallback(() => {
		const container = containerRef.current;
		if (!container) return;

		container.textContent = text;
		const isOverflowing = container.scrollWidth > container.clientWidth;

		if (!isOverflowing) {
			setTruncatedText(text);
			return;
		}

		let left = 0;
		let right = text.length;
		let bestResult = "";

		while (left <= right) {
			const mid = Math.floor((left + right) / 2);
			const truncated = `${text.slice(0, mid)}…${text.slice(text.length - mid)}`;
			container.textContent = truncated;

			if (container.scrollWidth <= container.clientWidth) {
				bestResult = truncated;
				left = mid + 1;
			} else {
				right = mid - 1;
			}
		}

		setTruncatedText(bestResult || "…");
	}, [text]);

	const debouncedTruncate = useCallback(() => {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}
		timeoutRef.current = setTimeout(truncateText, debounceMs);
	}, [truncateText, debounceMs]);

	useEffect(() => {
		truncateText();
		window.addEventListener("resize", debouncedTruncate);

		return () => {
			window.removeEventListener("resize", debouncedTruncate);
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}
		};
	}, [truncateText, debouncedTruncate]);

	const style = useMemo(
		() => ({ maxWidth: `${maxWidth}px`, whiteSpace: "nowrap" }),
		[maxWidth],
	);

	return (
		<p ref={containerRef} style={style} title={text}>
			{truncatedText}
		</p>
	);
};
