import { useEffect } from "react";
import { useParams } from "react-router-dom";

const isOutDatedBrowser = () => {
	// doesn't support local storage or session storage
	const isLocalStorageSupported = !!window.localStorage;
	const isSessionStorageSupported = !!window.sessionStorage;
	const isFetchSupported = !!window.fetch;
	const isPromiseSupported = !!window.Promise;
	const isIE = !!window.navigator.userAgent.match(/MSIE|Trident/);

	return (
		isIE ||
		!isLocalStorageSupported ||
		!isSessionStorageSupported ||
		!isFetchSupported ||
		!isPromiseSupported
	);
};

export const useUnsupportedBrowserRedirect = () => {
	const params = useParams<{ clientId?: string }>();
	const isOutdated = isOutDatedBrowser();
	useEffect(() => {
		if (isOutdated) {
			window.location.href = params.clientId
				? `/${params.clientId}/unsupported-browser`
				: "/unsupported-browser";
		}
	}, [isOutdated, params.clientId]);
};
