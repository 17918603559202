import { useState } from "react";
import { Button } from "../button";
import { Dialog } from "../dialog";

import { AccountManagerCard } from "@app/features/onboarding/need-help-modal/account-manager-card";
import styles from "./index.module.css";

export const MobileHelp = () => {
	const [showHelpModal, setShowHelpModal] = useState(false);
	return (
		<>
			<button
				type="button"
				aria-label="Help"
				onClick={() => setShowHelpModal(true)}
			>
				<svg
					role="presentation"
					width="23"
					height="23"
					viewBox="0 0 23 23"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M8.71255 8.62435C8.93786 7.98386 9.38257 7.44378 9.96793 7.09977C10.5533 6.75575 11.2415 6.63 11.9107 6.74478C12.5799 6.85956 13.1868 7.20748 13.6241 7.7269C14.0613 8.24632 14.3006 8.90372 14.2996 9.58268C14.2996 11.4993 11.4246 12.4577 11.4246 12.4577M11.5013 16.291H11.5109M21.0846 11.4993C21.0846 16.7921 16.794 21.0827 11.5013 21.0827C6.20857 21.0827 1.91797 16.7921 1.91797 11.4993C1.91797 6.20662 6.20857 1.91602 11.5013 1.91602C16.794 1.91602 21.0846 6.20662 21.0846 11.4993Z"
						stroke="#56A7A2"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			</button>
			<Dialog
				bottomsheet
				isOpen={showHelpModal}
				actions={<Button onClick={() => setShowHelpModal(false)}>Back</Button>}
				title="Need help?"
				classNameDescription={styles.description}
				description="If you have a question or need any help, your Account Manager will be happy to assist."
			>
				<AccountManagerCard variant="condensed" />
			</Dialog>
		</>
	);
};
