import { ConfirmedModal } from "../confirmed-modal";

export const ConfirmedDeviceNotTrustedModal = ({
	onClose,
}: {
	onClose: () => void;
}) => {
	return (
		<ConfirmedModal
			onClose={onClose}
			title="Device not trusted"
			iconVariant="error"
			completeText="Continue"
			description="OTP authentication will be required for future logins."
		/>
	);
};
