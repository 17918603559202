import { isLocalOrTest } from "@app/config/env";

const arbitrageUrl = isLocalOrTest
	? "https://test-dashboard.futureforex.co.za/"
	: "https://dashboard.futureforex.co.za/";

const websiteUrl = isLocalOrTest
	? "https://test.futureforex.co.za/"
	: "https://futureforex.co.za/";

export const links = {
	website: websiteUrl,
	contact: `${websiteUrl}contact-us`,
	termsOfService: `${websiteUrl}forex/legal/terms-of-service`,
	privacyPolicy: `${websiteUrl}legal/privacy-policy`,
	arbitrage: {
		base: arbitrageUrl,
		register: `${arbitrageUrl}register`,
	},
	supportedCurrencies: `${websiteUrl}faqs/forex#what-currencies-can-i-send-or-receive-through-future-forex`,
	dealingRoom: {
		emailAddress: "mailto:deal.room@futureforex.co.za",
		phoneNumber: "tel:0215180559",
	},
	forexFaq: `${websiteUrl}faqs/forex`,
	getInTouch: {
		emailAddress: "mailto:info@futureforex.co.za",
		phoneNumber: "tel:+27 (0)21 518 0558",
		physicalAddress:
			"https://www.google.com/maps/place/66+Roeland+St,+Gardens,+Cape+Town,+8001,+South+Africa/" +
			"@-33.93014,18.4202713,17z/data=!3m1!4b1!4m5!3m4!1s0x1dcc677c6dee2f25:0xfc54dc852efce006!8m2!3d-33.93014!4d18.42246",
	},
	legal: {
		privacyPolicy: `${websiteUrl}legal/privacy-policy`,
		termsAndConditions: `${websiteUrl}terms-and-conditions`,
		termsOfUse: `${websiteUrl}website-terms-of-use`,
	},
	sourceOfFundsFaq:
		"https://www.resbank.co.za/en/home/quick-links/frequently-asked-questions#finsurv-item-1869cf2e7d-button",
	quickLinks: {
		about: `${websiteUrl}about-us`,
		careers: `${websiteUrl}careers`,
		faqs: `${websiteUrl}faqs/forex`,
	},
};
