import { Button } from "@app/components/button";
import { SignedInLayout } from "@app/components/signed-in-layout";
import { ReactNode } from "react";

import { useMediaQuery } from "@app/hooks/use-media-query";
import {
	TransactionLayout,
	TransactionStep,
} from "../transaction-details/transaction-layout";
import { Footer } from "./components-responsive/footer";
import styles from "./layout.module.css";

export const Layout = ({
	children,
	onCancel,
	variant = "default",
	isDisabled,
	isEdit,
	step,
	onBack,
	onNext,
}: {
	children: ReactNode;
	onCancel?: () => void;
	variant?: "default" | "payment-details";
	isDisabled: boolean;
	isEdit: boolean;
	step: number;
	onBack?: () => void;
	onNext?: () => void;
}) => {
	const isMobile = useMediaQuery();

	const footer = isMobile ? (
		<Footer
			isEdit={isEdit}
			isSubmitting={isDisabled}
			step={step}
			onBack={() => onBack?.()}
			onNext={() => onNext?.()}
		/>
	) : (
		<div className={styles.footer}>
			<Button
				className={styles.footerButton}
				onClick={onCancel}
				variant="secondary"
			>
				{variant === "payment-details" ? "Back" : "Cancel"}
			</Button>
			<Button
				className={styles.footerButton}
				type="submit"
				disabled={isDisabled}
			>
				Save
			</Button>
		</div>
	);

	if (variant === "payment-details") {
		return (
			<TransactionLayout
				onBack={onBack}
				step={TransactionStep.PaymentDetails}
				footer={footer}
			>
				{children}
			</TransactionLayout>
		);
	}

	return (
		<SignedInLayout
			hasBackButton={isMobile}
			hideBottomNav={isMobile}
			title={isEdit ? "Edit Recipient" : "New Recipient"}
			footer={footer}
		>
			{children}
		</SignedInLayout>
	);
};
