import { FiEye, FiEyeOff } from "react-icons/fi";

export const PasswordToggleButton = ({
	value,
	onChange,
	color = "inherit",
}: {
	value: boolean;
	onChange: (value: boolean) => void;
	color?: string;
}) => {
	return (
		<button
			aria-label="Toggle show password"
			type="button"
			tabIndex={-1}
			onClick={() => onChange(!value)}
		>
			{value ? (
				<FiEyeOff size={20} color={color} />
			) : (
				<FiEye size={20} color={color} />
			)}
		</button>
	);
};
