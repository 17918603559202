import debounce from "lodash/debounce";
import { MultiSelect } from "primereact/multiselect";
import { useEffect, useMemo, useRef } from "react";
import { twMerge } from "tailwind-merge";

import type { ViewProperties } from "./properties";

import "./multi-select.css";

export const MultiSelectView = <T,>(props: ViewProperties<T>) => {
	const parentRef = useRef<HTMLDivElement | null>(null);

	const resizeObserver = useMemo(
		() =>
			new ResizeObserver(() => {
				const element = parentRef.current?.children[0].children[1];
				const childElement = element?.children[0];

				if (!element || !childElement) {
					return;
				}

				const overflowing =
					(childElement?.clientWidth || 0) > (element?.clientWidth || 0);

				debouncedResize(overflowing);
			}),
		[],
	);

	const mainStyle = [
		props.className,
		"multi-select",
		"w-full",
		props.error ? "multi-select-invalid" : "",
	];

	const itemStyle = [
		"custom-item focus:shadow-none text-gray-1100 pl-2 mr-1.5",
	];

	const panelStyle = twMerge(["multi-select-panel", props.panelClassName]);

	const defaultVirtualizedItemHeight = 48;

	const onOverflow = (overflow: boolean) => props.onOverflow?.(overflow);

	const debouncedResize = debounce(onOverflow, 100);

	useEffect(() => {
		const element = parentRef.current?.children[0].children[1];

		if (!element || !element.children[0]) {
			return;
		}

		resizeObserver.disconnect();
		resizeObserver.observe(element);
		resizeObserver.observe(element.children[0]);

		return () => {
			resizeObserver.disconnect();
		};
	}, [props.value]);

	const getFilterable = (options: T[] | string[]): string | undefined => {
		if ((options || []).length > 0) {
			const exampleObject = options[0];

			if (typeof exampleObject === "object")
				return Object.keys(exampleObject as object).join(",");
		}

		return undefined;
	};

	return (
		<div
			ref={(parentDiv) => {
				parentRef.current = parentDiv;
			}}
		>
			<MultiSelect
				appendTo={props.appendToParent ? parentRef.current : undefined}
				className={twMerge(mainStyle)}
				clearIcon={false}
				closeIcon={undefined}
				disabled={props.disabled}
				display={props.display}
				emptyFilterMessage={props.emptyFilterMessage}
				emptyMessage={props.emptyMessage}
				filter={props.filter}
				filterIcon={false}
				filterInputAutoFocus
				filterPlaceholder={props.filterPlaceholder ?? "Search"}
				filterTemplate={props.filterTemplate}
				itemTemplate={props.itemTemplate}
				itemClassName={twMerge(props.itemClassName ?? itemStyle)}
				maxSelectedLabels={props.maxSelectedLabels}
				optionLabel={props.optionLabel}
				options={props.options}
				panelClassName={panelStyle}
				placeholder={props.placeholder}
				selectedItemTemplate={props.selectedItemTemplate}
				selectionLimit={props.selectionLimit}
				showClear={false}
				showSelectAll={props.showSelectAll}
				value={props.value}
				filterBy={getFilterable(props.options)}
				// To be able to flip the chevron when open/closed
				pt={{
					triggerIcon: {
						className: twMerge(
							"trigger-icon",
							props.showingPanel ? "trigger-icon-open" : "trigger-icon-closed",
						),
					},
					label: {
						className: twMerge(
							props.labelClassName,
							props.selectedItemsScrollable && "multi-select-scrollable",
						),
					},
					labelContainer: {
						className: twMerge(
							props.overrideFadeSelectedItems !== false &&
								"multi-select-fade-mask",
							props.selectedItemsScrollable && "multi-select-scrollable",
						),
					},
				}}
				virtualScrollerOptions={{
					itemSize: props.virtualizedItemHeight ?? defaultVirtualizedItemHeight,
				}}
				onBlur={props.onBlur}
				onChange={props.onChangeSelected}
				onFocus={props.onFocus}
				onHide={props.onHidePanel}
				onShow={props.onShowPanel}
			/>
		</div>
	);
};
