import { joinClasses } from "@app/utils";
import { ReactNode } from "react";
import { FiX } from "react-icons/fi";
import CopyIcon from "@app/icons/copy.svg?react";
import { MdCheck, MdInfoOutline, MdWarningAmber } from "react-icons/md";
import styles from "./toast-variants.module.css";

export type ToastType = "error" | "info" | "success" | "warn" | "copy";

export interface ToastTheme {
	icon?: ReactNode;
	className?: string;
	content?: ReactNode;
}

export const types: { [key in ToastType]: ToastTheme } = {
	error: {
		icon: <FiX size={20} color="#f04438" />,
		className: joinClasses(styles.toast, "border-red-500", "bg-red-100"),
		content: "Error",
	},
	info: {
		icon: <MdInfoOutline size={20} color="#969696" />,
		className: joinClasses(styles.toast, "border-gray-500", "bg-gray-100"),
		content: "Info",
	},
	success: {
		icon: <MdCheck size={20} color="#008e17" />,
		className: joinClasses(styles.toast, "border-teal-550", "bg-teal-50"),
		content: "Success",
	},
	warn: {
		icon: <MdWarningAmber size={20} color="c9bb22" />,
		className: joinClasses(styles.toast, "border-yellow-500", "bg-yellow-100"),
		content: "Warning",
	},
	copy: {
		icon: <CopyIcon width={24} height={24} />,
		className: joinClasses(styles.toast, "border-teal-550", "bg-teal-50"),
		content: "Copy",
	},
};
