import { ReactNode } from "react";

import { FadeIn } from "@app/components/fade-in";
import { Subheading } from "@app/components/subheading";
import clsx from "clsx";
import styles from "./index.module.css";

export const Card = ({
	children,
	title,
	actions,
	className,
	disableAnimation = false,
	hide = false,
}: {
	title?: string;
	actions?: ReactNode;
	children?: ReactNode;
	className?: string;
	disableAnimation?: boolean;
	hide?: boolean;
}) => {
	const Component = disableAnimation ? "div" : FadeIn;
	return (
		<Component
			show
			className={clsx(styles.card, className, hide && styles.hide)}
		>
			{title && (
				<div className={styles.header}>
					<Subheading>{title}</Subheading>
					{actions}
				</div>
			)}
			{children}
		</Component>
	);
};
