import styles from "./index.module.css";
import { ToastType } from "./toast-variants";
import { ReactNode } from "react";
import { ToastTransition } from "react-toastify";
import { ToastTheme } from "./toast-variants";

export const ToastView = ({
	theme,
	content,
}: {
	theme: ToastTheme;
	content?: ReactNode;

	autoClose?: number;
	className?: string;

	icon?: ReactNode;
	iconClassName?: string;
	showCloseButton?: boolean;
	transition?: ToastTransition;
	onClose?: () => void;

	type: ToastType;
}) => {
	return (
		<div className={styles.container}>
			{theme.icon}
			{content}
		</div>
	);
};
