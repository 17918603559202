import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

dayjs.tz.setDefault("Africa/Johannesburg");

export const toDayjs = (date?: Date | string, formatter?: string) => {
	return dayjs.utc(date, formatter).tz("Africa/Johannesburg");
};
