import { ConfirmedModal } from "../confirmed-modal";

export const ConfirmedOTPNumberChangedModal = ({
	onClose,
}: {
	onClose: () => void;
}) => {
	return (
		<ConfirmedModal
			onClose={onClose}
			title="OTP number changed"
			description="The mobile number used for OTP authentication has been changed successfully."
		/>
	);
};
