import { Button } from "@app/components/button";
import { api } from "@app/services";
import { useState } from "react";

import styles from "./index.module.css";
import { isLocalhost } from "@app/config/env";

export const DebugOTP = () => {
	const [userId, setUserId] = useState("");
	const [token, setToken] = useState("");
	const [isSubmitting, setIsSubmitting] = useState(false);

	if (!isLocalhost) return null;

	const handleGenerateOTP = async () => {
		try {
			setIsSubmitting(true);
			const { data } = await api.get<{ token: string }>(
				`/otp/debug-otp-token/fozY3Le1LUto/${userId}/?allow_unconfirmed=true`,
			);
			setToken(data.token);
		} catch (error) {
			console.error(error);
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<div className={styles.container}>
			<p className={styles.title}>OTP Debugger</p>
			<input
				className={styles.input}
				aria-label="User ID"
				placeholder="User ID"
				type="text"
				value={userId}
				onChange={(event) => setUserId(event.target.value)}
			/>
			{token && <p>Token: {token}</p>}

			<Button
				size="sm"
				onClick={handleGenerateOTP}
				disabled={isSubmitting || !userId}
			>
				{isSubmitting ? "Generating OTP..." : "Generate OTP"}
			</Button>
		</div>
	);
};
