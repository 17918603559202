import unsortedSrc from "@app/assets/images/default-sort.svg";
import sortDownSrc from "@app/assets/images/sort-down.svg";
import sortUpSrc from "@app/assets/images/sort-up.svg";

import { Fragment, useMemo } from "react";
import styles from "./index.module.css";
import clsx from "clsx";

export interface SortableTableColumn<T> {
	field: string;
	sortField?: string;
	header?: string;
	sortable?: boolean;
	fitContent?: boolean;
	align?: "flex-start" | "center" | "flex-end";
	className?: string;
	width?: number;
	maxWidth?: number;
	minWidth?: number;
}

export const SortableTable = <T,>({
	data,
	columns,
	sortField,
	sortOrder = -1,
	isLoading = false,
	onSort,
}: {
	columns: Array<SortableTableColumn<keyof T>>;
	data: Array<{ [key: string]: any }>;
	sortField?: string;
	sortOrder?: number;
	onSort?: (sortOrder: number, sortField: string) => void;
	isLoading?: boolean;
}) => {
	if (isLoading) {
		return <div className={styles.loading} />;
	}

	const gridTemplateColumns = useMemo(() => {
		return columns.reduce((acc, column) => {
			if (column.fitContent) return `${acc} fit-content(100%)`;
			if (column.width) return `${acc} ${column.width}px`;
			if (column.maxWidth || column.minWidth)
				return `${acc} minmax(${column.minWidth ?? column.width ?? "120"}px, ${column.maxWidth ? `${column.maxWidth}px` : "1fr"})`;
			return `${acc} 1fr`;
		}, "");
	}, [columns]);

	return (
		<div className={styles.wrapper}>
			<div
				className={styles.grid}
				style={{
					gridTemplateColumns,
				}}
			>
				{columns.map((column, index) => {
					const sortKey = column.sortField ?? column.field;
					return (
						<div key={column.field} className={styles.headerCell}>
							<button
								disabled={!column.sortable}
								className={styles.headerButton}
								style={{
									paddingLeft: index === 0 ? "20px" : undefined,
									paddingRight:
										index === columns.length - 1 ? "20px" : undefined,
									justifyContent: column.align,
								}}
								type="button"
								onClick={() => {
									if (!onSort) return;
									if (sortKey === sortField) {
										onSort(sortOrder === -1 ? 1 : -1, sortKey);
									} else {
										onSort(-1, sortKey);
									}
								}}
							>
								{column.header}

								{column.sortable && (
									<img
										src={
											sortKey === sortField && sortOrder === 1
												? sortUpSrc
												: sortKey === sortField && sortOrder === -1
													? sortDownSrc
													: unsortedSrc
										}
										alt=""
									/>
								)}
							</button>
						</div>
					);
				})}
				{data.map((row, index) => {
					const isOdd = index % 2 !== 0;
					return (
						<Fragment key={index}>
							{columns.map((column, index) => {
								return (
									<div
										className={clsx(styles.cell, column.className)}
										key={column.field}
										style={{
											backgroundColor: isOdd ? "#FAFAFA" : "transparent",
											justifyContent: column.align,
											paddingLeft: index === 0 ? "24px" : undefined,
											paddingRight:
												index === columns.length - 1 ? "24px" : undefined,
										}}
									>
										{row[column.field as keyof typeof row]}
									</div>
								);
							})}
						</Fragment>
					);
				})}
			</div>
		</div>
	);
};
