import { useClients } from "@app/hooks/use-clients";
import { atom, useAtom } from "jotai";
import { useEffect, useMemo } from "react";
import useSWR from "swr";

export interface Notification {
	id: number;
	title: string;
	description: string;
	visible: boolean;
	priority: number;
}

const STORAGE_KEY = "notifications";

const getReadNotificationsForClient = (clientId: number): number[] => {
	try {
		const storage = window.localStorage.getItem(STORAGE_KEY);
		const parsed = storage ? JSON.parse(storage) : {};
		return parsed[clientId] || [];
	} catch {
		window.localStorage.removeItem(STORAGE_KEY);
		return [];
	}
};

const setReadNotificationsForClient = (
	clientId: number,
	notifications: number[],
) => {
	const storage = window.localStorage.getItem(STORAGE_KEY);
	const parsed = storage ? JSON.parse(storage) : {};
	parsed[clientId] = Array.from(new Set(notifications));
	window.localStorage.setItem(STORAGE_KEY, JSON.stringify(parsed));
};

const readNotificationsAtom = atom<undefined | Array<number>>(undefined);

export const useReadNotifications = () => useAtom(readNotificationsAtom);

export const useNotifications = () => {
	const { activeClientId } = useClients();
	const { data, error, isLoading } = useSWR<Notification[]>(
		"/utilities/notifications/",
	);

	const [readNotifications, setReadNotifications] = useReadNotifications();

	useEffect(() => {
		if (activeClientId && !readNotifications) {
			setReadNotifications(getReadNotificationsForClient(activeClientId));
		}
	}, [activeClientId, readNotifications, setReadNotifications]);

	const notifications = useMemo(() => {
		if (!data) return [];
		const currentReadNotifications = readNotifications ?? [];
		const sorted = [...data].sort((a: Notification, b: Notification) => {
			if (
				currentReadNotifications.includes(a.id) &&
				!currentReadNotifications.includes(b.id)
			) {
				return 1;
			}

			if (
				!currentReadNotifications.includes(a.id) &&
				currentReadNotifications.includes(b.id)
			) {
				return -1;
			}

			if (a.priority > b.priority) return -1;
			if (a.priority < b.priority) return 1;
			return 0;
		});

		return sorted;
	}, [data, readNotifications]);

	const markAsRead = (notificationId: number) => {
		if (activeClientId) {
			setReadNotificationsForClient(
				activeClientId,
				readNotifications?.concat(notificationId) ?? [],
			);
			setReadNotifications(getReadNotificationsForClient(activeClientId));
		}
	};

	const unreadCount = notifications.filter(
		(notification) => !readNotifications?.includes(notification.id),
	).length;

	return {
		data,
		hasNotifications: !!data?.length,
		notifications,
		readNotifications,
		unreadCount,
		hasUnreadNotifications: unreadCount > 0,
		markAsRead,
		isLoading,
		error,
	};
};
