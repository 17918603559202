import { type ComponentPropsWithoutRef } from "react";

import { joinClasses } from "@app/utils";

import styles from "./index.module.css";

interface IconButtonProps extends ComponentPropsWithoutRef<"button"> {
	size?: "sm" | "md";
	variant?: "default" | "borderless" | "primary";
}

export const IconButton = ({
	variant = "default",
	type = "button",
	children,
	className,
	size = "md",
	...rest
}: IconButtonProps) => {
	return (
		<button
			data-variant={variant}
			data-size={size}
			className={joinClasses(styles.button, className)}
			type={type}
			{...rest}
		>
			{children}
		</button>
	);
};
