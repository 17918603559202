import { MobileStepsNavigation } from "@app/components/mobile-steps-navigation";
import { NavigationStep } from "@app/components/navigation-steps";
import { EntityType } from "@app/types";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { OnboardingSteps } from "../onboarding-steps";
import { TutorialStep } from "../tutorial-step";
import { useTutorial } from "../tutorial-step/use-tutorial";

export const OnboardingMobileHeader = ({
	step,
	steps,
	onLogout,
	onStepNavigate,
	variant,
}: {
	onLogout: () => void;
	step: number;
	steps: Array<NavigationStep>;
	onStepNavigate?: (path: string, targetId?: string | undefined) => void;
	variant: EntityType;
}) => {
	const location = useLocation();
	const { disable, isEnabled } = useTutorial();
	const [stepIndex, setStepIndex] = useState(0);
	const [showMenu, setShowMenu] = useState(false);

	const isPrimaryShareholder = location.pathname.includes(
		"primary-shareholders",
	);

	useEffect(() => {
		if (isEnabled && !isPrimaryShareholder) {
			setShowMenu(true);
		}
	}, [isEnabled, isPrimaryShareholder]);

	useEffect(() => {
		document.body.style.overflow = showMenu ? "hidden" : "auto";
	}, [showMenu]);

	return (
		<>
			<MobileStepsNavigation
				id="onboarding-header"
				step={step}
				showMenu={showMenu}
				onShowMenuChange={setShowMenu}
				steps={steps}
				onLogout={() => onLogout()}
				title={
					isPrimaryShareholder ? "Primary shareholders" : "Onboarding form"
				}
				subtitle={!isPrimaryShareholder ? steps[step]?.title : undefined}
			>
				<OnboardingSteps
					steps={steps}
					variant={variant}
					onStepNavigate={
						onStepNavigate
							? (path) => {
									setShowMenu(false);
									onStepNavigate(path);
								}
							: undefined
					}
				/>
			</MobileStepsNavigation>

			{stepIndex === 0 && !isPrimaryShareholder && isEnabled && showMenu && (
				<TutorialStep
					title="Onboarding steps"
					target="#onboarding-steps"
					onContinue={() => {
						setShowMenu(false);
						setStepIndex(1);
					}}
				>
					{variant === "individual"
						? "Track your progress and navigate between steps here."
						: "Track your company’s progress and navigate between steps here."}
				</TutorialStep>
			)}

			{stepIndex === 1 && !isPrimaryShareholder && isEnabled && !showMenu && (
				<TutorialStep
					title="Navigation panel"
					target="#onboarding-header"
					arrowPosition="end"
					onContinue={() => {
						setStepIndex(0);
						disable();
					}}
				>
					Tap this panel at any stage to access the main navigation.
				</TutorialStep>
			)}
		</>
	);
};
