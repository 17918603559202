import { useForm } from "react-hook-form";

import { Button } from "@app/components/button";
import { FormBuilder } from "@app/components/form-builder";

import { Dialog } from "@app/components/dialog";
import { useMediaQuery } from "@app/hooks/use-media-query";
import type { MappedReasons } from "@app/services";

import styles from "./index.module.css";
import { PasswordToggleButton } from "@app/features/auth/password-toggle-button";
import { useState } from "react";
import { useUserProfile } from "../use-user-profile";
import { isChangeEmailAddressEnabled } from "@app/constants/feature-flags";
import { FormInputProps } from "@app/components/form-builder/types";

export interface ChangeEmailForm {
	currentEmail?: string;
	email?: string;
	password?: string;
}

export const ChangeEmailAddressModal = ({
	isOpen,
	mappedReasons,
	onClose,
	onSendVerification,
}: {
	isOpen: boolean;
	mappedReasons?: MappedReasons;
	onClose: () => void;
	onSendVerification: (data: ChangeEmailForm) => void;
}) => {
	const [showCurrentPassword, setShowCurrentPassword] = useState(false);
	const isMobile = useMediaQuery();
	const { data: userProfile } = useUserProfile();

	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm<ChangeEmailForm>({
		mode: "onChange",
	});

	const formInputs: FormInputProps[][] = isChangeEmailAddressEnabled
		? [
				[
					{
						name: "currentEmail",
						required: false,
						disabled: true,
						showLabel: true,
						theme: "none",
						title: "Current Email",
						type: "email",
						value: userProfile?.email,
					},
				],
				[
					{
						name: "email",
						placeholder: "Enter new email address",
						required: true,
						showLabel: true,
						theme: "none",
						title: "New Email",
						type: "email",
					},
				],
				[
					{
						name: "password",
						placeholder: "Enter your password",
						required: true,
						showLabel: true,
						theme: "none",
						title: "Password",
						type: showCurrentPassword ? "text" : "password",
						icon: (
							<PasswordToggleButton
								value={showCurrentPassword}
								onChange={setShowCurrentPassword}
								color="#b0b0b0"
							/>
						),
					},
				],
			]
		: [
				[
					{
						name: "email",
						placeholder: "Please enter your email address",
						required: true,
						showLabel: true,
						theme: "none",
						title: "Email",
						type: "email",
					},
				],
			];

	return (
		<Dialog
			fullscreen={isMobile}
			showTopbar={isMobile}
			title={isMobile ? "Change email" : "Change email address"}
			isOpen={isOpen}
			onClose={onClose}
			actions={
				<>
					<Button variant="secondary" onClick={onClose}>
						Cancel
					</Button>
					<Button
						className={styles.submitButton}
						type="submit"
						form="change-email-address-modal-form"
					>
						Send verification
					</Button>
				</>
			}
		>
			<form
				id="change-email-address-modal-form"
				onSubmit={handleSubmit(onSendVerification)}
				noValidate
			>
				<FormBuilder
					errors={errors}
					formControl={control}
					formInputs={formInputs}
					mappedReasons={mappedReasons}
				/>
			</form>
		</Dialog>
	);
};
