import { NeedHelp } from "@app/components/need-help";
import { useAccountManager } from "@app/hooks/use-account-manager";
import chromeSrc from "./chrome.png";
import edgeSrc from "./edge.png";
import firefoxSrc from "./firefox.png";
import logoLightSrc from "./logo-light.svg";
import logoSrc from "./logo.svg";
import operaSrc from "./opera.png";
import safariSrc from "./safari.png";

import { useMediaQuery } from "@app/hooks/use-media-query";
import styles from "./index.module.css";

const supportedBrowsers = [
	{
		src: edgeSrc,
		title: "Explorer",
		href: "https://www.microsoft.com/en-us/edge",
	},
	{
		src: chromeSrc,
		title: "Chrome",
		href: "https://www.google.com/chrome",
	},
	{
		src: firefoxSrc,
		title: "Firefox",
		href: "https://www.mozilla.org/en-US/firefox/new/",
	},
	{
		src: safariSrc,
		title: "Safari",
		href: "https://www.apple.com/safari",
	},
	{
		src: operaSrc,
		title: "Opera",
		href: "https://www.opera.com/",
	},
];

export const UnsupportedBrowser = () => {
	const isMobile = useMediaQuery();
	const { data: accountManager } = useAccountManager();
	return (
		<main className={styles.container}>
			<div className={styles.logoContainer}>
				<img
					src={isMobile ? logoLightSrc : logoSrc}
					className={styles.logo}
					alt="Future Forex"
					width={260}
					height={22}
				/>
			</div>
			<div className={styles.content}>
				<h1 className={styles.title}>Outdated Browser Detected</h1>
				<div className={styles.divider} />
				<p className={styles.description}>
					It looks like you’re using an unsupported browser version. To ensure
					the best experience, please update your browser to the latest version
					of one of these supported browsers:
				</p>
				<ul className={styles.list}>
					{supportedBrowsers.map((browser) => (
						<li key={browser.title}>
							<a href={browser.href} className={styles.link}>
								<img
									className={styles.browserLogo}
									src={browser.src}
									alt={browser.title}
									width={64}
									height={64}
								/>
								<p>{browser.title}</p>
							</a>
						</li>
					))}
				</ul>
			</div>
			<div className={styles.help}>
				<NeedHelp
					email={accountManager?.email}
					phone={accountManager?.contact_number}
				>
					Need help? Contact us.
				</NeedHelp>
			</div>
		</main>
	);
};
