const toFixedWithoutRounding = (num: number, fixed: number) => {
	const re = new RegExp(`^-?\\d+(?:\\.\\d{0,${fixed || -1}})?`);
	return num.toString().match(re)?.[0] ?? num.toFixed(fixed);
};

export const formatNumber = (num: number, roundTo = 2) => {
	const suffixes = ["", "k", "mil", "bil"];
	let suffixIndex = 0;
	let value = num;

	while (value >= 1000 && suffixIndex < suffixes.length - 1) {
		value /= 1000;
		suffixIndex++;
	}

	let formattedNum: string;

	if (value >= 100 || (value === Math.floor(value) && suffixIndex === 0)) {
		formattedNum = Math.floor(value).toString();
	} else {
		formattedNum = toFixedWithoutRounding(value, roundTo);
		// Remove trailing zeros and decimal point if not needed
		formattedNum = formattedNum.replace(/\.?0+$/, "");
	}

	// Ensure that we don't have more than 3 significant digits
	if (formattedNum.replace(".", "").length > 3) {
		formattedNum = formattedNum.slice(0, 4).replace(/\.$/, "");
	}

	return `${formattedNum}${suffixes[suffixIndex] ? ` ${suffixes[suffixIndex]}` : ""}`;
};
