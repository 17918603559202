import { ReactNode } from "react";
import AnimateHeight from "react-animate-height";

import styles from "./index.module.css";

export const Card = ({
	children,
	title,
	disabled = false,
}: {
	children: ReactNode;
	title?: string;
	disabled?: boolean;
}) => {
	return (
		<div data-disabled={disabled} className={styles.card}>
			{title && <h3 className={styles.title}>{title}</h3>}
			<AnimateHeight duration={300} height={!disabled ? "auto" : 0}>
				{!disabled && children}
			</AnimateHeight>
		</div>
	);
};
