import isEmpty from "lodash/isEmpty";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import type { MappedReasons } from "@app/services";

import { Dialog } from "@app/components/dialog";
import { Button } from "@app/components/button";
import { PasswordToggleButton } from "@app/features/auth/password-toggle-button";
import { FormBuilder } from "@app/components/form-builder";

import { useMediaQuery } from "@app/hooks/use-media-query";

export interface ChangePasswordForm {
	password?: string;
	newPassword?: string;
	confirmNewPassword?: string;
}

export const ChangePasswordModal = ({
	isOpen,
	mappedReasons: initialMappedReasons,
	onClose,
	onSave,
}: {
	isOpen: boolean;
	mappedReasons?: MappedReasons;
	onClose: () => void;
	onSave: (passwords: ChangePasswordForm) => Promise<void>;
}) => {
	const isMobile = useMediaQuery();

	const {
		control,
		handleSubmit,
		formState: { errors },
		reset,
		getValues,
		clearErrors,
	} = useForm<ChangePasswordForm>({
		defaultValues: {},
		mode: "onChange",
	});

	const [showCurrentPassword, setShowCurrentPassword] = useState(false);
	const [showNewPassword, setShowNewPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const [mappedReasons, setMappedReasons] = useState<MappedReasons | undefined>(
		initialMappedReasons,
	);

	const onClearErrors = (name?: string) => {
		if (name) {
			const newMappedReasons = { ...mappedReasons };

			newMappedReasons[name] = undefined;

			setMappedReasons(newMappedReasons);
		} else setMappedReasons(undefined);
	};

	const passwordsDoNotMatch = () => {
		const values = getValues();

		return (
			(isEmpty(values.newPassword) && !isEmpty(values.confirmNewPassword)) ||
			(!isEmpty(values.newPassword) && isEmpty(values.confirmNewPassword)) ||
			(!isEmpty(values.newPassword) &&
				!isEmpty(values.confirmNewPassword) &&
				values.newPassword === values.confirmNewPassword)
		);
	};

	const hideAllPasswords = () => {
		setShowCurrentPassword(false);
		setShowNewPassword(false);
		setShowConfirmPassword(false);
	};

	useEffect(() => {
		if (!isOpen) {
			reset({
				password: undefined,
				newPassword: undefined,
				confirmNewPassword: undefined,
			});

			hideAllPasswords();
			clearErrors();
			setMappedReasons(undefined);
		}
	}, [isOpen]);

	useEffect(() => {
		if (initialMappedReasons) {
			setMappedReasons(initialMappedReasons);
		}
	}, [initialMappedReasons]);

	return (
		<Dialog
			fullscreen={isMobile}
			showTopbar={isMobile}
			isOpen={isOpen}
			onBack={isMobile ? onClose : undefined}
			title="Change password"
			onClose={onClose}
			actions={
				<>
					<Button variant="secondary" onClick={onClose}>
						Cancel
					</Button>
					<Button type="submit" form="change-password-form">
						Save
					</Button>
				</>
			}
		>
			<form
				id="change-password-form"
				onSubmit={handleSubmit(onSave)}
				noValidate
			>
				<FormBuilder
					errors={errors}
					formControl={control}
					formInputs={[
						[
							{
								name: "password",
								placeholder: "Enter your current password",
								required: true,
								showLabel: true,
								theme: "none",
								title: "Current Password",
								type: showCurrentPassword ? "text" : "password",
								icon: (
									<PasswordToggleButton
										value={showCurrentPassword}
										onChange={setShowCurrentPassword}
										color="#b0b0b0"
									/>
								),
							},
						],
						[
							{
								customErrorMessage: "Password doesn’t match your new password",
								name: "newPassword",
								placeholder: "Enter a new password",
								required: true,
								showLabel: true,
								theme: "none",
								title: "New Password",
								type: showNewPassword ? "text" : "password",
								icon: (
									<PasswordToggleButton
										value={showNewPassword}
										onChange={setShowNewPassword}
										color="#b0b0b0"
									/>
								),
								onCustomValidationRule: passwordsDoNotMatch,
								onChange: () => {
									clearErrors("confirmNewPassword");
									clearErrors("newPassword");
									onClearErrors("newPassword");
								},
							},
						],
						[
							{
								customErrorMessage: "Password doesn’t match your new password",
								iconSize: 20,
								name: "confirmNewPassword",
								placeholder: "Confirm your new password",
								required: true,
								showLabel: true,
								theme: "none",
								title: "Confirm New Password",
								type: showConfirmPassword ? "text" : "password",
								icon: (
									<PasswordToggleButton
										value={showConfirmPassword}
										onChange={setShowConfirmPassword}
										color="#b0b0b0"
									/>
								),
								onCustomValidationRule: passwordsDoNotMatch,
								onChange: () => {
									clearErrors("newPassword");
									onClearErrors("newPassword");
								},
							},
						],
					]}
					mappedReasons={mappedReasons}
				/>
			</form>
		</Dialog>
	);
};
