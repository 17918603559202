import { paths } from "@app/constants/paths";
import { Dialog } from "../dialog";

import { links } from "@app/constants/links";
import { useAuth } from "@app/hooks/use-auth";
import { Link } from "react-router-dom";
import styles from "./index.module.css";

export const AccountMenuDialog = ({
	isOpen,
	onClose,
}: {
	isOpen: boolean;
	onClose: () => void;
}) => {
	const { onLogout } = useAuth();
	return (
		<Dialog bottomsheet isOpen={isOpen} onClose={onClose}>
			<ul className={styles.accountMenuList}>
				<li>
					<Link
						className={styles.accountMenuOption}
						to={paths().userSettings()}
					>
						<svg
							role="presentation"
							xmlns="http://www.w3.org/2000/svg"
							width="20"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
						>
							<path
								d="M7.82936 16.1419L8.3164 17.2373C8.46118 17.5634 8.69747 17.8404 8.99659 18.0349C9.29571 18.2293 9.64483 18.3327 10.0016 18.3327C10.3583 18.3327 10.7075 18.2293 11.0066 18.0349C11.3057 17.8404 11.542 17.5634 11.6868 17.2373L12.1738 16.1419C12.3472 15.7533 12.6388 15.4292 13.0071 15.216C13.3778 15.0022 13.8066 14.9111 14.2321 14.9558L15.4238 15.0827C15.7785 15.1202 16.1365 15.054 16.4544 14.8921C16.7722 14.7302 17.0363 14.4796 17.2145 14.1706C17.393 13.8619 17.4781 13.5079 17.4593 13.1518C17.4406 12.7956 17.3189 12.4525 17.109 12.1642L16.4034 11.1947C16.1522 10.847 16.018 10.4284 16.0201 9.99935C16.02 9.57151 16.1555 9.15464 16.4071 8.80861L17.1127 7.83916C17.3226 7.55081 17.4443 7.20774 17.463 6.85158C17.4818 6.49541 17.3967 6.14147 17.2183 5.83268C17.04 5.52371 16.7759 5.27309 16.4581 5.11121C16.1402 4.94932 15.7822 4.88312 15.4275 4.92065L14.2358 5.0475C13.8103 5.09219 13.3815 5.00112 13.0108 4.78731C12.6418 4.57289 12.3501 4.24715 12.1775 3.85676L11.6868 2.76139C11.542 2.43532 11.3057 2.15828 11.0066 1.96385C10.7075 1.76942 10.3583 1.66596 10.0016 1.66602C9.64483 1.66596 9.29571 1.76942 8.99659 1.96385C8.69747 2.15828 8.46118 2.43532 8.3164 2.76139L7.82936 3.85676C7.6568 4.24715 7.36509 4.57289 6.99603 4.78731C6.62538 5.00112 6.19659 5.09219 5.77103 5.0475L4.57566 4.92065C4.22094 4.88312 3.86294 4.94932 3.54509 5.11121C3.22724 5.27309 2.96317 5.52371 2.78492 5.83268C2.60644 6.14147 2.52141 6.49541 2.54014 6.85158C2.55888 7.20774 2.68058 7.55081 2.89048 7.83916L3.59603 8.80861C3.84765 9.15464 3.98315 9.57151 3.98307 9.99935C3.98315 10.4272 3.84765 10.8441 3.59603 11.1901L2.89048 12.1595C2.68058 12.4479 2.55888 12.791 2.54014 13.1471C2.52141 13.5033 2.60644 13.8572 2.78492 14.166C2.96335 14.4748 3.22744 14.7253 3.54525 14.8872C3.86306 15.049 4.22096 15.1153 4.57566 15.0781L5.76733 14.9512C6.19288 14.9065 6.62167 14.9976 6.99233 15.2114C7.36277 15.4252 7.65583 15.751 7.82936 16.1419Z"
								stroke="#56A7A2"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M10.0001 12.4993C11.3808 12.4993 12.5001 11.3801 12.5001 9.99935C12.5001 8.61864 11.3808 7.49935 10.0001 7.49935C8.61939 7.49935 7.5001 8.61864 7.5001 9.99935C7.5001 11.3801 8.61939 12.4993 10.0001 12.4993Z"
								stroke="#56A7A2"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
						Settings
					</Link>
				</li>
				<li>
					<a
						target="_blank"
						className={styles.accountMenuOption}
						href={links.contact}
						rel="noreferrer"
					>
						<svg
							role="presentation"
							xmlns="http://www.w3.org/2000/svg"
							width="20"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
						>
							<path
								d="M17.9167 15.0007L12.381 10.0007M7.61913 10.0007L2.08344 15.0007M1.66675 5.83398L8.47085 10.5969C9.02182 10.9825 9.29731 11.1754 9.59697 11.2501C9.86166 11.3161 10.1385 11.3161 10.4032 11.2501C10.7029 11.1754 10.9783 10.9825 11.5293 10.5969L18.3334 5.83398M5.66675 16.6673H14.3334C15.7335 16.6673 16.4336 16.6673 16.9684 16.3948C17.4388 16.1552 17.8212 15.7727 18.0609 15.3023C18.3334 14.7675 18.3334 14.0674 18.3334 12.6673V7.33398C18.3334 5.93385 18.3334 5.23379 18.0609 4.69901C17.8212 4.2286 17.4388 3.84615 16.9684 3.60647C16.4336 3.33398 15.7335 3.33398 14.3334 3.33398H5.66675C4.26662 3.33398 3.56655 3.33398 3.03177 3.60647C2.56137 3.84615 2.17892 4.2286 1.93923 4.69901C1.66675 5.23379 1.66675 5.93385 1.66675 7.33398V12.6673C1.66675 14.0674 1.66675 14.7675 1.93923 15.3023C2.17892 15.7727 2.56137 16.1552 3.03177 16.3948C3.56655 16.6673 4.26662 16.6673 5.66675 16.6673Z"
								stroke="#56A7A2"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
						Contact us
					</a>
				</li>
				<li>
					<a
						target="_blank"
						className={styles.accountMenuOption}
						href={links.forexFaq}
						rel="noreferrer"
					>
						<svg
							role="presentation"
							xmlns="http://www.w3.org/2000/svg"
							width="20"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
						>
							<g clipPath="url(#clip0_13741_2173)">
								<path
									d="M7.57508 7.49935C7.771 6.94241 8.15771 6.47277 8.66671 6.17363C9.17571 5.87448 9.77416 5.76513 10.3561 5.86494C10.938 5.96475 11.4658 6.26729 11.846 6.71896C12.2262 7.17063 12.4343 7.74228 12.4334 8.33268C12.4334 9.99935 9.93342 10.8327 9.93342 10.8327M10.0001 14.166H10.0084M18.3334 9.99935C18.3334 14.6017 14.6025 18.3327 10.0001 18.3327C5.39771 18.3327 1.66675 14.6017 1.66675 9.99935C1.66675 5.39698 5.39771 1.66602 10.0001 1.66602C14.6025 1.66602 18.3334 5.39698 18.3334 9.99935Z"
									stroke="#56A7A2"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</g>
							<defs>
								<clipPath id="clip0_13741_2173">
									<rect width="20" height="20" fill="white" />
								</clipPath>
							</defs>
						</svg>
						FAQs
					</a>
				</li>
				<li>
					<a
						target="_blank"
						className={styles.accountMenuOption}
						href={links.website}
						rel="noreferrer"
					>
						<svg
							role="presentation"
							xmlns="http://www.w3.org/2000/svg"
							width="20"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
						>
							<g clipPath="url(#clip0_15344_20499)">
								<path
									d="M10.0003 1.66602C12.0847 3.94798 13.2693 6.90938 13.3337 9.99935C13.2693 13.0893 12.0847 16.0507 10.0003 18.3327M10.0003 1.66602C7.91593 3.94798 6.73137 6.90938 6.66699 9.99935C6.73137 13.0893 7.91593 16.0507 10.0003 18.3327M10.0003 1.66602C5.39795 1.66602 1.66699 5.39698 1.66699 9.99935C1.66699 14.6017 5.39795 18.3327 10.0003 18.3327M10.0003 1.66602C14.6027 1.66602 18.3337 5.39698 18.3337 9.99935C18.3337 14.6017 14.6027 18.3327 10.0003 18.3327M2.08367 7.49935H17.917M2.08366 12.4993H17.917"
									stroke="#56A7A2"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</g>
							<defs>
								<clipPath id="clip0_15344_20499">
									<rect width="20" height="20" fill="white" />
								</clipPath>
							</defs>
						</svg>
						Visit website
					</a>
				</li>
				<li className={styles.divider} />
				<li>
					<button
						className={styles.accountMenuOption}
						type="button"
						onClick={() => onLogout()}
					>
						<svg
							role="presentation"
							xmlns="http://www.w3.org/2000/svg"
							width="20"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
						>
							<path
								d="M13.3333 14.1667L17.5 10M17.5 10L13.3333 5.83333M17.5 10H7.5M7.5 2.5H6.5C5.09987 2.5 4.3998 2.5 3.86502 2.77248C3.39462 3.01217 3.01217 3.39462 2.77248 3.86502C2.5 4.3998 2.5 5.09987 2.5 6.5V13.5C2.5 14.9001 2.5 15.6002 2.77248 16.135C3.01217 16.6054 3.39462 16.9878 3.86502 17.2275C4.3998 17.5 5.09987 17.5 6.5 17.5H7.5"
								stroke="#56A7A2"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
						Log out
					</button>
				</li>
			</ul>
		</Dialog>
	);
};
