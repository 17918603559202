import type { TypographyTheme } from "@app/entities";

export const fontSizesPredefined: {
	[key in TypographyTheme]: { fontSize: number };
} = {
	displayMd: { fontSize: 36 },
	displayXSm: { fontSize: 32 },
	displaySm: { fontSize: 30 },
	displayXs: { fontSize: 24 },
	textXl: { fontSize: 20 },
	textLg: { fontSize: 18 },
	textMd: { fontSize: 16 },
	textSm: { fontSize: 14 },
	textXs: { fontSize: 12 },
};
