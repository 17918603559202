import { Dialog } from "@app/components/dialog";

import { Button } from "@app/components/button";
import { ContactNumberField } from "@app/components/contact-number-field";
import { useMediaQuery } from "@app/hooks/use-media-query";
import { useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import styles from "./index.module.css";
import { FormErrors } from "@app/utils/get-form-errors";
import { ApiErrors } from "@app/components/api-errors";
import { isValidNumber } from "@app/utils/is-valid-number";

type Form = {
	contact_number: string;
};

export const ChangeOTPNumberModal = ({
	onClose,
	onConfirm,
}: {
	onConfirm: (contactNumber: string) => Promise<FormErrors | undefined>;
	onClose: () => void;
}) => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [apiErrors, setApiErrors] = useState<Array<string>>([]);
	const isMobile = useMediaQuery();

	const methods = useForm<Form>({
		shouldFocusError: false,
		defaultValues: {
			contact_number: "",
		},
	});

	const {
		handleSubmit,
		setError,
		clearErrors,
		formState: { errors },
	} = methods;

	const onSubmit: SubmitHandler<Form> = async (data) => {
		if (!data.contact_number || data.contact_number.length < 4) {
			setError("contact_number", {
				message: "This field is required",
			});
			return;
		}
		if (!isValidNumber(data.contact_number)) {
			setError("contact_number", {
				message: "Please provide a valid phone number",
			});
			return;
		}
		setIsSubmitting(true);
		const errors = await onConfirm(data.contact_number);
		setIsSubmitting(false);
		if (errors) {
			const contactNumberError = errors.fieldErrors.find(
				(error) => error.name === "contact_number",
			);
			if (contactNumberError) {
				setError("contact_number", {
					message: contactNumberError.message,
				});
			}
			setApiErrors(errors.apiErrors);
			return;
		}
	};

	return (
		<Dialog
			isOpen
			size="md"
			fullscreen={isMobile}
			showTopbar={isMobile}
			onClose={onClose}
			title="Change OTP number"
			description="Please enter the new mobile number you would like to use for OTP authentication."
			actions={
				<>
					<Button
						minWidth={110}
						disabled={isSubmitting}
						variant="secondary"
						onClick={onClose}
					>
						Cancel
					</Button>
					<Button
						minWidth={110}
						disabled={isSubmitting}
						form="device-form"
						type="submit"
					>
						Continue
					</Button>
				</>
			}
		>
			<FormProvider {...methods}>
				<form id="device-form" onSubmit={handleSubmit(onSubmit)}>
					<ContactNumberField
						label="New Mobile Number"
						placeholder="Enter new contact number"
						onBlur={() => {
							clearErrors("contact_number");
						}}
						error={errors.contact_number}
					/>
				</form>
				<ApiErrors className={styles.errors} errors={apiErrors} />
			</FormProvider>
		</Dialog>
	);
};
