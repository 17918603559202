import removeIconSrc from "@app/assets/images/trash.svg";
import { Dialog } from "@app/components/dialog";
import { FiCalendar, FiEdit2, FiSend } from "react-icons/fi";
import { RecipientTableModel } from "../models/models";

import styles from "./index.module.css";

export const RecipientsMenuModal = ({
	isOpen,
	onClose,
	selected,
	onSelect,
}: {
	isOpen: boolean;
	onClose: () => void;
	selected: RecipientTableModel;
	onSelect: (
		id: number,
		destination: "sendFunds" | "edit" | "history" | "remove",
	) => void;
}) => {
	return (
		<Dialog bottomsheet title={selected.name} isOpen={isOpen} onClose={onClose}>
			<ul className={styles.list}>
				<li>
					<button
						className={styles.button}
						type="button"
						onClick={() => onSelect(selected.id, "sendFunds")}
					>
						<FiSend size={20} color="#56A7A2" />
						Send Funds
					</button>
				</li>
				<li>
					<button
						className={styles.button}
						type="button"
						onClick={() => onSelect(selected.id, "edit")}
					>
						<FiEdit2 size={20} color="#56A7A2" />
						Edit
					</button>
				</li>
				<li>
					<button
						className={styles.button}
						type="button"
						onClick={() => onSelect(selected.id, "history")}
					>
						<FiCalendar size={20} color="#56A7A2" />
						History
					</button>
				</li>
				<li className={styles.divider} />
				<li>
					<button
						className={styles.button}
						type="button"
						onClick={() => onSelect(selected.id, "remove")}
					>
						<img src={removeIconSrc} alt="" width={20} height={20} />
						Remove
					</button>
				</li>
			</ul>
		</Dialog>
	);
};
