import { type ComponentPropsWithoutRef } from "react";

import { joinClasses } from "@app/utils";

import { Link } from "react-router-dom";
import styles from "./index.module.css";

interface ButtonProps extends ComponentPropsWithoutRef<"button"> {
	variant?: "primary" | "secondary" | "tertiary" | "icon";
	size?: "sm" | "md" | "lg";
	block?: boolean;
	centered?: boolean;
	minWidth?: number;
	noPadding?: boolean;
	inline?: boolean;
	href?: string;
	to?: string;
}

export const Button = ({
	variant = "primary",
	size = "md",
	block = false,
	centered = false,
	type = "button",
	inline = false,
	minWidth,
	children,
	noPadding = false,
	className,
	style,
	to,
	href,
	...rest
}: ButtonProps) => {
	const styleOverride = minWidth
		? { ...style, minWidth, padding: minWidth ? "0 1rem" : undefined }
		: style;

	if (href) {
		return (
			<a
				href={href}
				target="_blank"
				rel="noreferrer"
				style={styleOverride}
				className={joinClasses(styles.button, className)}
				data-variant={variant}
				data-block={block}
				data-no-padding={noPadding}
				data-centered={centered}
				data-inline={inline}
				data-size={size}
			>
				{variant === "tertiary" ? (
					<span className={styles.inner}>{children}</span>
				) : (
					children
				)}
			</a>
		);
	}

	if (to) {
		return (
			<Link
				to={to}
				className={joinClasses(styles.button, className)}
				data-variant={variant}
				data-block={block}
				data-no-padding={noPadding}
				data-centered={centered}
				data-inline={inline}
				data-size={size}
				style={styleOverride}
			>
				{variant === "tertiary" ? (
					<span className={styles.inner}>{children}</span>
				) : (
					children
				)}
			</Link>
		);
	}

	return (
		<button
			className={joinClasses(styles.button, className)}
			data-variant={variant}
			data-block={block}
			data-no-padding={noPadding}
			data-centered={centered}
			data-inline={inline}
			data-size={size}
			type={type}
			style={styleOverride}
			{...rest}
		>
			{variant === "tertiary" ? (
				<span className={styles.inner}>{children}</span>
			) : (
				children
			)}
		</button>
	);
};
