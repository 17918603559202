import checkSrc from "./check.svg";
import { Button } from "@app/components/button";

import { Dialog } from "@app/components/dialog";
import "./withdrawal-submitted-modal.css";

import styles from "./index.module.css";

export const WithdrawalSubmittedModal = ({
	isOpen,
	withdrawalType,
	onClose,
}: {
	isOpen: boolean;
	withdrawalType?: string;
	onClose: () => void;
}) => {
	return (
		<Dialog
			size="md"
			className="withdrawal-submitted-modal"
			isOpen={isOpen}
			onClose={onClose}
			actions={
				<Button centered onClick={onClose}>
					Done
				</Button>
			}
		>
			<div className={styles.container}>
				<img
					alt=""
					className={styles.icon}
					src={checkSrc}
					width={60}
					height={60}
				/>
				<h2 className={styles.title}>Withdrawal submitted</h2>
				<p>
					This will be processed in the next{" "}
					{withdrawalType?.toLowerCase() === "standard" ? "3" : "1-2"} business
					days.
				</p>
			</div>
		</Dialog>
	);
};
