import { Button } from "@app/components/button";
import { IconButton } from "@app/components/icon-button";

import { Dialog } from "@app/components/dialog";
import { useMediaQuery } from "@app/hooks/use-media-query";
import { useEffect } from "react";
import { FiPlus, FiTrash2 } from "react-icons/fi";
import { useWithdrawal } from "../withdraw-modal/use-withdrawal";
import styles from "./index.module.css";

export const ManageAccountsModal = (props: {
	isOpen: boolean;
	onAddBankAccount: () => void;
	onBack: () => void;
	onClose: () => void;
	onDelete: (bankAccountId: number) => void;
	onEdit: (bankAccountId: number) => void;
}) => {
	const isMobile = useMediaQuery();
	const { backAccounts, isLoadingBackAccounts, mutateBackAccounts } =
		useWithdrawal();
	const hasAccounts = backAccounts && backAccounts?.length > 0;

	useEffect(() => {
		if (props.isOpen) {
			mutateBackAccounts();
		}
	}, [props.isOpen, mutateBackAccounts]);

	return (
		<Dialog
			title="Manage accounts"
			isOpen={props.isOpen}
			onClose={props.onClose}
			onBack={isMobile ? undefined : props.onBack}
			size="xl"
			fullscreen={isMobile}
			className="manage-accounts-modal"
			actions={
				<>
					<Button
						disabled={isLoadingBackAccounts}
						variant="secondary"
						onClick={props.onBack}
					>
						Back
					</Button>
					<Button
						className={styles.addAccountButton}
						disabled={isLoadingBackAccounts}
						onClick={props.onAddBankAccount}
					>
						<FiPlus size={20} />
						Add bank account
					</Button>
				</>
			}
		>
			{hasAccounts ? (
				<ul className={styles.list}>
					{backAccounts?.map((account, index) => {
						return (
							<li className={styles.item} key={index}>
								<div className={styles.detail}>
									<p className={styles.bankName}>{account.bank}</p>
									<p>{account.account_number}</p>
								</div>
								<div className={styles.actions}>
									<Button
										disabled={isLoadingBackAccounts}
										size="sm"
										variant="secondary"
										inline
										onClick={() => props.onEdit(account.id as number)}
										type="button"
									>
										Edit
									</Button>
									<IconButton
										size="sm"
										disabled={isLoadingBackAccounts}
										onClick={() => props.onDelete(account.id as number)}
									>
										<FiTrash2 size={24} />
									</IconButton>
								</div>
							</li>
						);
					})}
				</ul>
			) : (
				<div className={styles.noAccounts}>No accounts added yet</div>
			)}
		</Dialog>
	);
};
