import { Button } from "@app/components/button";
import { useEffect, useState } from "react";
import { ResendModal } from "../resend-modal";

import clsx from "clsx";
import styles from "./index.module.css";

const DEFAULT_TIMEOUT = 60;

export const ResendEmail = ({
	email,
	onResend,
	variant,
}: {
	email?: string | null;
	onResend: () => Promise<boolean>;
	variant: "verify-email" | "password-reset" | "change-email";
}) => {
	const [isLoading, setIsLoading] = useState(false);
	const [isResendSuccess, setIsResendSuccess] = useState(false);
	const [showResendModal, setShowResendModal] = useState(false);
	const [countdown, setCountdown] = useState(DEFAULT_TIMEOUT);
	const [isDisabled, setIsDisabled] = useState(false);

	const handleResend = async () => {
		setIsLoading(true);
		const isSuccess = await onResend();
		setIsLoading(false);
		if (isSuccess) {
			setIsResendSuccess(true);
			setShowResendModal(true);
		}
	};

	const handleResendModalClose = () => {
		setShowResendModal(false);
		setIsResendSuccess(false);
		setIsDisabled(true);
	};

	useEffect(() => {
		if (isDisabled && countdown > 0) {
			const timeoutId = setTimeout(() => {
				setCountdown((prevCount) => prevCount - 1);
			}, 1000);
			return () => clearTimeout(timeoutId);
		}

		if (countdown === 0) {
			setIsDisabled(false);
			setCountdown(60);
		}
	}, [isDisabled, countdown]);

	return (
		<>
			<Button
				disabled={isDisabled || isLoading || isResendSuccess}
				centered={variant !== "change-email"}
				inline
				onClick={handleResend}
				className={clsx({
					[styles.changeEmailButton]: variant === "change-email",
				})}
				variant={variant === "change-email" ? "tertiary" : "secondary"}
			>
				{isDisabled
					? `Resend in ${countdown} seconds`
					: isResendSuccess
						? "Email sent"
						: isLoading
							? "Resending"
							: "Resend email"}
			</Button>
			{showResendModal && email && (
				<ResendModal
					isResetPassword={variant === "password-reset"}
					onClose={handleResendModalClose}
					userEmail={email}
				/>
			)}
		</>
	);
};
