import { joinClasses } from "@app/utils";

export const SendReceiveIcon = ({
	type,
	size,
}: {
	type: "send" | "receive";
	size?: number;
}) => {
	return (
		<div
			data-testid="send-receive-icon"
			className={joinClasses(
				"flex h-10 w-10 items-center justify-center rounded-xl border mobile:h-8 mobile:w-8 mobile:rounded-lg",
				type === "send"
					? "border-blue-200 bg-blue-50"
					: "border-purple-200 bg-purple-50",
			)}
			data-type={type}
			style={size ? { height: size, width: size } : {}}
		>
			<svg
				role="presentation"
				xmlns="http://www.w3.org/2000/svg"
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
			>
				{type === "send" ? (
					<path
						d="M12 5L12 19M12 19L19 12M12 19L5 12"
						stroke="#383838"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				) : (
					<path
						d="M12.5 19L12.5 5M12.5 5L5.5 12M12.5 5L19.5 12"
						stroke="#383838"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				)}
			</svg>
		</div>
	);
};
