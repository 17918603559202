import { MoreInfoTooltip } from "@app/components/more-info-tooltip";
import { SuggestedPaymentBop } from "@app/entities";
import { useMediaQuery } from "@app/hooks/use-media-query";
import { ElementRef, useEffect, useRef } from "react";
import { FiClock, FiLayers, FiStar } from "react-icons/fi";
import styles from "./suggested-bop-card.module.css";

export const SuggestedBopCard = ({
	value,
	isPopular = false,
	isRecent = false,
	isSelected = false,
	shouldScroll,
	onScrollComplete,
	onChange,
}: {
	shouldScroll: boolean;
	onScrollComplete: () => void;
	value: SuggestedPaymentBop;
	isPopular?: boolean;
	isRecent?: boolean;
	isSelected?: boolean;
	onChange: (value: SuggestedPaymentBop) => void;
}) => {
	const buttonRef = useRef<ElementRef<"button">>(null);
	const showTag = isPopular || isRecent;
	const isMobile = useMediaQuery();

	useEffect(() => {
		if (isSelected && shouldScroll) {
			buttonRef.current?.scrollIntoView({
				behavior: "smooth",
				block: "nearest",
				inline: "start",
			});
			onScrollComplete();
		}
	}, [isSelected, shouldScroll, onScrollComplete]);

	return (
		<button
			ref={buttonRef}
			type="button"
			onClick={() => onChange(value)}
			data-selected={isSelected}
			className={styles.container}
		>
			<FiLayers color="#888" size={24} />
			<p className={styles.code}>{value.bopCode}</p>
			<div className={styles.descriptionContainer}>
				<p className={styles.description}>{value.category}</p>
				<MoreInfoTooltip
					hasIcon={false}
					name={
						<>
							<p>{value.bopCode}</p>
							<p>{value.category}</p>
						</>
					}
					maxWidth={300}
					size={20}
				>
					{isMobile ? (
						value.description
					) : (
						<div>
							<p>{value.bopCode}</p>
							<p>Category: {value.category}</p>
							<p>Description: {value.description}</p>
						</div>
					)}
				</MoreInfoTooltip>
			</div>
			{showTag && (
				<div
					className={styles.tag}
					data-type={isPopular ? "popular" : "recent"}
				>
					{isPopular ? (
						<FiStar color="#262626" size={14} />
					) : (
						<FiClock color="#262626" size={14} />
					)}

					{isPopular ? "Popular" : "Recent"}
				</div>
			)}
		</button>
	);
};
