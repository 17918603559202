import { useEffect, useState } from "react";

import {
	MappedCurrency,
	tempCurrenciesMapping,
	useCurrencies,
} from "@app/hooks/use-currencies";
import { useMediaQuery } from "@app/hooks/use-media-query";
import { MultiSelectCurrencyView } from "./multi-select-currency-view";
import { MultiSelectCurrencyViewResponsive } from "./multi-select-currency-view-responsive";
import type { Properties, ViewProperties } from "./properties";

export const MultiSelectCurrency = (props: Properties) => {
	const isMobile = useMediaQuery();
	const [state, setState] = useState<MappedCurrency[]>([]);

	const { data: currencies } = useCurrencies();

	const onChange = (values: MappedCurrency[]) => {
		setState(values);
		if (props.onChange) props.onChange(values);
	};

	const onRemoveSelectedItem = (value: MappedCurrency) => {
		const newState = [...state];

		const foundIndex = newState.findIndex(
			(x) => x.currencyCode === value.currencyCode,
		);
		if (foundIndex > -1) {
			props.onRemoveSelectedItem?.(newState[foundIndex]);
			newState.splice(foundIndex, 1);
			setState(newState);
			props.onChange?.(newState);
		}
	};

	const sortedCurrencies = currencies?.currencies.concat(
		currencies.dealing_room_only,
	);

	useEffect(() => {
		if (props.value !== state) {
			setState(props.value ?? []);
		}
	}, [props.value]);

	const viewProps: ViewProperties = {
		...props,
		value: state,
		defaultOptions: sortedCurrencies
			? sortedCurrencies
					.map((currencyCode) =>
						currencies?.currency_mapping.find(
							(currency) => currency.currency_code === currencyCode,
						),
					)
					.filter((currency) => currency !== undefined)
					.map(tempCurrenciesMapping)
			: [],
	};

	if (isMobile) {
		return (
			<MultiSelectCurrencyViewResponsive
				{...viewProps}
				onChange={onChange}
				onRemoveSelectedItem={onRemoveSelectedItem}
				hideOutlineContent={props.hideOutlineContent}
			/>
		);
	}

	return (
		<MultiSelectCurrencyView
			{...viewProps}
			onChange={onChange}
			onRemoveSelectedItem={onRemoveSelectedItem}
		/>
	);
};
