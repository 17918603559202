import { ReactNode, useState } from "react";
import { useNavigate } from "react-router-dom";

import { paths } from "@app/constants/paths";
import { useSetCurrentPage } from "@app/hooks/use-set-payment-current-page";

import { tempPaymentStatusMapping } from "@app/hooks/use-payment-status";
import { useTransaction } from "@app/hooks/use-transaction";
import { useTransactionId } from "@app/hooks/use-transaction-id";
import { getFormErrors } from "@app/utils/get-form-errors";
import { useSubmittedTransactionRedirect } from "../use-submitted-transaction-redirect";
import { ReviewTransactionView } from "./review-transaction-view";
import { handleGeneralError } from "@app/utils/handle-general-error";

const ReviewTransaction = () => {
	const [apiErrors, setApiErrors] = useState<ReactNode[]>([]);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const navigate = useNavigate();
	const transactionId = useTransactionId();
	const { exchangeDetails, paymentStatus, onSubmitPayment, activePaymentId } =
		useTransaction(transactionId);

	const [showCompleteModal, setShowCompleteModal] = useState(false);

	const onNavigateTransaction = (navigationPath: string) =>
		navigate(navigationPath);

	const onBack = () => {
		if (!transactionId) return;
		navigate(paths().documents(transactionId));
	};

	const handleComplete = () => {
		setShowCompleteModal(false);
		navigate(paths().transactions());
	};

	const handleNext = async () => {
		if (activePaymentId) {
			try {
				setIsSubmitting(true);
				await onSubmitPayment(activePaymentId);
				setShowCompleteModal(true);
			} catch (error) {
				const result = getFormErrors(error);
				if (result.fieldErrors.length > 0) {
					setApiErrors(result.fieldErrors.map((current) => current.message));
				} else {
					handleGeneralError(result);
				}
			} finally {
				setIsSubmitting(false);
			}
		} else {
			handleGeneralError({
				activePaymentId,
			});
		}
	};

	const onEditBop = () => {
		if (!transactionId) return;
		navigate(paths().balanceOfPayment(transactionId));
	};

	const onEditDocument = () => {
		if (!transactionId) return;
		navigate(paths().documents(transactionId));
	};

	const onEditPayment = () => {
		if (!transactionId) return;
		navigate(paths().paymentDetails(transactionId));
	};

	useSubmittedTransactionRedirect();
	useSetCurrentPage(exchangeDetails?.payment_ids[0], "review");

	return (
		<ReviewTransactionView
			paymentStatus={
				paymentStatus ? tempPaymentStatusMapping(paymentStatus) : undefined
			}
			onNavigateTransaction={onNavigateTransaction}
			onBack={onBack}
			apiErrors={apiErrors}
			onNext={handleNext}
			isSubmitting={isSubmitting || !paymentStatus?.awaiting_submission_details}
			onComplete={handleComplete}
			onEditBop={onEditBop}
			onEditDocument={onEditDocument}
			onEditPayment={onEditPayment}
			showCompleteModal={showCompleteModal}
			setShowCompleteModal={setShowCompleteModal}
		/>
	);
};

export default ReviewTransaction;
