import styles from "./index.module.css";

const ProgressIcon = ({ stage }: { stage?: number }) => {
	if (stage === 1)
		return (
			<svg
				height={40}
				width={40}
				role="presentation"
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 20 20"
			>
				<path
					fill="#56A7A2"
					d="M3.333 10.835a.833.833 0 1 0 0-1.667.833.833 0 0 0 0 1.667M3.333 5.835a.833.833 0 1 0 0-1.667.833.833 0 0 0 0 1.667M3.333 15.835a.833.833 0 1 0 0-1.667.833.833 0 0 0 0 1.667"
				/>
				<path
					stroke="#56A7A2"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={1.5}
					d="M17.5 10.001h-10m10-5h-10m10 10h-10m-3.333-5a.833.833 0 1 1-1.667 0 .833.833 0 0 1 1.667 0m0-5a.833.833 0 1 1-1.667 0 .833.833 0 0 1 1.667 0m0 10a.833.833 0 1 1-1.667 0 .833.833 0 0 1 1.667 0"
				/>
			</svg>
		);

	if (stage === 2)
		return (
			<svg
				height={40}
				width={40}
				role="presentation"
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 20 20"
			>
				<path
					stroke="#56A7A2"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={2}
					d="M10 12.917H6.25c-1.164 0-1.745 0-2.218.143a3.33 3.33 0 0 0-2.222 2.222c-.144.473-.144 1.055-.144 2.218M13.333 15l1.666 1.667 3.334-3.334m-6.25-7.083a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0"
				/>
			</svg>
		);

	if (stage === 3)
		return (
			<svg
				height={40}
				width={40}
				role="presentation"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 20 20"
				fill="none"
			>
				<path
					stroke="#56A7A2"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={2}
					d="M4.167 7.5v6.666m3.75-6.667v6.667m4.166-6.667v6.667m3.75-6.667v6.667M2.5 15.499v.667c0 .467 0 .7.09.878.08.157.208.284.365.364.178.091.412.091.878.091h12.334c.466 0 .7 0 .878-.09a.83.83 0 0 0 .364-.365c.091-.178.091-.411.091-.878v-.667c0-.466 0-.7-.09-.878a.83.83 0 0 0-.365-.364c-.178-.091-.412-.091-.878-.091H3.833c-.466 0-.7 0-.878.09a.83.83 0 0 0-.364.365c-.091.178-.091.412-.091.878M9.71 2.563l-6.166 1.37c-.372.084-.559.125-.698.225a.83.83 0 0 0-.278.348c-.068.157-.068.348-.068.73v.93c0 .467 0 .7.09.878.08.157.208.284.365.364.178.091.412.091.878.091h12.334c.466 0 .7 0 .878-.09a.83.83 0 0 0 .364-.365c.091-.178.091-.411.091-.878v-.93c0-.382 0-.573-.068-.73a.83.83 0 0 0-.278-.348c-.14-.1-.326-.141-.698-.224l-6.167-1.37a2 2 0 0 0-.216-.041 1 1 0 0 0-.146 0 2 2 0 0 0-.216.04"
				/>
			</svg>
		);

	return null;
};

export const TransactionProgress = ({
	data,
}: {
	data?: {
		description: string;
		stage: number;
	};
}) => {
	if (!data) return null;
	return (
		<div>
			<div className={styles.progressRow}>
				<div className={styles.progressText}>
					<ProgressIcon stage={data?.stage} />
					<div className={styles.inner}>
						<p className={styles.label}>Current Status:</p>
						<p className={styles.value}>{data?.description}</p>
					</div>
				</div>
				<div className={styles.progressScale}>
					{[...Array(4)].map((e, i) => {
						if (i < (data?.stage || 4)) {
							return <div key={i} className={styles.doneIndicator} />;
						}
						return <div key={i} className={styles.waitingIndicator} />;
					})}
				</div>
			</div>
		</div>
	);
};
