import { api } from "@app/services";
import { getFormErrors } from "@app/utils/get-form-errors";
import { useCallback } from "react";
import useSWR from "swr";

export type CreateOTPDevice = {
	contact_number: string;
	message_channel: OTPMethod;
};

export type OTPMethod = "sms" | "whatsapp";

type OTPDetails = {
	status: string;
	contact_number: string;
	message_channel: OTPMethod;
};

export const getOTPDetails = () => api.get<OTPDetails>("/otp/otp-details/");

export const useOTP = () => {
	const { data: otpDetails, mutate } = useSWR<OTPDetails>("/otp/otp-details/");

	const handleConfirmOTPDevice = useCallback(async (token: string) => {
		try {
			await api.post("/otp/otp-device/confirm/", {
				token,
			});
		} catch (error) {
			return getFormErrors(error);
		}
	}, []);

	const handleVerifyOTPDevice = useCallback(async (token: string) => {
		try {
			await api.post("/otp/verify/", {
				token,
			});
		} catch (error) {
			return getFormErrors(error);
		}
	}, []);

	const handleRequestOTP = useCallback(async (method?: OTPMethod) => {
		try {
			await api.post(
				"/otp/request/",
				method
					? {
							method,
						}
					: undefined,
			);
		} catch (error) {
			return getFormErrors(error);
		}
	}, []);

	return {
		otpDetails,
		mutate,
		/**
		 * Create a new OTP device for the user. The user will receive OTP codes via SMS or WhatsApp.
		 */
		createOTPDevice: async (data: CreateOTPDevice) => {
			try {
				await api.post("/otp/otp-device/", data);
			} catch (error) {
				return getFormErrors(error);
			}
		},
		/**
		 * Delete the OTP device for the user.
		 */
		deleteOTPDevice: async (password: string) => {
			try {
				await api.post("/otp/otp-device/delete/", {
					password,
				});
			} catch (error) {
				return getFormErrors(error);
			}
		},
		/**
		 * Confirm a newly registered OTP device by verifying the OTP token.
		 */
		confirmOTPDevice: handleConfirmOTPDevice,
		/**
		 * This endpoint triggers an OTP code generation for the user's device. The user can then use this OTP to verify their identity.
		 */
		requestOTP: handleRequestOTP,
		/**
		 * This endpoint verifies the provided OTP code for the user's registered device. If the OTP is correct, the user is marked as verified in the session.
		 */
		verifyOTP: handleVerifyOTPDevice,
		/**
		 * Marks the current device as trusted for the authenticated user, enabling future logins from this device without requiring OTP verification.
		 */
		trustDevice: async () => {
			try {
				await api.post("/otp/device/trust/");
			} catch (error) {
				return getFormErrors(error);
			}
		},
		disableOTPDevice: async () => {
			try {
				await api.post("/users/disable-otp/");
			} catch (error) {
				return getFormErrors(error);
			}
		},
		validateCurrentNumber: async (password: string) => {
			try {
				await api.post("/otp/otp-device/validate-current-number/", {
					password,
				});
			} catch (error) {
				return getFormErrors(error);
			}
		},
		changeOTPNumber: async (data: {
			contact_number: string;
			token?: string;
			method?: OTPMethod;
		}) => {
			try {
				await api.post("/otp/otp-device/change-number/", {
					contact_number: data.contact_number,
					otp_token: data.token,
					otp_method: data.method,
				});
			} catch (error) {
				return getFormErrors(error);
			}
		},
		changeChannel: async (method: OTPMethod) => {
			try {
				await api.post("/otp/otp-device/change-channel/", {
					method,
				});
				await mutate();
			} catch (error) {
				return getFormErrors(error);
			}
		},
	};
};
