import { links } from "@app/constants/links";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";

import styles from "./user-menu.module.css";

import contactSrc from "./contact.svg";
import faqsSrc from "./faqs.svg";
import globeSrc from "./globe.svg";
import logoutSrc from "./logout.svg";
import settingsSrc from "./settings.svg";

export const UserMenu = ({
	onLogout,
	onSettings,
}: {
	onLogout: () => void;
	onSettings: () => void;
}) => {
	return (
		<NavigationMenu.Root>
			<NavigationMenu.List>
				<NavigationMenu.Item>
					<NavigationMenu.Trigger
						className={styles.trigger}
						aria-label="user menu"
					>
						<svg
							role="presentation"
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
						>
							<path
								d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
								fill="black"
							/>
							<path
								d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z"
								fill="black"
							/>
							<path
								d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z"
								fill="black"
							/>
							<path
								d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
								stroke="white"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z"
								stroke="white"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z"
								stroke="white"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</NavigationMenu.Trigger>
					<NavigationMenu.Content className={styles.content}>
						<NavigationMenu.Item className={styles.item} onClick={onSettings}>
							<img src={settingsSrc} height={20} width={20} alt="" />
							Settings
						</NavigationMenu.Item>
						<NavigationMenu.Link
							className={styles.item}
							href={links.contact}
							target="_blank"
						>
							<img src={contactSrc} width={20} height={20} alt="" />
							Contact us
						</NavigationMenu.Link>
						<NavigationMenu.Link
							className={styles.item}
							href={links.forexFaq}
							target="_blank"
						>
							<img src={faqsSrc} width={20} height={20} alt="" />
							FAQs
						</NavigationMenu.Link>
						<NavigationMenu.Link
							className={styles.item}
							href={links.website}
							target="_blank"
						>
							<img src={globeSrc} height={20} width={20} alt="" />
							Visit website
						</NavigationMenu.Link>
						<div className={styles.divider} />
						<NavigationMenu.Item
							className={styles.item}
							onClick={() => onLogout()}
						>
							<img src={logoutSrc} height={20} width={20} alt="" />
							Log out
						</NavigationMenu.Item>
					</NavigationMenu.Content>
				</NavigationMenu.Item>
			</NavigationMenu.List>
		</NavigationMenu.Root>
	);
};
