import { FieldError } from "@app/components/field-error";
import { Input } from "@app/components/input";
import { Label } from "@app/components/label";
import { ReactNode, forwardRef } from "react";
import type { FieldError as RHFFieldError } from "react-hook-form";
import { OnValueChange, PatternFormat } from "react-number-format";

type FormatType = "percentage";

const getFormat = (type: FormatType, value?: string) => {
	if (type === "percentage") {
		const pattern = value ? "#".repeat(Math.min(value.length + 1, 3)) : "# ";
		return `${pattern}${pattern.length === 3 ? " " : ""}%`;
	}
	return "";
};

export const PatternField = forwardRef<
	any,
	{
		name: string;
		error?: RHFFieldError;
		label?: string;
		placeholder: string;
		tooltip?: ReactNode;
		tooltipWidth?: number;
		value?: string;
		type: FormatType;
		onValueChange?: OnValueChange;
		onBlur?: () => void;
	}
>(
	(
		{
			label,
			name,
			error,
			tooltip,
			type,
			tooltipWidth,
			onValueChange,
			value,
			placeholder,
			...rest
		},
		ref,
	) => {
		return (
			<div>
				{label && (
					<Label htmlFor={name} tooltip={tooltip} tooltipWidth={tooltipWidth}>
						{label}
					</Label>
				)}
				<PatternFormat
					customInput={Input}
					getInputRef={ref}
					placeholder={placeholder}
					value={value}
					name={name}
					aria-invalid={!!error}
					onValueChange={onValueChange}
					type="text"
					format={getFormat(type, value)}
					{...rest}
				/>
				{error && <FieldError>{error?.message}</FieldError>}
			</div>
		);
	},
);
