import { type ReactNode, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { Button } from "@app/components/button";
import { pathParams, paths } from "@app/constants/paths";

import { ApiErrors } from "@app/components/api-errors";
import { Title } from "@app/components/title";
import { LockIcon } from "../login/lock-icon";
import { PasswordToggleButton } from "../password-toggle-button";
import { TextField } from "../text-field";
import styles from "./index.module.css";
import { StandardOTPFlow } from "@app/features/otp/standard-otp-flow";
import { getIsOTPError } from "@app/features/otp/get-is-otp-error";
import { OTPMethod } from "@app/features/otp/use-otp";
import { ConfirmedModal } from "@app/features/otp/modals/confirmed-modal";
import { useAuth } from "@app/hooks/use-auth";

const PasswordReset = () => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [showStandardOTPFlow, setShowStandardOTPFlow] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const [apiErrors, setApiErrors] = useState<ReactNode[]>([]);
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);

	const {
		register,
		handleSubmit,
		getValues,
		watch,
		formState: { errors },
	} = useForm<{
		password: string;
		confirmPassword: string;
	}>({
		defaultValues: {},
		mode: "onChange",
	});

	const navigate = useNavigate();
	const { onResetPassword } = useAuth();

	const params = useParams();
	const paramUid = params[pathParams.uid];
	const paramToken = params[pathParams.token];

	const onCancel = () => {
		navigate(paths().login);
	};

	const onClosePasswordConfirmationModal = () => navigate(paths().login);

	const handleResetPassword = async (
		formData: {
			password: string;
			confirmPassword: string;
			otpToken?: string;
			otpMethod?: OTPMethod;
		},
		isOTP = false,
	) => {
		if (!paramUid || !paramToken) return;
		setIsSubmitting(true);
		const errors = await onResetPassword({
			uid: paramUid,
			token: paramToken,
			password: formData.confirmPassword,
			otpToken: formData.otpToken,
			otpMethod: formData.otpMethod,
		});
		setIsSubmitting(false);

		if (errors && errors.apiErrors.length > 0 && isOTP) {
			return errors.apiErrors;
		}

		setShowStandardOTPFlow(false);

		if (errors) {
			if (getIsOTPError(errors.apiErrors)) {
				setShowStandardOTPFlow(true);
				return;
			}

			setApiErrors(errors.apiErrors);
			return errors.apiErrors;
		}

		setApiErrors([]);
		setShowConfirmationModal(true);
	};

	return (
		<form
			onSubmit={handleSubmit((values) => handleResetPassword(values))}
			noValidate
		>
			<>
				<Title>Create new password</Title>
				<div className={styles.form}>
					<TextField
						register={register}
						name="password"
						label="Password"
						type={showPassword ? "text" : "password"}
						required
						validate={(value) => {
							if (value.length < 8) {
								return "Password must contain at least 8 characters";
							}
							const confirmPassword = watch("confirmPassword");
							if (confirmPassword && confirmPassword !== value) {
								return "Password doesn’t match";
							}

							if (!/[A-Z]/.test(value))
								return "Password must contain at least one upper-case character";

							if (!/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(value))
								return "Password must contain at least one special character";

							return true;
						}}
						leftSection={<LockIcon />}
						rightSection={
							<PasswordToggleButton
								value={showPassword}
								onChange={setShowPassword}
							/>
						}
						error={errors.password}
					/>
					<TextField
						register={register}
						name="confirmPassword"
						label="Confirm password"
						type={showConfirmPassword ? "text" : "password"}
						required
						leftSection={<LockIcon />}
						validate={(value) => {
							if (watch("password") !== value) {
								return "Password doesn’t match";
							}
							return true;
						}}
						rightSection={
							<PasswordToggleButton
								value={showConfirmPassword}
								onChange={setShowConfirmPassword}
							/>
						}
						error={errors.confirmPassword}
					/>
					<ApiErrors errors={apiErrors} />
				</div>
				<div className={styles.buttons}>
					<Button disabled={isSubmitting} type="submit">
						{isSubmitting ? "Submitting" : "Submit"}
					</Button>
					<Button disabled={isSubmitting} onClick={onCancel} variant="tertiary">
						Cancel
					</Button>
				</div>
			</>
			{showConfirmationModal && (
				<ConfirmedModal
					title="Password changed"
					completeText="Continue to login"
					onClose={onClosePasswordConfirmationModal}
				/>
			)}

			{showStandardOTPFlow && (
				<StandardOTPFlow
					variant="unauthed"
					onClose={() => setShowStandardOTPFlow(false)}
					onResend={async (type) => {
						const errors = await handleResetPassword(
							{
								...getValues(),
								otpMethod: type,
							},
							true,
						);
						if (errors && !getIsOTPError(errors)) {
							return {
								apiErrors: errors,
								fieldErrors: [],
							};
						}
					}}
					onComplete={async (token) => {
						const errors = token
							? await handleResetPassword(
									{
										otpToken: token,
										...getValues(),
									},
									true,
								)
							: await handleResetPassword(getValues(), true);
						if (errors) {
							return {
								apiErrors: errors,
								fieldErrors: [],
							};
						}
					}}
				/>
			)}
		</form>
	);
};

export default PasswordReset;
