// import { createRoot } from "react-dom/client";
import { AxiosError, isAxiosError } from "axios";
import { StrictMode, useEffect } from "react";
import ReactDOM from "react-dom";
import {
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
} from "react-router-dom";

import * as Sentry from "@sentry/react";

import Main from "./main";

import "@fontsource-variable/roboto-flex";
import "@fontsource/belleza";
import { isDemo, isLocalhost, isProd } from "./config/env";
import "./index.css";

if (APP_VERSION) {
	console.log(`Version: ${APP_VERSION}`);
}

if (!isLocalhost) {
	const addAxiosContextRecursive = (event: Sentry.Event, error: unknown) => {
		if (isAxiosError(error)) {
			addAxiosContext(event, error);
		} else if (error instanceof Error && error.cause) {
			addAxiosContextRecursive(event, error.cause);
		}
	};

	function addAxiosContext(event: Sentry.Event, error: AxiosError) {
		if (error.response) {
			const contexts = { ...event.contexts };
			contexts.Response = { ...error.response };
			event.contexts = contexts;
		}
	}

	Sentry.init({
		normalizeDepth: 5,
		beforeSend: (event, hint) => {
			addAxiosContextRecursive(event, hint?.originalException);
			return event;
		},
		ignoreErrors: [
			"Object captured as promise rejection with keys: code, message",
			"Object captured as exception with keys: config, data, headers, request, status",
			"Object captured as promise rejection with keys: config, data, headers, request, status",
			"Non-Error exception captured",
			"Non-Error promise rejection captured",
			"CanceledError",
		],
		release: process.env.VERSION,
		dsn: "https://c063a5770b8ddf86c52f3a29f394119b@o4504921484623872.ingest.sentry.io/4506746137542656",
		integrations: [
			Sentry.replayIntegration(),
			Sentry.browserTracingIntegration(),
			Sentry.reactRouterV6BrowserTracingIntegration({
				useEffect: useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes,
			}),
		],
		replaysSessionSampleRate: 0,
		replaysOnErrorSampleRate: 1.0,
		tracesSampleRate: 0.3,
		environment: isProd ? "production" : isDemo ? "demo" : "development",
	});
}

// const container = document.getElementById("root");
// const root = createRoot(container!);
// root.render(
// 	<StrictMode>
// 		<Main />
// 	</StrictMode>,
// );
ReactDOM.render(
	<StrictMode>
		<Main />
	</StrictMode>,
	document.getElementById("root"),
);
