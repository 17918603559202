import { useClientProfile } from "@app/hooks/use-client-profile";
import { useProfilePicture } from "@app/hooks/use-profile-picture";
import { UserMenu } from "./user-menu";

import { ProfilePicture } from "@app/components/profile-picture";
import { useUserProfile } from "@app/features/user-settings/use-user-profile";
import styles from "./user-row.module.css";

export const UserRow = ({
	onLogout,
	onSettings,
}: {
	onLogout: () => void;
	onSettings: () => void;
}) => {
	const { data: profilePicture } = useProfilePicture();
	const { data: clientProfile } = useClientProfile();
	const { data: userProfile } = useUserProfile();

	return (
		<div className={styles.container}>
			<ProfilePicture size={40} src={profilePicture?.base64} />
			<p className={styles.name}>
				{clientProfile?.display_name ?? userProfile?.email}
			</p>
			<UserMenu onSettings={onSettings} onLogout={() => onLogout()} />
		</div>
	);
};
