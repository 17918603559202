import axios from "axios";
import { useEffect } from "react";
import { apiUrl } from "../config/env";
import { handleGeneralError } from "@app/utils/handle-general-error";

const TOKEN_KEY = "csrf_token";

export const getCrsfToken = () => window.sessionStorage.getItem(TOKEN_KEY);
export const clearCsrfToken = () => window.sessionStorage.removeItem(TOKEN_KEY);

/**
 * Retreive an CSRF token for the current session.
 * Used within the API client to set the X-CSRFToken header
 */
export const useCsrfToken = (isAuthenticated = false) => {
	useEffect(() => {
		const handleCsrfToken = async () => {
			try {
				const result = await axios.get("auth/get-csrf-token/", {
					baseURL: `${apiUrl}api/`,
					withCredentials: true,
				});
				if (result.data.csrf_token) {
					window.sessionStorage.setItem(TOKEN_KEY, result.data.csrf_token);
				}
			} catch (error) {
				handleGeneralError(error);
			}
		};
		if (isAuthenticated) {
			handleCsrfToken();
		}
	}, [isAuthenticated]);
};
