import { memo } from "react";

import { Carousel } from "@app/components/carousel";
import { NoTransactionsBlock } from "@app/components/no-transactions-block";
import type { ListTransactionInProgress } from "@app/entities";
import { CarouselCard } from "./carousel-card";
import { CarouselEndCard } from "./carousel-end-card";

import { CountTag } from "../count-tag";
import styles from "./index.module.css";

export const InProgressTransactionCarousel = memo(
	({
		data,
		isLoading = false,
	}: {
		isLoading?: boolean;
		data?: ListTransactionInProgress[];
	}) => {
		const hasTransactions = data?.length && data?.length > 0;
		return (
			<>
				{hasTransactions ? (
					<Carousel
						heading="In progress"
						displayAmount
						values={data}
						template={(item, index) => (
							<>
								<CarouselCard {...item} index={index} />
								{data && item === data[data.length - 1] && <CarouselEndCard />}
							</>
						)}
					/>
				) : (
					<div className={styles.empty}>
						<div className={styles.headerRow}>
							<h3 className={styles.title}>In progress</h3>
							<CountTag>{data?.length ?? 0}</CountTag>
						</div>
						{isLoading ? (
							<div className={styles.loadingBlock} />
						) : (
							<NoTransactionsBlock showButton />
						)}
					</div>
				)}
			</>
		);
	},
);
