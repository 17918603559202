import styles from "./account-manager-card.module.css";

import { IconButton } from "@app/components/icon-button";
import { ProfilePicture } from "@app/components/profile-picture";
import { apiUrl } from "@app/config/env";
import { useAccountManager } from "@app/hooks/use-account-manager";
import { useMediaQuery } from "@app/hooks/use-media-query";
import emailSrc from "./email.svg";
import phoneSrc from "./phone.svg";

export const AccountManagerCard = ({
	variant = "default",
}: {
	variant?: "default" | "condensed";
}) => {
	const isMobile = useMediaQuery();
	const { data: accountManager } = useAccountManager();

	const email = accountManager?.email ?? "info@futureforex.com";
	const phone = accountManager?.contact_number ?? "021 518 0558";
	const accountManagerPhotoUrl =
		accountManager?.profile_photo && apiUrl
			? apiUrl + accountManager.profile_photo.replace(/^\//, "")
			: "";

	return (
		<div className={styles.container} data-variant={variant}>
			<div className={styles.details}>
				<ProfilePicture size={48} src={accountManagerPhotoUrl} />
				<div>
					{accountManager?.name ? (
						<p className={styles.name}>{accountManager.name}</p>
					) : (
						<p className={styles.contactUs}>Contact us</p>
					)}

					{accountManager?.title && <p>{accountManager.title}</p>}
				</div>
			</div>
			<div className={styles.line} />
			<ul className={styles.contactDetails}>
				<li className={styles.link}>
					{isMobile ? (
						<IconButton
							onClick={() => {
								window.open(`mailto:${email}`, "_blank");
							}}
						>
							<img src={emailSrc} alt="" width={24} height={24} />
						</IconButton>
					) : (
						<>
							<img src={emailSrc} alt="" width={16} height={16} />
							<a target="_blank" rel="noreferrer" href={`mailto:${email}`}>
								{email}
							</a>
						</>
					)}
				</li>
				<li className={styles.link}>
					{isMobile ? (
						<IconButton
							onClick={() => {
								window.open(`tel:${phone}`, "_blank");
							}}
						>
							<img src={phoneSrc} alt="" width={24} height={24} />
						</IconButton>
					) : (
						<>
							<img src={phoneSrc} alt="" width={16} height={16} />
							<a target="_blank" rel="noreferrer" href={`tel:${phone}`}>
								{phone}
							</a>
						</>
					)}
				</li>
			</ul>
		</div>
	);
};
