import { useDispatch } from "react-redux";

import type { AppDispatch } from "@app/redux";
import { storeUnverifiedEmailAction } from "@app/redux/auth/auth-actions";

export const useStoreUnverifiedEmail = () => {
	const dispatch: AppDispatch = useDispatch();

	const storeUnverifiedEmail = (email: string | null) => {
		dispatch(storeUnverifiedEmailAction(email));
	};

	return [storeUnverifiedEmail];
};
