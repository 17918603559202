import { Button } from "@app/components/button";

import { Dialog } from "@app/components/dialog";
import { useMediaQuery } from "@app/hooks/use-media-query";
import styles from "./index.module.css";

export const ResendModal = ({
	isResetPassword,
	userEmail,
	onClose,
}: {
	isResetPassword?: boolean;
	userEmail: string;
	onClose: () => void;
}) => {
	const isMobile = useMediaQuery();
	return (
		<Dialog
			isOpen
			title="Email resent"
			isSmallHeader
			actions={
				<Button centered onClick={onClose}>
					Continue
				</Button>
			}
			onClose={onClose}
			description={
				<>
					We have sent another email to{" "}
					<span className={styles.email}>{userEmail}&nbsp;</span> to{" "}
					{isResetPassword ? "reset your password" : "verify your account"}.
				</>
			}
		>
			<p className={styles.description}>
				{isMobile
					? "Remember to check your spam/junk."
					: "Remember to check your spam/junk folders as well."}
			</p>
		</Dialog>
	);
};
