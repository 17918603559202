import { Button } from "@app/components/button";
import { useMediaQuery } from "@app/hooks/use-media-query";
import { clsx } from "clsx";
import { ReactNode } from "react";
import { ChangeSaved } from "../../../components/changes-saved";
import styles from "./index.module.css";

export const OnboardingFooter = ({
	isSaving,
	hasSaved,
	onSubmit,
	onBack,
	formId,
	isSubmitting,
	primaryText = "Next",
}: {
	isSubmitting: boolean;
	isSaving: boolean;
	hasSaved: boolean;
	onSubmit?: () => void;
	onBack?: () => void;
	formId: string;
	primaryText?: ReactNode;
}) => {
	const isMobile = useMediaQuery();

	return (
		<footer className={styles.footer}>
			{!isMobile && (
				<>
					{onBack ? (
						<Button
							disabled={isSubmitting}
							onClick={onBack}
							variant="tertiary"
							className={clsx(styles.button, styles.backButton)}
						>
							Back
						</Button>
					) : (
						<div />
					)}
				</>
			)}

			<ChangeSaved
				status={isSaving ? "saving" : hasSaved ? "saved" : "unsaved"}
			/>

			{!isMobile && (
				<Button
					disabled={isSubmitting}
					onClick={onSubmit}
					type={onSubmit ? "button" : "submit"}
					form={onSubmit ? undefined : formId}
					className={styles.button}
				>
					{primaryText}
				</Button>
			)}

			{isMobile && (
				<div className={styles.buttons}>
					{onBack && (
						<Button
							disabled={isSubmitting}
							variant="secondary"
							onClick={onBack}
							block
							className={styles.button}
						>
							Back
						</Button>
					)}
					<Button
						disabled={isSubmitting}
						onClick={onSubmit}
						type={onSubmit ? "button" : "submit"}
						form={onSubmit ? undefined : formId}
						block
						className={styles.button}
					>
						{primaryText}
					</Button>
				</div>
			)}
		</footer>
	);
};
