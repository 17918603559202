import styles from "./index.module.css";
import { Spacer } from "./components/spacer";
import { DropDownOptionGrouped } from "./components/drop-down-option-grouped";
import type { FormInputProps, ViewProperties } from "./types";
import { Custom } from "./components/custom";
import { DatePicker } from "./components/date-picker";
import { DropDown } from "./components/drop-down";
import { MultiSelect } from "./components/multi-select";
import { DropDownOption } from "./components/drop-down-option";
import { MultiSelectCurrency } from "./components/multi-select-currency";
import { HiddenField } from "./components/hidden-field";
import { InputField } from "./components/input-field";

export const FormBuilder = (props: ViewProperties) => {
	const buildField = (fieldProps: FormInputProps) => {
		switch (fieldProps.type) {
			case "custom":
				return <Custom {...fieldProps} />;
			case "date-picker":
				return (
					<DatePicker
						{...fieldProps}
						errors={props.errors}
						formControl={props.formControl}
						mappedReasons={props.mappedReasons}
					/>
				);
			case "dropdown":
				return (
					<DropDown
						{...fieldProps}
						errors={props.errors}
						formControl={props.formControl}
						mappedReasons={props.mappedReasons}
					/>
				);
			case "dropdown-option-grouped":
				return (
					<DropDownOptionGrouped
						{...fieldProps}
						errors={props.errors}
						formControl={props.formControl}
						mappedReasons={props.mappedReasons}
					/>
				);
			case "dropdown-option":
				return (
					<DropDownOption
						{...fieldProps}
						errors={props.errors}
						formControl={props.formControl}
						mappedReasons={props.mappedReasons}
						optionLabel="label"
					/>
				);
			case "multiselect":
				return (
					<MultiSelect
						{...fieldProps}
						errors={props.errors}
						formControl={props.formControl}
						mappedReasons={props.mappedReasons}
					/>
				);
			case "multiselect-currency":
				return (
					<MultiSelectCurrency
						{...fieldProps}
						errors={props.errors}
						formControl={props.formControl}
						mappedReasons={props.mappedReasons}
					/>
				);

			case "spacer":
				return <Spacer />;

			case "hidden":
				return (
					<HiddenField
						{...fieldProps}
						errors={props.errors}
						formControl={props.formControl}
						mappedReasons={props.mappedReasons}
					/>
				);

			default:
				return (
					<InputField
						{...fieldProps}
						errors={props.errors}
						formControl={props.formControl}
						mappedReasons={props.mappedReasons}
					/>
				);
		}
	};

	return (
		<div className={styles.root}>
			{props.ignoreRows &&
				props.formInputs.map((inputRow, parentIndex) =>
					inputRow.map((inputField, index) => (
						<div className={styles.formField} key={`${parentIndex}-${index}`}>
							{buildField(inputField)}
						</div>
					)),
				)}
			{!props.ignoreRows &&
				props.formInputs.map((inputRow, parentIndex) => (
					<div key={`inputrow-${parentIndex}`} className={styles.formRow}>
						{inputRow.map((inputField, index) => (
							<div className={styles.formField} key={`${parentIndex}-${index}`}>
								{buildField(inputField)}
							</div>
						))}
					</div>
				))}
		</div>
	);
};
