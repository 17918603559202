import { paths } from "@app/constants/paths";
import { useAccountManager } from "@app/hooks/use-account-manager";
import { Button } from "../button";
import { Dialog } from "../dialog";

import emailSrc from "./email.svg";
import phoneSrc from "./phone.svg";

import styles from "./index.module.css";

export const NotFoundModal = ({
	description,
}: {
	description: string;
}) => {
	const { data: accountManager } = useAccountManager();

	const email = accountManager?.email ?? "info@futureforex.com";
	const phone = accountManager?.contact_number ?? "021 518 0558";
	return (
		<Dialog
			isOpen
			title="Not found"
			description={description}
			actions={
				<Button centered to={paths().dashboard}>
					Home
				</Button>
			}
		>
			<p className={styles.description}>
				If you need help, your Account Manager
				{accountManager?.name ? `, ${accountManager.name}, ` : ""} will be happy
				to assist.
			</p>
			<ul className={styles.contactContainer}>
				<li className={styles.contactItem}>
					<img src={emailSrc} alt="" width={16} height={16} />
					<a href={`mailto:${email}`} rel="noreferrer" target="_blank">
						{email}
					</a>
				</li>
				<li className={styles.contactItem}>
					<img src={phoneSrc} alt="" width={16} height={16} />
					<a href={`tel:${phone}`} rel="noreferrer" target="_blank">
						{phone}
					</a>
				</li>
			</ul>
		</Dialog>
	);
};
