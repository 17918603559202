import { ConfirmedModal } from "../confirmed-modal";

export const ConfirmedDisableModal = ({
	onClose,
}: {
	onClose: () => void;
}) => {
	return (
		<ConfirmedModal
			onClose={onClose}
			title="OTP Disabled"
			iconVariant="error"
			description="If you wish to enable OTP authentication in future to secure your account, you can do so in your settings."
		/>
	);
};
