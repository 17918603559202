import { Button } from "@app/components/button";
import { Dialog } from "@app/components/dialog";
import checkSrc from "./check.svg";

import styles from "./index.module.css";

export const TransactionSubmittedModal = (props: {
	isOpen: boolean;
	onClose: () => void;
}) => {
	return (
		<Dialog
			size="sm"
			isOpen={props.isOpen}
			actions={
				<Button centered onClick={props.onClose} type="button">
					Continue
				</Button>
			}
		>
			<div className={styles.content}>
				<img
					alt=""
					className={styles.graphic}
					src={checkSrc}
					width={60}
					height={60}
				/>

				<h1 className={styles.title}>Transaction submitted</h1>

				<p className={styles.description}>
					You can track its status on the Transactions page.
				</p>
			</div>
		</Dialog>
	);
};
