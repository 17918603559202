import { MobileHelp } from "@app/components/mobile-help";
import { Notifications } from "@app/components/notifications";
import { SignedInLayout } from "@app/components/signed-in-layout";
import { UserSettingsContent } from "./user-settings-content";

const UserSettings = () => {
	return (
		<SignedInLayout
			title="Settings"
			mobileRightSection={
				<>
					<MobileHelp />
					<Notifications />
				</>
			}
		>
			<UserSettingsContent />
		</SignedInLayout>
	);
};

export default UserSettings;
