import { STORAGE_KEYS } from "@app/constants/storage-keys";
import { api } from "@app/services";
import { EntityType } from "@app/types";
import { getApiError } from "@app/utils/get-api-error";
import { getFormErrors } from "@app/utils/get-form-errors";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

export const useRegister = () => {
	const [searchParams] = useSearchParams();
	const [email, setEmail] = useState("");
	return {
		email,
		onRegister: async (data: {
			email: string;
			password: string;
			entity_type: EntityType;
		}) => {
			try {
				await api.post("/users/register/", data);
				const ref = searchParams.get("ref");
				const refName = searchParams.get("refName");
				if (ref) {
					window.localStorage.setItem(STORAGE_KEYS.ref, ref);
				}
				if (refName) {
					window.localStorage.setItem(STORAGE_KEYS.refName, refName);
				}
				setEmail(data.email);
				window.localStorage.setItem(STORAGE_KEYS.registerEmail, data.email);
			} catch (error) {
				return getFormErrors(error);
			}
		},
		onResendEmail: async () => {
			try {
				await api.post(`/users/resend-confirmation-email/${email}/`);
			} catch (error) {
				return getApiError(error);
			}
		},
	};
};
