import { paths } from "@app/constants/paths";

/**
 * @description Check if the previous path pre login is ignored. If it is, we don't want to redirect to it.
 * @param path - The path to check
 * @returns - True if the path is ignored, false otherwise
 */
export const getIsIgnoredPath = (path: string | null) => {
	if (!path) return false;
	const ignored = [
		paths().root,
		paths().login,
		paths().register,
		paths().resetPassword,
		paths().arbitrageNotVerified,
		"/password-reset",
		paths().notVerified,
		paths().selectService,
		paths().selectForgotPassword,
		paths().error.general,
	];

	return ignored.includes(path);
};
