import { Suspense } from "react";
import {
	BrowserRouter,
	Navigate,
	Outlet,
	Route,
	Routes,
} from "react-router-dom";

import { CustomLoader } from "@app/components/custom-loader";
import ForgotPassword from "@app/features/auth/forgot-password";
import Login from "@app/features/auth/login";
import NotVerified from "@app/features/auth/not-verified";
import PasswordReset from "@app/features/auth/password-reset";
import Register from "@app/features/auth/register";
import { Auth as AuthWrapper } from "@app/wrappers/auth";
import { withSentryReactRouterV6Routing } from "@sentry/react";

import { pathSchema } from "@app/constants/paths";
import { SessionTimeout } from "./components/session-timeout";
import AddEditRecipients from "./features/add-edit-recipients";
import { ConfirmEmail } from "./features/auth/confirm-email";
import { LoginLayout } from "./features/auth/login-layout";
import { SelectService } from "./features/auth/register/select-service";
import { SelectFogotPassword } from "./features/auth/select-forgot-password";
import CreateQuote from "./features/create-quote";
import Dashboard from "./features/dashboard";
import { FundConfirmBankClosedError } from "./components/fund-confirm-bank-closed-error";
import { GeneralError } from "./components/general-error";
import { BusinessCompanyBasics } from "./features/onboarding/business-company-basics";
import { BusinessCompanyDetails } from "./features/onboarding/business-company-details";
import { BusinessCompanyDirectors } from "./features/onboarding/business-company-directors";
import { BusinessCompanyShareholders } from "./features/onboarding/business-company-shareholders";
import { BusinessCompanySignatories } from "./features/onboarding/business-company-signatories";
import { PrimaryBusinessShareholders } from "./features/onboarding/business-primary-shareholders";
import { IndividualAdditionalDetails } from "./features/onboarding/individual-additional-details";
import { IndividualAddressInformation } from "./features/onboarding/individual-address-information";
import { IndividualPersonalInformation } from "./features/onboarding/individual-personal-information";
import { OnboardingUserSettings } from "./features/onboarding/user-settings";
import Recipients from "./features/recipients";
import { BalanceOfPayment } from "./features/transaction-details/balance-of-payment";
import ConfirmPayment from "./features/transaction-details/confirm-payment";
import Documents from "./features/transaction-details/documents";
import PaymentDetails from "./features/transaction-details/payment-details";
import ReviewTransaction from "./features/transaction-details/review-transaction";
import Transactions from "./features/transactions";
import { UnsupportedBrowser } from "./features/unsupported-browser";
import UserSettings from "./features/user-settings";
import ViewRecipient from "./features/view-recipient";
import ViewTransaction from "./features/view-transaction";
import { GenericErrorProvider } from "./components/generic-error-modal";

const SentryRoutes = withSentryReactRouterV6Routing(Routes);

export const Router = () => {
	return (
		<Suspense fallback={<CustomLoader />}>
			<BrowserRouter>
				<GenericErrorProvider>
					<SentryRoutes>
						<Route
							path={pathSchema.unsupportedBrowser}
							element={<UnsupportedBrowser />}
						/>
						<Route
							element={
								<LoginLayout>
									<Outlet />
								</LoginLayout>
							}
						>
							<Route path={pathSchema.login} element={<Login />} />
							<Route path={pathSchema.register} element={<Register />} />
							<Route
								path={pathSchema.resetPassword}
								element={<ForgotPassword />}
							/>
							<Route
								path={pathSchema.passwordReset}
								element={<PasswordReset />}
							/>
							<Route path={pathSchema.notVerified} element={<NotVerified />} />
							<Route
								path={pathSchema.arbitrageNotVerified}
								element={<NotVerified variant="arbitrage" />}
							/>
							<Route
								path={pathSchema.selectService}
								element={<SelectService />}
							/>
							<Route
								path={pathSchema.selectForgotPassword}
								element={<SelectFogotPassword />}
							/>
							<Route
								path={pathSchema.forgotPassword}
								element={<SelectFogotPassword />}
							/>
							<Route path="/activate/:uid/:token" element={<ConfirmEmail />} />
						</Route>
						<Route
							element={
								<AuthWrapper>
									<SessionTimeout />
									<Outlet />
								</AuthWrapper>
							}
						>
							<Route path="/" element={<Dashboard />} />

							<Route path={pathSchema.dashboard} element={<Dashboard />} />

							<Route path={pathSchema.recipients} element={<Recipients />} />

							<Route
								path={pathSchema.transactions}
								element={<Transactions />}
							/>

							<Route
								path={pathSchema.sendReceiveFunds}
								element={<CreateQuote />}
							/>

							<Route
								path={pathSchema.reviewTransaction}
								element={<ReviewTransaction />}
							/>

							<Route
								path={pathSchema.paymentDetails}
								element={<PaymentDetails />}
							/>

							<Route
								path={pathSchema.paymentDetailsAddRecipient}
								element={<AddEditRecipients variant="payment-details" />}
							/>

							<Route
								path={pathSchema.balanceOfPayment}
								element={<BalanceOfPayment />}
							/>

							<Route path={pathSchema.documents} element={<Documents />} />

							<Route
								path={pathSchema.confirmPayment}
								element={<ConfirmPayment />}
							/>

							<Route
								path={pathSchema.userSettings}
								element={<UserSettings />}
							/>
							<Route
								path={pathSchema.addRecipient}
								element={<AddEditRecipients />}
							/>

							<Route
								path={pathSchema.editRecipient}
								element={<AddEditRecipients />}
							/>

							<Route
								path={pathSchema.viewRecipient}
								element={<ViewRecipient />}
							/>

							<Route
								path={pathSchema.viewTransaction}
								element={<ViewTransaction />}
							/>

							<Route
								path={pathSchema.error.fundConfirmBankClosed}
								element={<FundConfirmBankClosedError />}
							/>

							<Route
								path={pathSchema.onboarding.individual.personalInformation}
								element={<IndividualPersonalInformation />}
							/>

							<Route
								path={pathSchema.onboarding.individual.addressInformation}
								element={<IndividualAddressInformation />}
							/>

							<Route
								path={pathSchema.onboarding.individual.additionalDetails}
								element={<IndividualAdditionalDetails />}
							/>

							<Route
								path={pathSchema.onboarding.business.companyBasics}
								element={<BusinessCompanyBasics />}
							/>

							<Route
								path={pathSchema.onboarding.business.companyDetails}
								element={<BusinessCompanyDetails />}
							/>

							<Route
								path={pathSchema.onboarding.business.directors}
								element={<BusinessCompanyDirectors />}
							/>

							<Route
								path={pathSchema.onboarding.business.signatories}
								element={<BusinessCompanySignatories />}
							/>

							<Route
								path={pathSchema.onboarding.business.shareholders}
								element={<BusinessCompanyShareholders />}
							/>

							<Route
								path={pathSchema.onboarding.business.primaryShareholders}
								element={<PrimaryBusinessShareholders />}
							/>

							<Route
								path={pathSchema.onboarding.userSettings}
								element={<OnboardingUserSettings />}
							/>

							<Route
								path={pathSchema.unsupportedBrowserWithClientId}
								element={<UnsupportedBrowser />}
							/>
						</Route>

						<Route
							path="/register/individual"
							element={<Navigate replace to="/register?type=individual" />}
						/>
						<Route
							path="/register/business"
							element={<Navigate replace to="/register?type=legal_entity" />}
						/>
						<Route path={pathSchema.error.general} element={<GeneralError />} />
						<Route
							path="*"
							element={<Navigate replace to={pathSchema.login} />}
						/>
					</SentryRoutes>
				</GenericErrorProvider>
			</BrowserRouter>
		</Suspense>
	);
};
