import { ElementRef, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import BankIcon from "@app/assets/images/bank.svg";
import ListIcon from "@app/assets/images/list.svg";
import PlusIcon from "@app/assets/images/plus.svg";
import UserCheckIcon from "@app/assets/images/user-check-01.svg";
import { Button } from "@app/components/button";
import { Typography } from "@app/components/typography";
import { paths } from "@app/constants/paths";
import {
	CurrencyOption,
	type TransactionDirection,
	TransactionInProgressStatus,
} from "@app/entities";

import { CountryIcon } from "@app/components/country-icon";
import { MoreInfoTooltip } from "@app/components/more-info-tooltip";
import { FiArrowDown, FiArrowUp } from "react-icons/fi";

import { dateFormats } from "@app/constants/date-formats";
import { useGoToTransaction } from "@app/hooks/use-go-to-transaction";
import { useIsVisibleOnce } from "@app/hooks/use-is-visible-once";
import "./carousel-card.css";
import { toDayjs } from "@app/lib/date";
import { STORAGE_KEYS } from "@app/constants/storage-keys";

export const CarouselCard = (props: {
	index: number;
	id: number;
	date: string;
	recipient: string;
	zarAmount: string;
	fxAmount: string;
	fxCurrency: string;
	paymentType: string;
	paymentIds?: number[];
	zarAmountTooltip?: string;
	status?: TransactionInProgressStatus;
	currencies?: CurrencyOption[];
}) => {
	const { onGoToTransaction, isLoading } = useGoToTransaction();
	const cardRef = useRef<ElementRef<"div"> | null>(null);
	const isVisible = useIsVisibleOnce(cardRef);
	const searchChar = ".";
	const [mainCash, cents] = props.zarAmount.split(searchChar);
	const [fxMainCash, fxCents] = props.fxAmount.split(searchChar);
	const paymentType = props.paymentType.toLowerCase() as TransactionDirection;
	const isSend = paymentType === "send";
	const paymentTypeStyle = twMerge(
		isSend
			? "border border-blue-200 bg-blue-50  flex justify-center"
			: "border border-purple-200 bg-purple-50 flex justify-center",
	);
	const noName = "N/A";

	const location = useLocation();
	const navigate = useNavigate();

	const getProgressIcon = () => {
		if (props.status?.stage === 3) return BankIcon;
		if (props.status?.stage === 2) return UserCheckIcon;
		return ListIcon;
	};

	const getProgressSection = () => {
		const iconToUse = getProgressIcon();

		return (
			<div className="progress-row">
				<div className="progress-text">
					<img alt="" src={iconToUse} width={20} height={20} />
					<Typography theme="textMd" className={"my-auto font-medium"}>
						{props.status?.description}
					</Typography>
				</div>
				<div className="progress-scale">
					{[...Array(4)].map((e, i) => {
						if (i < (props.status?.stage || 4)) {
							return <div key={i} className="done-indicator" />;
						}
						return <div key={i} className="waiting-indicator" />;
					})}
				</div>
			</div>
		);
	};

	const onAddInformation = () => {
		window.sessionStorage.setItem(
			STORAGE_KEYS.resumeLaterPath,
			location.pathname,
		);
		onGoToTransaction(props.id);
	};

	const showContent = isVisible || props.index <= 5;

	return (
		<div ref={cardRef} className="in-progress-template">
			{showContent && (
				<>
					<div className="currency-row-section">
						<div className="currency-row">
							<CountryIcon currencyCode="zar" width={24} height={24} />
							<div className="currency">
								<Typography theme="displayXs" className={"my-auto font-medium"}>
									{mainCash}
								</Typography>
								<Typography
									theme="textMd"
									className={"mb-auto mt-2 font-medium text-gray-650"}
								>
									.{cents}
								</Typography>
							</div>
						</div>
						{props.zarAmountTooltip ? (
							<MoreInfoTooltip
								name="ZAR Amount"
								maxWidth={300}
								indicator={
									<p className="ml-1 text-2xl font-medium text-teal-550">*</p>
								}
							>
								{props.zarAmountTooltip}
							</MoreInfoTooltip>
						) : null}
					</div>
					<div className="type-row">
						<div
							className={`flex h-6 w-6 items-center gap-1 rounded px-1 py-1 ${paymentTypeStyle}`}
						>
							{isSend ? (
								<FiArrowDown size={16} color="#262626" />
							) : (
								<FiArrowUp size={16} color="#262626" />
							)}
						</div>
						<div className="message-section">
							{isSend ? (
								<>
									<Typography theme="textSm" className={"text-nowrap"}>
										{props.recipient === noName ? "Send" : "Send to"}
									</Typography>
									<Typography
										theme="textSm"
										className={"my-auto font-semibold"}
									>
										{props.recipient === noName ? "" : props.recipient}
									</Typography>
								</>
							) : (
								<Typography theme="textSm" className={" my-auto"}>
									Receive
								</Typography>
							)}
						</div>
					</div>
					<div className="currency-row-section">
						<div className="currency-row">
							<CountryIcon
								currencyCode={props.fxCurrency}
								width={24}
								height={24}
							/>
							<div className="currency">
								<Typography theme="displayXs" className={"my-auto font-medium"}>
									{fxMainCash}
								</Typography>
								<Typography
									theme="textMd"
									className={"mb-auto mt-2 font-medium text-gray-650"}
								>
									.{fxCents}
								</Typography>
							</div>
						</div>
					</div>
					<div className="value-row">
						<Typography theme="textSm" className={"my-auto text-gray-650"}>
							Value date:
						</Typography>
						<Typography
							theme="textSm"
							className={"my-auto font-semibold text-gray-650"}
						>
							{props.date !== null
								? toDayjs(props.date, dateFormats.reverseIso8601).format(
										dateFormats.paddedDayShortMonthYear,
									)
								: "-"}
						</Typography>
					</div>
					{getProgressSection()}
					{props.status?.addInformation ? (
						<Button block onClick={onAddInformation} disabled={isLoading}>
							<img alt="" src={PlusIcon} width={20} height={20} />
							Add information
						</Button>
					) : (
						<Button
							block
							variant="secondary"
							onClick={() => {
								navigate(paths().viewTransaction(props.id));
							}}
						>
							View
						</Button>
					)}
				</>
			)}
		</div>
	);
};
