import { useCallback, useEffect, useState } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";

import { wsUrl } from "@app/config/env";
import type {
	ForexQuote,
	ForexQuoteConfirmPage,
	GetForexQuote,
	GetForexQuoteForm,
} from "@app/entities";
import {
	type FieldInvalidationDetailResponse,
	type MappedReasons,
} from "@app/services";
import { useIsActiveTab } from "./use-is-active-tab";
import { handleGeneralError } from "@app/utils/handle-general-error";

const REFRESH_MESSAGE = "refresh";

export enum ForexQuoteErrorCodes {
	Validation = 4422,
	Throttling = 4429,
	Unauthorized = 4401,
	SystemStatusOffline = 4402,
	InvalidClientUser = 4403,
	StaffUser = 4403,
	ConnectionTimeout = 4408,
	ServerError = 4500,
}

interface ForexQuoteConfirmPageResponse {
	fx_amount: string;
	zar_amount: string;
}

interface ForexQuoteResponse {
	quote_id: number;
	spread: string;
	amount: string;
	rate: string;
	quote_page_display: string;
	confirm_page_display: ForexQuoteConfirmPageResponse;
	value_dates_disabled: {
		[valueDate: string]: boolean;
	};
}

interface WebsocketErrorResponse {
	errors?: FieldInvalidationDetailResponse[];
}

const getForexQuoteApi = (clientId: number) => {
	if (wsUrl) {
		return `${wsUrl}api/clients/${clientId}/forex-quote/`;
	}
	const host = document.location.host;
	return `wss://${host}/api/clients/${clientId}/forex-quote/`;
};

const mapToGetForexQuoteConfirmPage = (
	value: ForexQuoteConfirmPageResponse,
): ForexQuoteConfirmPage => ({
	fxAmount: value?.fx_amount,
	zarAmount: value?.zar_amount,
});

const isValidForexQuoteResponse = (value: ForexQuoteResponse) =>
	!!value.quote_id;

const mapToGetForexQuote = (value: ForexQuoteResponse): ForexQuote => ({
	quoteId: value.quote_id,
	amount: value.amount,
	spread: value.spread,
	rate: value.rate,
	quotePageDisplay: value.quote_page_display,
	confirmPageDisplay: mapToGetForexQuoteConfirmPage(value.confirm_page_display),
	valueDatesDisabled: Object.keys(value.value_dates_disabled ?? []).filter(
		(current) => value.value_dates_disabled[current],
	),
});

const mapErrors = (exception: WebsocketErrorResponse) => {
	const fieldReasons: FieldInvalidationDetailResponse[] | undefined =
		Array.isArray(exception.errors)
			? (exception as WebsocketErrorResponse).errors
			: undefined;

	const mappedReasons: MappedReasons | undefined = fieldReasons
		? {
				quoteAmount:
					fieldReasons
						?.filter((x) => x.loc.includes("amount"))
						?.map((x) => x.msg || "") || [],
			}
		: undefined;

	return mappedReasons;
};

export const useGetForexQuoteWebsocket = (
	clientId: number,
	params?: GetForexQuoteForm | GetForexQuote,
	isEnabled = true,
) => {
	const [queryParams, setQueryParams] = useState<
		| {
				fx_currency: string;
				quote_amount: string;
				quote_currency?: string;
				transaction_type: string;
				settlement_account_id: number;
		  }
		| undefined
	>(undefined);
	const isActiveTab = useIsActiveTab();
	const [value, setValue] = useState<ForexQuote | undefined>(undefined);
	const [errors, setErrors] = useState<MappedReasons | undefined>(undefined);
	const [showOutOfHours, setShowOutOfHours] = useState(false);

	const shouldReconnect = useCallback(
		() => !showOutOfHours && isActiveTab,
		[showOutOfHours, isActiveTab],
	);

	const handleClear = useCallback(() => {
		setValue(undefined);
		setErrors(undefined);
		setShowOutOfHours(false);
	}, []);

	const { lastJsonMessage, readyState, sendMessage } = useWebSocket(
		getForexQuoteApi(clientId),
		{
			onClose(event) {
				console.log("Websocket closed", event);
			},
			onOpen(event) {
				console.log("Websocket opened", event);
			},
			onError: (event) => {
				console.log("Websocket error", event);
			},
			shouldReconnect: () => isActiveTab,
			retryOnError: false,
			reconnectInterval: 1000,
			reconnectAttempts: 5,
			queryParams,
		},
		isEnabled && !!queryParams,
	);

	const handleError = useCallback((lastJsonMessage: any) => {
		// Currently out of hours. Modal will be shown on the quote page
		if (
			lastJsonMessage.error_code === ForexQuoteErrorCodes.SystemStatusOffline
		) {
			setShowOutOfHours(true);
			return;
		}

		// Field level validation errors
		if (lastJsonMessage.error_code === ForexQuoteErrorCodes.Validation) {
			const errorsObject: WebsocketErrorResponse = JSON.parse(
				lastJsonMessage.error,
			);
			setErrors(mapErrors(errorsObject));
			return;
		}

		handleGeneralError(lastJsonMessage);
	}, []);

	// Refresh the websocket connection if it is about to expire and we are still active
	useEffect(() => {
		if (
			lastJsonMessage?.refresh_message === REFRESH_MESSAGE &&
			shouldReconnect()
		) {
			console.log("Triggering refresh of websocket connection");
			sendMessage(REFRESH_MESSAGE);
		}
	}, [lastJsonMessage, sendMessage, shouldReconnect]);

	// Parse the incoming message
	useEffect(() => {
		if (readyState === ReadyState.OPEN && lastJsonMessage) {
			if (lastJsonMessage.error) {
				handleError(lastJsonMessage);
				return;
			}

			if (isValidForexQuoteResponse(lastJsonMessage)) {
				setErrors(undefined);
				setShowOutOfHours(false);
				setValue(mapToGetForexQuote(lastJsonMessage));
			}
		}
	}, [lastJsonMessage, readyState, handleError]);

	// Sync the websocket url with the params from the quote page
	useEffect(() => {
		if (
			params?.transactionType &&
			params?.fxCurrency &&
			params?.settlementAccounts
		) {
			const value =
				params.fxCurrency === params.quoteCurrency && params.fxAmount
					? params.fxAmount
					: params.quoteAmount;
			const quoteAmountString = value
				? value.replace(/,/g, "").toString()
				: "0";

			const formattedParams = {
				fx_currency: params.fxCurrency || "",
				quote_amount: Number.parseFloat(quoteAmountString || "").toFixed(2),
				quote_currency: params.quoteCurrency,
				transaction_type: params.transactionType,
				settlement_account_id: params.settlementAccounts.bankAccountId,
			};
			setQueryParams(formattedParams);
		}
	}, [
		params?.fxCurrency,
		params?.quoteAmount,
		params?.quoteCurrency,
		params?.fxAmount,
		params?.transactionType,
		params?.settlementAccounts,
	]);

	return {
		value,
		mappedReasons: errors,
		showOutOfHours,
		clear: handleClear,
	};
};
