import { FieldError } from "@app/components/field-error";
import type { MappedReasons } from "@app/services";

export const showMappedReasons = (
	fieldName: string,
	mappedReasons?: MappedReasons,
) => {
	const filteredMappedReasons = (
		mappedReasons ? mappedReasons[fieldName] || [] : []
	).filter((x) => !!x);

	if (filteredMappedReasons.length === 0) return false;

	return (
		<>
			{filteredMappedReasons.map((message, index) => (
				<FieldError key={index}>{message}</FieldError>
			))}
		</>
	);
};
