import { Button } from "@app/components/button";
import {
	DatePicker,
	MultiSelect,
	MultiSelectCurrency,
} from "@app/components/controls";
import { Spacer } from "@app/components/spacer";
import { Typography } from "@app/components/typography";
import { useEffect, useState } from "react";
import { FiArrowDown, FiArrowUp, FiChevronDown, FiX } from "react-icons/fi";

import type {
	CurrencyOption,
	FilterRecipientsByForm,
	ListTransactionInProgress,
	TransactionDirection,
} from "@app/entities";
import type { TransactionsQueryParams } from "@app/services";
import type { Control } from "react-hook-form";

import { MappedCurrency } from "@app/hooks/use-currencies";
import { RecipientNames } from "@app/hooks/use-recipient-names";
import type {
	TransactionTableModel,
	TransactionsFilterState,
} from "../models/models";

import { Dialog } from "@app/components/dialog";
import styles from "./filter-section.module.css";
import "./filter-section.css";
import { toDayjs } from "@app/lib/date";
import clsx from "clsx";

export const FilterSection = (props: {
	control: Control<FilterRecipientsByForm, any>;
	currencies?: CurrencyOption[];
	currenciesOverflowing: boolean;
	currentPage?: number;
	errors?: string[];
	filterGroupSize: number;
	filterState: TransactionsFilterState;
	listCompletedTransactions?: TransactionTableModel[];
	listInProgressTransactions?: ListTransactionInProgress[];
	inProgressTotal?: number;
	loading?: boolean;
	loadingTableData?: boolean;
	recipientsOverflowing: boolean;
	showFilters: boolean;
	state: TransactionsQueryParams;
	total?: number;
	totalFilters: number;
	onApplyFilters: () => void;
	onChangeCurrencyFilters: (value: MappedCurrency[]) => void;
	onChangeDirectionFilter: (value?: TransactionDirection[]) => void;
	onChangeEndDateFilter: (value: Date) => void;
	onChangeRecipientFilters: (value: RecipientNames[]) => void;
	onChangeStartDateFilter: (value: Date) => void;
	onClearFilters: () => void;
	onCurrenciesOverflow: (overflow: boolean) => void;
	onFilterByName: (value: string) => void;
	onPageChange: (page: number, rowsPerPage: number) => void;
	onRecipientsOverflow: (overflow: boolean) => void;
	onRemoveCurrencyFilter: (value: MappedCurrency) => void;
	onRemoveCurrencyFilterTag: (value: MappedCurrency) => void;
	onRemoveCurrencyFiltersTag: () => void;
	onRemoveDateFiltersTag: () => void;
	onRemoveDirectionFilterTag: (value: TransactionDirection) => void;
	onRemoveDirectionFiltersTag: (value?: TransactionDirection[]) => void;
	onRemoveRecipientFilter: (value: RecipientNames) => void;
	onRemoveRecipientFilterTag: (value: RecipientNames) => void;
	onRemoveRecipientFiltersTag: () => void;
	onSortByName: (sortOrder: number, columnKey: string) => void;
	onToggleShowFilters: () => void;
}) => {
	const [typeModalOpen, setTypeModalOpen] = useState(false);
	const [dateModalOpen, setDateModalOpen] = useState(false);
	const [directionSelected, setDirectionSelected] =
		useState<TransactionDirection>();

	useEffect(() => {
		if (props.filterState) {
			props.onApplyFilters();
		}
	}, [
		JSON.stringify(props.filterState.currentRecipientNamesSelected),
		JSON.stringify(props.filterState.currentDirectionsSelected),
		JSON.stringify(props.filterState.currentCurrenciesSelected),
	]);

	useEffect(() => {
		if (props.filterState.appliedDirectionsSelected) {
			setDirectionSelected(props.filterState.appliedDirectionsSelected[0]);
		}
	}, props.filterState.appliedDirectionsSelected);

	const handleCloseTypeModal = () => setTypeModalOpen(false);
	const handleCloseDateModal = () => setDateModalOpen(false);

	const handleSaveTypeModal = () => {
		props.onChangeDirectionFilter([directionSelected as TransactionDirection]);
		setTypeModalOpen(false);
	};
	const handleSaveDateModal = () => {
		props.onApplyFilters();
		setDateModalOpen(false);
	};

	const getFormattedDate = (date?: Date): string => {
		if (!date) {
			return "";
		}
		const dDate = toDayjs(date);
		return `${dDate.format("DD MMM YYYY")}`;
	};

	const direction = props.filterState.currentDirectionsSelected;
	const selectedDirection = props.filterState.appliedDirectionsSelected;

	return (
		<>
			<div className="filter-section-responsive">
				<MultiSelect
					headingText="Filter by recipient"
					hideOutlineContent
					className={` border-gray-110 ${
						props.filterState.currentRecipientNamesSelected ? "selected" : ""
					}`}
					optionLabel="name"
					options={props.filterState.recipientNameOptions || []}
					placeholder="Recipient"
					itemTemplate={(option) => (
						<Typography theme="textMd">{option.name} </Typography>
					)}
					value={props.filterState.currentRecipientNamesSelected}
					valueTemplate={(
						option: { id: string; name: string },
						index: number,
						onClick?: () => void,
					) => {
						return (
							<div
								className="item-wrapper border-gray-110"
								onClick={onClick}
								key={index}
							>
								<div className={styles.nameWrapper}>{option.name}</div>
								<button
									type="button"
									onClick={(e) => {
										e.preventDefault();
										e.stopPropagation();
										props.onRemoveRecipientFilterTag({
											id: Number.parseInt(option.id),
											name: option.name,
										});
									}}
								>
									<FiX size={14} color="#b0b0b0" />
								</button>
							</div>
						);
					}}
					virtualized
					onChange={props.onChangeRecipientFilters}
					onOverflow={props.onRecipientsOverflow}
				/>

				{/* TYPE selection */}
				<div
					className={`type border-gray-110 ${
						selectedDirection && selectedDirection.length > 0 ? "selected" : ""
					}`}
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
						setTypeModalOpen(true);
					}}
				>
					{selectedDirection && selectedDirection.length > 0 && (
						<>
							{selectedDirection[0] === "send" && (
								<div className={clsx(styles.iconWrapper, styles.send)}>
									<FiArrowDown
										className="down-arrow"
										name="FiArrowDown"
										size={18}
									/>
								</div>
							)}

							{selectedDirection[0] === "receive" && (
								<div className={clsx(styles.iconWrapper, styles.receive)}>
									<FiArrowUp className="up-arrow" size={18} />
								</div>
							)}
							{["send", "receive"].includes(selectedDirection[0]) && (
								<Spacer width="0.5rem" />
							)}
							<div className="capitalize">{selectedDirection}</div>
							<Spacer width="0.5rem" />
							<div
								onClick={(e) => {
									e.stopPropagation();
									e.preventDefault();
									props.onRemoveDirectionFilterTag(
										selectedDirection[0] as TransactionDirection,
									);
								}}
							>
								<FiX className="mr-1" size={14} color="#b0b0b0" />
							</div>
						</>
					)}

					{(!selectedDirection ||
						(selectedDirection && selectedDirection.length === 0)) && (
						<div className="type-default">
							<div>Type</div>
							<Spacer width="0.5rem" />
							<FiChevronDown size={20} color="#b0b0b0" />
						</div>
					)}
				</div>

				{/* Multi currency selection */}
				<MultiSelectCurrency
					{...props}
					value={props.filterState.appliedCurrenciesSelected}
					placeholder="Currency"
					hideOutlineContent
					onRemoveSelectedItem={(currency) => {
						props.onRemoveCurrencyFilterTag(currency);
					}}
					onChange={props.onChangeCurrencyFilters}
				/>

				<div
					className={`date-filter ${
						props.filterState.appliedStartDate ||
						props.filterState.appliedEndDate
							? "selected"
							: ""
					}`}
				>
					{!(
						props.filterState.appliedStartDate ||
						props.filterState.appliedEndDate
					) && (
						<div
							className="flex flex-row items-center border-gray-110"
							onClick={() => setDateModalOpen(true)}
						>
							<div>Date</div>
							<Spacer width="0.5rem" />
							<FiChevronDown size={20} color="#b0b0b0" />
						</div>
					)}

					{(props.filterState.appliedStartDate ||
						props.filterState.appliedEndDate) && (
						<div
							className="flex flex-row items-center whitespace-nowrap text-nowrap"
							onClick={(e) => {
								e.stopPropagation();
								e.preventDefault();
								setDateModalOpen(true);
							}}
						>
							{props.filterState.appliedStartDate &&
							props.filterState.appliedEndDate
								? `${getFormattedDate(
										props.filterState.appliedStartDate ?? undefined,
									)}  - ${getFormattedDate(
										props.filterState.appliedEndDate ?? undefined,
									)}`
								: props.filterState.appliedStartDate
									? `From ${getFormattedDate(
											props.filterState.appliedStartDate,
										)}`
									: `Up to ${getFormattedDate(
											props.filterState.appliedEndDate,
										)}`}

							<Spacer width="0.5rem" />
							<div
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									props.onRemoveDateFiltersTag();
								}}
							>
								<FiX className="mr-1" size={14} color="#b0b0b0" />
							</div>
						</div>
					)}
				</div>
			</div>

			{/* type modal */}
			<Dialog
				fullscreen
				isOpen={typeModalOpen}
				onClose={() => setTypeModalOpen(false)}
				title="Filter by type"
				actions={
					<>
						<Button variant="secondary" onClick={handleCloseTypeModal}>
							Cancel
						</Button>
						<Button onClick={handleSaveTypeModal}>Apply</Button>
					</>
				}
			>
				<div className={styles.typeOptions}>
					<button
						type="button"
						className={styles.typeButton}
						data-active={direction && directionSelected === "both"}
						onClick={() => setDirectionSelected("both")}
					>
						Both
					</button>
					<button
						type="button"
						className={styles.typeButton}
						data-active={direction && directionSelected === "receive"}
						onClick={() => {
							setDirectionSelected("receive");
						}}
					>
						<div className={styles.typeIcon} data-type="receive">
							<FiArrowUp className="up-arrow" size={24} />
						</div>
						Receive
					</button>
					<button
						type="button"
						className={styles.typeButton}
						data-active={direction && directionSelected === "send"}
						onClick={() => setDirectionSelected("send")}
					>
						<div className={styles.typeIcon} data-type="send">
							<FiArrowDown className="down-arrow" size={24} />
						</div>
						Send
					</button>
				</div>
			</Dialog>

			{/* Date selection modal */}
			<Dialog
				fullscreen
				isOpen={dateModalOpen}
				onClose={() => setDateModalOpen(false)}
				title="Filter by date"
				actions={
					<>
						<Button variant="secondary" onClick={handleCloseDateModal}>
							Cancel
						</Button>
						<Button onClick={handleSaveDateModal}>Apply</Button>
					</>
				}
			>
				<div className={styles.dateFilterItems}>
					<div>
						<div className="flex flex-row items-center border-gray-110">
							<Typography theme="textSm" className="font-semibold">
								Start Date
							</Typography>
						</div>

						<DatePicker
							className="date-picker w-full"
							placeholder="Select a date"
							maxDate={props.filterState.currentEndDate}
							value={
								props.filterState.currentStartDate
									? [props.filterState.currentStartDate]
									: []
							}
							onChange={(value) => props.onChangeStartDateFilter(value[0])}
							showChevron
						/>
					</div>

					<div>
						<div className="flex flex-row items-center border-gray-110">
							<Typography theme="textSm" className="font-semibold">
								End Date
							</Typography>
						</div>

						<DatePicker
							className="date-picker w-full"
							placeholder="Select a date"
							minDate={props.filterState.currentStartDate}
							value={
								props.filterState.currentEndDate
									? [props.filterState.currentEndDate]
									: []
							}
							onChange={(value) => props.onChangeEndDateFilter(value[0])}
							showChevron
						/>
					</div>
				</div>
			</Dialog>
		</>
	);
};
