import type { GetPaymentBopDocs } from "@app/entities";
import { useMediaQuery } from "@app/hooks/use-media-query";

import { Button } from "@app/components/button";
import { IconButton } from "@app/components/icon-button";

import additionalDocumentSrc from "./additional-document.svg";
import uploadedDocumentSrc from "./uploaded-document.svg";
import uploadDocumentSrc from "./upload-document.svg";

import { formatFileSize } from "@app/utils";
import { FiPlus, FiTrash2 } from "react-icons/fi";

import { FieldError } from "@app/components/field-error";
import { SupportingDocument } from "@app/hooks/use-supporting-documents";
import styles from "./index.module.css";
import { MoreInfoTooltip } from "@app/components/more-info-tooltip";
import { TextTruncate } from "@app/components/text-truncate";
import clsx from "clsx";

const ADDITIONAL_DOCUMENTS_TYPE_ID = 1;

export const DocumentTypeContent = ({
	document,
	showValidation,
	paymentSupportingDocs,
	onShowUploadModal,
	onDownloadDocument,
	onDeleteDocument,
	allDocumentTypeIds,
	tooltip,
}: {
	tooltip?: string;
	document: GetPaymentBopDocs;
	showValidation: boolean;
	paymentSupportingDocs?: SupportingDocument[] | null;
	onShowUploadModal: (
		document: GetPaymentBopDocs,
		candidates?: SupportingDocument["documents"],
	) => void;
	onDownloadDocument: (doc: SupportingDocument["documents"][number]) => void;
	onDeleteDocument: (id?: string | number) => void;
	allDocumentTypeIds: number[];
}) => {
	const isMobile = useMediaQuery();

	const supportingDocuments = paymentSupportingDocs
		?.filter((current) => {
			const isMatchingType =
				current.document_type_id === document.documentTypeId;
			const isUngroupedDoc =
				document.documentTypeId === ADDITIONAL_DOCUMENTS_TYPE_ID &&
				!allDocumentTypeIds.includes(current.document_type_id);

			return isMatchingType || isUngroupedDoc;
		})
		.flatMap((current) => current.documents);

	const isEmpty = !supportingDocuments || supportingDocuments.length === 0;
	const isInError = document.required && showValidation && isEmpty;

	const iconSrc = isEmpty
		? document.required
			? uploadDocumentSrc
			: additionalDocumentSrc
		: uploadedDocumentSrc;

	const isEmptyWithoutTooltip = isEmpty && !tooltip;

	return (
		<>
			<div
				className={styles.container}
				data-error={isInError}
				data-empty={isEmpty}
			>
				<div
					className={styles.wrapper}
					data-is-empty-without-tooltip={isEmptyWithoutTooltip}
				>
					<img
						className={styles.icon}
						src={iconSrc}
						width={24}
						height={24}
						alt=""
					/>
					<div className={styles.content}>
						<div className={styles.titleContainer}>
							<div className={styles.titleWrapper} data-empty={isEmpty}>
								<p className={styles.title}>
									{document?.required
										? `${document?.documentName}*`
										: `${document?.documentName} (optional)`}
								</p>
								{tooltip && (
									<>
										{isEmpty ? (
											<p className={styles.tooltipDescription}>{tooltip}</p>
										) : (
											<MoreInfoTooltip
												maxWidth={200}
												name={document.documentName}
											>
												{tooltip}
											</MoreInfoTooltip>
										)}
									</>
								)}
							</div>
							{isMobile && (
								<IconButton
									variant="primary"
									className={clsx(isEmpty && styles.addButton)}
									onClick={() =>
										onShowUploadModal(document, supportingDocuments)
									}
								>
									<FiPlus size={24} color="inherit" />
								</IconButton>
							)}
						</div>

						{supportingDocuments && supportingDocuments?.length > 0 && (
							<ul className={styles.list}>
								{supportingDocuments.map((x) => (
									<li key={x.id} className={styles.listItem}>
										<div className={styles.itemWrapper}>
											{isMobile && (
												<p className={styles.documentName}>
													<TextTruncate text={x.document_name} maxWidth={280} />
												</p>
											)}
											<div className={styles.item}>
												{!isMobile && (
													<>
														<p>
															<TextTruncate
																text={x.document_name}
																maxWidth={224}
															/>
														</p>
														<div className={styles.inlineDivider} />
													</>
												)}
												<p className={styles.fileSize}>
													{formatFileSize(
														x.document_size
															? Number.parseFloat(x.document_size)
															: 0,
													)}
												</p>
												<div className={styles.inlineDivider} />
												<Button
													noPadding
													variant="tertiary"
													className={styles.inlineButton}
													inline
													onClick={() => {
														if (onDownloadDocument) onDownloadDocument(x);
													}}
												>
													Download
												</Button>

												{!isMobile && (
													<>
														<div className={styles.inlineDivider} />
														<Button
															noPadding
															variant="tertiary"
															inline
															className={styles.inlineButton}
															onClick={() => {
																if (onDeleteDocument) onDeleteDocument(x.id);
															}}
														>
															Remove
														</Button>
													</>
												)}
											</div>
										</div>
										{isMobile && (
											<IconButton>
												<FiTrash2
													size={24}
													color="#56A7A2"
													onClick={() => {
														if (onDeleteDocument) onDeleteDocument(x.id);
													}}
												/>
											</IconButton>
										)}
									</li>
								))}
							</ul>
						)}
					</div>
				</div>
				{!isMobile && (
					<Button
						variant={isEmpty ? "primary" : "secondary"}
						inline
						onClick={() => onShowUploadModal(document, supportingDocuments)}
					>
						Add file(s)
					</Button>
				)}
			</div>
			{isInError && <FieldError>This document is required</FieldError>}
		</>
	);
};
