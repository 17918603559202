import { Button } from "@app/components/button";
import { Dialog } from "@app/components/dialog";
import { useMediaQuery } from "@app/hooks/use-media-query";

import styles from "./index.module.css";

import { ApiErrors } from "@app/components/api-errors";
import { useClients } from "@app/hooks/use-clients";
import { api } from "@app/services";
import clsx from "clsx";
import { useState } from "react";
import checkSrc from "./check.svg";

export const AccountOpenedModal = ({
	onComplete,
}: { onComplete: () => void }) => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const { activeClientId } = useClients();
	const [showError, setShowError] = useState(false);
	const isMobile = useMediaQuery();

	const handleContinue = async () => {
		try {
			setIsSubmitting(true);
			await api.post(`/onboarding/${activeClientId}/confirm-account-opened/`);
			onComplete();
		} catch (error) {
			console.error(error);
			setShowError(true);
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<Dialog
			size="xl"
			isOpen
			actions={
				<Button
					centered
					disabled={!activeClientId || isSubmitting}
					onClick={handleContinue}
				>
					Continue
				</Button>
			}
		>
			<div className={styles.content}>
				<img
					className={styles.graphic}
					src={checkSrc}
					alt=""
					height={80}
					width={80}
				/>
				<h1 className={styles.title}>Account opened!</h1>
				<p className={clsx(styles.description, styles.firstDescription)}>
					{isMobile ? (
						"Congratulations, your account has been opened and you can begin transacting."
					) : (
						<>
							Congratulations, your account has been opened
							<br />
							and you can begin transacting.
						</>
					)}
				</p>
				<p className={styles.description}>
					If you need any help, please reach out to your Account Manager.
				</p>
				{showError && (
					<ApiErrors
						className={styles.error}
						errors={["An unknown error occurred. Please try again."]}
					/>
				)}
			</div>
		</Dialog>
	);
};
