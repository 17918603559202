import { Button } from "@app/components/button";
import { MobileHeader } from "@app/components/mobile-header";
import { TopBar } from "@app/components/top-bar";
import { UserSettingsContent } from "@app/features/user-settings/user-settings-content";
import { useMediaQuery } from "@app/hooks/use-media-query";
import { useNavigate } from "react-router-dom";

import logoSrc from "./logo.svg";

import styles from "./index.module.css";

export const OnboardingUserSettings = () => {
	const isMobile = useMediaQuery();
	const navigate = useNavigate();
	return (
		<div className={styles.container}>
			{isMobile ? (
				<MobileHeader>Settings</MobileHeader>
			) : (
				<TopBar
					leftSection={
						<img src={logoSrc} alt="Future Forex" height={18} width={180} />
					}
					variant="onboarding"
					isSettings
				/>
			)}
			<UserSettingsContent variant="onboarding" />
			<footer className={styles.footer}>
				<Button onClick={() => navigate(-1)}>Back to onboarding</Button>
			</footer>
		</div>
	);
};
