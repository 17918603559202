import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Button } from "@app/components/button";
import { Title } from "@app/components/title";
import { paths } from "@app/constants/paths";
import { useShowToast } from "@app/helpers/toast";
import type { RootState } from "@app/redux";

import { ApiErrors } from "@app/components/api-errors";
import { ResendEmail } from "../resend-email";
import styles from "./index.module.css";
import { STORAGE_KEYS } from "@app/constants/storage-keys";
import { links } from "@app/constants/links";
import { useAuth } from "@app/hooks/use-auth";

const NotVerified = ({
	variant = "default",
}: {
	variant?: "arbitrage" | "default";
}) => {
	const [apiErrors, setApiErrors] = useState<string[]>([]);
	const navigate = useNavigate();
	const { onArbitrageResendActivate, onResendConfirmationEmail } = useAuth();
	const [showToast] = useShowToast();

	const { unverifiedEmail } = useSelector(
		(rootState: RootState) => rootState.auth,
	);

	const email =
		window.localStorage.getItem(STORAGE_KEYS.registerEmail) ?? unverifiedEmail;

	const handleLogin = () => navigate(paths().login);

	const handleResend = async () => {
		if (!email) {
			showToast("No email found", "error");
			navigate(paths().login);
			return Promise.resolve(false);
		}

		if (variant === "arbitrage") {
			const errors = await onArbitrageResendActivate(email);
			if (errors) {
				setApiErrors(errors.apiErrors);
				return false;
			}
			return true;
		}

		const errors = await onResendConfirmationEmail(email);
		if (errors) {
			setApiErrors(errors.apiErrors);
			return false;
		}
		return true;
	};

	const handleRegisterAgain = () => {
		if (variant === "arbitrage") {
			window.location.href = links.arbitrage.register;
		} else {
			navigate(paths().register);
		}
	};

	return (
		<>
			<Title>Account not yet verified</Title>
			<ApiErrors errors={apiErrors} />
			<div className={styles.description}>
				<p>Your email address has not been verified yet.</p>

				{variant === "arbitrage" ? (
					<p>
						We previously sent an email to <strong>{email}</strong> to verify
						your account.
					</p>
				) : (
					<p>
						We have sent an email to <strong>{email}</strong> to verify your
						account.
					</p>
				)}
			</div>
			<Button inline centered onClick={handleLogin}>
				I’ve verified my email
			</Button>
			<div className={styles.divider} />
			<p className={styles.notReceivedYet}>Haven't received an email yet? </p>
			<ResendEmail
				email={email}
				variant="verify-email"
				onResend={handleResend}
			/>
			<p className={styles.wrongEmail}>
				Wrong email?{" "}
				<button
					className={styles.registerAgain}
					type="button"
					onClick={handleRegisterAgain}
				>
					Register again
				</button>
			</p>
		</>
	);
};

export default NotVerified;
