import { Button } from "@app/components/button";
import { IconButton } from "@app/components/icon-button";
import { useMediaQuery } from "@app/hooks/use-media-query";
import styles from "./index.module.css";

import EditIcon from "@app/icons/edit.svg?react";
import TrashIcon from "@app/icons/trash.svg?react";

export const ViewRecipientActions = ({
	onEditRecipient,
	onRemoveRecipient,
	onSendFunds,
}: {
	onEditRecipient: () => void;
	onRemoveRecipient: () => void;
	onSendFunds: () => void;
}) => {
	const isMobile = useMediaQuery();
	return (
		<div className={styles.actions}>
			{isMobile ? (
				<>
					<IconButton onClick={onEditRecipient}>
						<EditIcon width={24} height={24} />
					</IconButton>
					<IconButton onClick={onRemoveRecipient}>
						<TrashIcon width={24} height={24} />
					</IconButton>
				</>
			) : (
				<>
					<Button onClick={onSendFunds}>Send funds</Button>
					<Button onClick={onEditRecipient} variant="icon">
						<EditIcon width={20} height={20} />
						Edit
					</Button>
					<IconButton onClick={onRemoveRecipient}>
						<TrashIcon width={20} height={20} />
					</IconButton>
				</>
			)}
		</div>
	);
};
