import * as Popover from "@radix-ui/react-popover";

import {
	ElementRef,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { FiX } from "react-icons/fi";
import styles from "./index.module.css";

import { Button } from "@app/components/button";
import { Checkbox } from "@app/components/checkbox";
import { CountryIcon } from "@app/components/country-icon";
import { Dialog } from "@app/components/dialog";
import { Search } from "@app/components/search";
import { useMediaQuery } from "@app/hooks/use-media-query";
import clsx from "clsx";
import chevronUpSrc from "./chevron-up.svg";
import AnimateHeight from "react-animate-height";

export interface Option {
	name: string;
	id: number | string;
	countryCode?: string;
}

const POPOVER_CONTENT_HEIGHT = 260;

const getEstimatedTagWidth = (name: string, defaultTagWidth = 46) => {
	const charWidth = 7;
	return charWidth * name.length + defaultTagWidth;
};

const Tag = ({
	option,
	onRemove,
	className,
}: { option: Option; onRemove: () => void; className?: string }) => (
	<div className={clsx(styles.tag, className)}>
		{option.countryCode ? (
			<>
				<CountryIcon
					currencyCode={option.id.toString()}
					width={20}
					height={20}
				/>
				<p>{option.id}</p>
			</>
		) : (
			<p>{option.name}</p>
		)}

		<button
			aria-label="Remove"
			type="button"
			onClick={(event) => {
				event.stopPropagation();
				onRemove();
			}}
		>
			<FiX size={14} color="#b0b0b0" />
		</button>
	</div>
);

const OptionValue = ({
	isChecked,
	option,
}: {
	isChecked: boolean;
	option: Option;
}) => {
	return (
		<>
			<Checkbox value={isChecked} />
			{option.countryCode && (
				<CountryIcon
					currencyCode={option.id.toString()}
					width={20}
					height={20}
				/>
			)}
			{option.countryCode ? (
				<p>
					{option.id} - {option.name}
				</p>
			) : (
				<p>{option.name}</p>
			)}
		</>
	);
};

export const MultiSelectNew = ({
	value,
	options,
	onChange,
	placeholder,
	error,
	searchText = "Search",
	mobileHeading,
	tagsClassName,
}: {
	onChange: (value: Array<Option>) => void;
	options: Array<Option>;
	value?: Array<Option>;
	placeholder?: string;
	searchText?: string;
	error?: boolean;
	mobileHeading: string;
	tagsClassName?: string;
}) => {
	const isMobile = useMediaQuery();
	const triggerRef = useRef<ElementRef<"button">>(null);
	const [search, setSearch] = useState("");
	const [isOpen, setIsOpen] = useState(false);
	const [selectedOptions, setSelectedOptions] = useState<Array<Option>>([]);
	const [popoverSide, setPopoverSide] = useState<"top" | "bottom">("bottom");

	const filteredOptions = options.filter((option) => {
		return option.name.toLowerCase().includes(search.toLowerCase());
	});

	const updatePopoverSide = useCallback(() => {
		if (triggerRef.current) {
			const { bottom } = triggerRef.current.getBoundingClientRect();
			const viewportHeight = window.innerHeight;
			const spaceBelow = viewportHeight - bottom;
			setPopoverSide(spaceBelow < POPOVER_CONTENT_HEIGHT ? "top" : "bottom");
		}
	}, []);

	const handleOpenChange = (open: boolean) => {
		if (open) {
			updatePopoverSide();
		}
		setIsOpen(open);
	};

	const overflowIndex = useMemo(() => {
		if (!value || value?.length <= 1) return undefined;
		const clientWidth = triggerRef.current?.clientWidth ?? 0;
		const contentWidth = clientWidth - 60;
		let totalTagWidth = 0;
		for (let i = 0; i < value.length; i++) {
			totalTagWidth += getEstimatedTagWidth(
				value[i].name,
				value[i].countryCode ? 32 : 48,
			);
			if (totalTagWidth > contentWidth) {
				return i;
			}
		}
	}, [value]);

	useEffect(() => {
		if (isOpen) {
			setSelectedOptions(value ?? []);
		}
	}, [isOpen, value]);

	if (isMobile) {
		return (
			<>
				<button
					className={styles.trigger}
					type="button"
					data-error={error}
					data-open={isOpen}
					ref={triggerRef}
					onClick={() => setIsOpen(true)}
				>
					<div className={clsx(styles.tags, tagsClassName)}>
						{value && value.length > 0 ? (
							<>
								{(overflowIndex ? value.slice(0, overflowIndex) : value).map(
									(current) => (
										<Tag
											key={current.id}
											option={current}
											onRemove={() => {
												onChange(
													value.filter((option) => option.id !== current.id),
												);
											}}
										/>
									),
								)}
								{overflowIndex !== undefined && (
									<span>+{value.length - overflowIndex}</span>
								)}
							</>
						) : (
							<span className={styles.placeholder}>{placeholder}</span>
						)}
					</div>
					<img
						data-open={isOpen}
						className={styles.chevron}
						src={chevronUpSrc}
						alt=""
						width={20}
						height={20}
					/>
				</button>
				<Dialog
					fullscreen
					isOpen={isOpen}
					zIndex={102}
					onClose={() => setIsOpen(false)}
					title={mobileHeading}
					actions={
						<>
							<Button
								variant="secondary"
								onClick={() => {
									setSelectedOptions([]);
									onChange([]);
									setIsOpen(false);
								}}
							>
								Clear all
							</Button>
							<Button
								onClick={() => {
									onChange(selectedOptions);
									setIsOpen(false);
								}}
							>
								Apply
							</Button>
						</>
					}
				>
					<AnimateHeight
						duration={300}
						height={selectedOptions.length > 0 ? "auto" : 0}
					>
						<div className={styles.selectedTags}>
							{selectedOptions.map((current) => (
								<Tag
									className={styles.selectedTag}
									key={current.id}
									option={current}
									onRemove={() => {
										setSelectedOptions(
											selectedOptions.filter(
												(selected) => selected.id !== current.id,
											),
										);
									}}
								/>
							))}
						</div>
					</AnimateHeight>
					<Search
						value={search}
						onChange={(event) => setSearch(event.target.value)}
						placeholder={searchText}
					/>
					<div className={styles.modalList}>
						{filteredOptions.map((option) => {
							const isChecked = !!selectedOptions.find(
								(selected) => selected.id === option.id,
							);
							return (
								<button
									type="button"
									className={styles.modalItem}
									aria-current={isChecked}
									key={option.id}
									onClick={() => {
										if (
											selectedOptions.find(
												(selected) => selected.id === option.id,
											)
										) {
											setSelectedOptions(
												selectedOptions.filter(
													(selected) => selected.id !== option.id,
												),
											);
										} else {
											setSelectedOptions([...selectedOptions, option]);
										}
									}}
								>
									<OptionValue isChecked={isChecked} option={option} />
								</button>
							);
						})}
					</div>
				</Dialog>
			</>
		);
	}

	return (
		<Popover.Root open={isOpen} onOpenChange={handleOpenChange}>
			<Popover.Anchor>
				<Popover.Trigger
					ref={triggerRef}
					className={styles.trigger}
					type="button"
					data-error={error}
					data-open={isOpen}
				>
					<div className={clsx(styles.tags, tagsClassName)}>
						{value && value.length > 0 ? (
							<>
								{(overflowIndex ? value.slice(0, overflowIndex) : value).map(
									(current) => (
										<Tag
											key={current.id}
											option={current}
											onRemove={() => {
												onChange(
													value.filter((option) => option.id !== current.id),
												);
											}}
										/>
									),
								)}
								{overflowIndex !== undefined && (
									<span>+{value.length - overflowIndex}</span>
								)}
							</>
						) : (
							<span className={styles.placeholder}>{placeholder}</span>
						)}
					</div>

					<img
						data-open={isOpen}
						className={styles.chevron}
						src={chevronUpSrc}
						alt=""
						width={20}
						height={20}
					/>
				</Popover.Trigger>
			</Popover.Anchor>
			<Popover.Content
				className={styles.content}
				avoidCollisions={false}
				side={popoverSide}
				onOpenAutoFocus={(event) => {
					event.preventDefault();
					updatePopoverSide();
				}}
			>
				<Search
					className={styles.search}
					value={search}
					autoFocus
					onChange={(event) => setSearch(event.target.value)}
					onClear={() => setSearch("")}
					placeholder={searchText}
				/>
				<ul className={styles.list}>
					{filteredOptions.map((current) => (
						<li key={current.id}>
							<button
								type="button"
								className={styles.item}
								onClick={() => {
									if (value?.find((value) => value.id === current.id)) {
										onChange(value.filter((value) => value.id !== current.id));
									} else {
										onChange([...(value || []), current]);
									}
								}}
							>
								<OptionValue
									isChecked={!!value?.find((value) => value.id === current.id)}
									option={current}
								/>
							</button>
						</li>
					))}
				</ul>
			</Popover.Content>
		</Popover.Root>
	);
};
