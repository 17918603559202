import { ApiErrors } from "@app/components/api-errors";
import { Button } from "@app/components/button";
import { Dialog } from "@app/components/dialog";
import { Label } from "@app/components/label";
import { PasswordToggleButton } from "@app/features/auth/password-toggle-button";
import { TextField } from "@app/features/auth/text-field";
import { useMediaQuery } from "@app/hooks/use-media-query";
import { FormErrors } from "@app/utils/get-form-errors";
import { useState } from "react";
import { useForm } from "react-hook-form";

export const ChangeOtpPasswordModal = ({
	onClose,
	onConfirm,
	title = "Disable OTP",
	description,
}: {
	onClose: () => void;
	// biome-ignore lint: Need to support async and sync callbacks
	onConfirm: (password: string) => void | Promise<FormErrors | void>;
	title?: string;
	description?: string;
}) => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [apiErrors, setApiErrors] = useState<Array<string>>([]);
	const isMobile = useMediaQuery();
	const [showPassword, setShowPassword] = useState(false);

	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm<{
		password: string;
	}>({
		defaultValues: {
			password: "",
		},
		mode: "onBlur",
	});

	return (
		<Dialog
			isOpen
			fullscreen={isMobile}
			showTopbar={isMobile}
			title={title}
			onClose={onClose}
			description={description}
			actions={
				<>
					<Button
						minWidth={110}
						disabled={isSubmitting}
						variant="secondary"
						onClick={onClose}
					>
						Cancel
					</Button>
					<Button
						minWidth={110}
						disabled={isSubmitting}
						form="change-otp-password-modal"
						type="submit"
					>
						Continue
					</Button>
				</>
			}
		>
			<form
				id="change-otp-password-modal"
				onSubmit={handleSubmit(async (data) => {
					setIsSubmitting(true);
					const errors = await onConfirm(data.password);
					setIsSubmitting(false);
					if (errors) {
						const passwordFieldError = errors.fieldErrors.find(
							(error) => error.name === "password",
						);
						setError("password", {
							message: passwordFieldError?.message ?? "",
						});
						setApiErrors(errors.apiErrors);
					}
				})}
				noValidate
			>
				<Label htmlFor="password">Password*</Label>
				<TextField
					register={register}
					name="password"
					label="Password"
					type={showPassword ? "text" : "password"}
					placeholder="Enter password"
					required
					rightSection={
						<PasswordToggleButton
							value={showPassword}
							onChange={setShowPassword}
						/>
					}
					error={errors.password}
				/>
				<ApiErrors errors={apiErrors} />
			</form>
		</Dialog>
	);
};
