import { useDownloadPaymentSupportingDocs } from "@app/helpers";
import { downloadFile } from "@app/utils";

import { useBopDocuments } from "@app/hooks/use-bop-documents";
import {
	SupportingDocument,
	useSupportingDocuments,
} from "@app/hooks/use-supporting-documents";
import { useTransaction } from "@app/hooks/use-transaction";
import { DocumentsDetailsBlockView } from "./documents-details-block-view";

export const DocumentsDetailsBlock = ({
	transactionId,
	onEdit,
}: {
	transactionId?: number;
	onEdit?: () => void;
}) => {
	const { activePaymentId } = useTransaction(transactionId);
	const { data: paymentRequiredDocs, isLoading: isPaymentRequiredDocsLoading } =
		useBopDocuments(activePaymentId);
	const {
		data: paymentSupportingDocs,
		isLoading: paymentSupportingDocsLoading,
	} = useSupportingDocuments(activePaymentId);
	const [downloadPaymentSupportingDoc] = useDownloadPaymentSupportingDocs();

	return (
		<DocumentsDetailsBlockView
			documents={paymentSupportingDocs}
			requiredDocuments={paymentRequiredDocs}
			loading={paymentSupportingDocsLoading || isPaymentRequiredDocsLoading}
			onEdit={onEdit}
			onDownloadDoc={(docs: SupportingDocument) => {
				for (const doc of docs.documents) {
					const documentName = doc.document_name;
					downloadPaymentSupportingDoc(doc.id, (response) => {
						if (
							response &&
							!Array.isArray(response) &&
							response.contentType &&
							response.data
						) {
							downloadFile(
								response.contentType,
								response.data,
								documentName || new Date().toLocaleString(),
							);
						}
					});
				}
			}}
		/>
	);
};
